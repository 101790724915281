<div class="account-information" *ngIf="!isMobile">
	<div class="account-info-grid" *ngIf="contactInformation && customerSAPData">
		<ion-grid>
			<ion-row>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"accountInfoComponent.contactInformation.heading"| translate}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{customerSAPData.sap_customer_code || "--"}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"accountInfoComponent.accountSecurity.heading" | translate}}
						</div>
						<div class="information-container">
							<div class="security-note">
								<span>{{customerSAPData.cx_account_id || "--"}}</span>
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>

			<ion-row class="mt-30">
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"accountInfoComponent.custEnglishName"| translate}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{customerSAPData.customer_name_en || "--"}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"accountInfoComponent.custArabicName"| translate}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{customerSAPData.customer_name_ar || "--"}} </div>
						</div>
					</div>
				</ion-col>
			</ion-row>
			<ion-row class="mt-30">
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"accountInfoComponent.vatnumber"| translate}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{customerSAPData.vat_number || "--"}} </div>
						</div>
					</div>
				</ion-col>
			</ion-row>

		</ion-grid>
	</div>
</div>
<div class="account-information" *ngIf="isMobile">
	<div class="account-info-grid" *ngIf="contactInformation">
		<ion-grid class="p-0">
			<ion-row>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="common-input mb-10">
						<ion-item lines="none" mode="md">
							<img slot="start" src="assets/icon/email-icon.svg" alt="emailicon" class="input-icon">
							<ion-label class="input-color p-0">{{contactInformation.customer_email}}</ion-label>
						</ion-item>

					</div>

					<div class="common-input mb-10">
						<ion-item lines="none" mode="md">
							<img slot="start" src="assets/icon/user.svg" alt="Jazeera" class="input-icon">
							<ion-label class="input-color p-0">{{contactInformation.customer_name}}</ion-label>
						</ion-item>

					</div>

					<div class="common-input mb-10">
						<ion-item lines="none" mode="md">
							<img slot="start" src="assets/icon/password-icon.svg" alt="password" class="input-icon">
							<ion-label class="input-color p-0 lbl-password ">12345678</ion-label>

							<ion-label slot="end" class="btn-label"
								(click)="openModal()">{{"accountInfoComponent.change" |
								translate}}</ion-label>
						</ion-item>


					</div>

				</ion-col>

			</ion-row>
		</ion-grid>
	</div>
	<div class="account-info-grid" *ngIf=" !isLoading && customerSAPData">
		<ion-grid>
			<ion-row>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"accountInfoComponent.contactInformation.heading"| translate}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{customerSAPData.sap_customer_code || "--"}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"accountInfoComponent.accountSecurity.heading" | translate}}
						</div>
						<div class="information-container">
							<div class="security-note">
								<span>{{customerSAPData.cx_account_id || "--"}}</span>
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>

			<ion-row>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"accountInfoComponent.custEnglishName"| translate}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{customerSAPData.customer_name_en || "--"}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"accountInfoComponent.custArabicName"| translate}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{customerSAPData.customer_name_ar || "--"}} </div>
						</div>
					</div>
				</ion-col>
			</ion-row>
			<ion-row>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"accountInfoComponent.vatnumber"| translate}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{customerSAPData.vat_number || "--"}} </div>
						</div>
					</div>
				</ion-col>
			</ion-row>

		</ion-grid>
	</div>

	<div class="account-info-grid" *ngIf="isLoading">
		Loading customer data...
	</div>
</div>