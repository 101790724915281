import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api-data.service';
import { CommonService } from 'src/app/services/common.service';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { ModalService } from 'src/app/services/modal.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
	selector: 'app-sales-information',
	templateUrl: './sales-information.component.html',
	styleUrls: ['./sales-information.component.scss'],
})
export class SalesInformationComponent implements OnInit {

	mobileView = false;
	@Input() salesInfo: any;
	@Input() isMobile: boolean = false;

	constructor(
		public commonService: CommonService,
		public modalService: ModalService,
		private dataService: ApiService,
		private toastService: ToastService,
		public getPlatformService: GetPlatformService

	) { }

	ngOnInit() {
	}



}
