import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';
import { ToastService } from 'src/app/services/toast.service';



@Component({
	selector: 'app-mobile-statement-filter',
	templateUrl: './mobile-statement-filter.component.html',
	styleUrls: ['./mobile-statement-filter.component.scss'],
})
export class MobileStatementFilterComponent implements OnInit {

	fromDate: any;
	toDate: any;
	dateRange: FormGroup;
	doc_no_filter: any;
	credit_filter: any;
	debit_filter: any;
	docFromDate: any;
	docToDate: any;
	maxDate: any;
	orderStatus = 1;
	selectedStatus: any;
	maxFromDate: any;
	statusList = [];
	todaysDate = new Date();
	statusInterface = { cssClass: 'filterby-dropdown' };
	filterObj = {};
	fromOrdersList = false;
	fromQuotesList = false;
	constructor(
		private modalCtrl: ModalController,
		private datePipe: DatePipe,
		private toastService: ToastService,
		public commonService: CommonService,
		private fb: FormBuilder,
	) { }

	ngOnInit() {
		let date = new Date();
		this.initializeDateForm();
		if (this.filterObj) {
			delete this.filterObj['search'];
			if (this.filterObj['status']) {
				this.selectedStatus = this.filterObj['status'];
			}
			if (this.filterObj['post_from_date']) {
				this.fromDate = new Date(this.filterObj['post_from_date']);
				this.dateRange.controls.from.setValue(this.fromDate)
			}
			if (this.filterObj['post_to_date']) {
				this.toDate = new Date(this.filterObj['post_to_date']);
				this.dateRange.controls.to.setValue(this.toDate)
			}
			if (this.filterObj['doc_from_date']) {
				this.docFromDate = new Date(this.filterObj['doc_from_date']);
				this.dateRange.controls.docfrom.setValue(this.docFromDate)
			}
			if (this.filterObj['doc_to_date']) {
				this.docToDate = new Date(this.filterObj['doc_to_date']);
				this.dateRange.controls.docto.setValue(this.docToDate)
			}
			if (this.filterObj['doc_no']) {
				this.doc_no_filter = this.filterObj['doc_no'];
			}
			if (this.filterObj['debit']) {
				this.debit_filter = this.filterObj['debit']
			}
			if (this.filterObj['credit']) {
				this.credit_filter = this.filterObj['credit']
			}
		}
		this.maxFromDate = date.setDate(this.todaysDate.getDate() - 1);
		this.maxDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');
		this.maxFromDate = this.datePipe.transform(this.maxFromDate, 'YYYY-MM-dd');

	}

	initializeDateForm() {
		this.dateRange = this.fb.group({
			from: new FormControl(null),
			to: new FormControl(null),
			docfrom: new FormControl(null),
			docto: new FormControl(null),
		});

	}

	apply() {
		if ((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) {
			if (!this.fromDate) {
				let msg = this.commonService.domDirectionLTR
					? "Please select 'From Date'."
					: 'الرجاء تحديد "من التاريخ".';
				this.toastService.displayToast(msg, null, "danger");
			}
			if (!this.toDate) {
				let msg = this.commonService.domDirectionLTR
					? "Please select 'To Date'."
					: 'الرجاء تحديد "حتى الآن".';
				this.toastService.displayToast(msg, null, "danger");
			}
			return;
		}
		if (this.fromDate && this.toDate) {
			this.filterObj["post_from_date"] = this.datePipe.transform(
				this.fromDate,
				"yyyy-MM-dd"
			);
			this.filterObj["post_to_date"] = this.datePipe.transform(
				this.toDate,
				"yyyy-MM-dd"
			);
		}
		if (this.filterObj["post_from_date"] && this.filterObj["post_to_date"]) {
			if (this.filterObj["post_from_date"] > this.filterObj["post_to_date"]) {
				let msg = this.commonService.domDirectionLTR
					? "'From Date' cannot be greater than 'To Date'."
					: 'لا يمكن أن يكون "من تاريخ" أكبر من "إلى تاريخ".';
				this.toastService.displayToast(msg, null, "danger");
				return;
			}
		}
		let isValid = false;
		if (
			isValid &&
			this.filterObj["post_from_date"] &&
			this.filterObj["post_to_date"] &&
			this.filterObj["post_from_date"] == this.filterObj["post_to_date"]
		) {
			let msg = this.commonService.domDirectionLTR
				? "'From Date' and 'To Date' cannot be same."
				: 'لا يمكن أن يتطابق "من تاريخ" و "إلى تاريخ".';
			this.toastService.displayToast(msg, null, "danger");
			return;
		}




		let docDateObject = {};
		if ((this.docFromDate && !this.docToDate) || (!this.docFromDate && this.docToDate)) {
			if (!this.docFromDate) {
				let msg = this.commonService.domDirectionLTR
					? "Please select 'From Date'."
					: 'الرجاء تحديد "من التاريخ".';
				this.toastService.displayToast(msg, null, "danger");
			}
			if (!this.docToDate) {
				let msg = this.commonService.domDirectionLTR
					? "Please select 'To Date'."
					: 'الرجاء تحديد "حتى الآن".';
				this.toastService.displayToast(msg, null, "danger");
			}
			return;
		}
		if (this.docFromDate && this.docToDate) {
			docDateObject["doc_from_date"] = this.datePipe.transform(
				this.docFromDate,
				"yyyy-MM-dd"
			);
			docDateObject["doc_to_date"] = this.datePipe.transform(
				this.docToDate,
				"yyyy-MM-dd"
			);
		}
		if (docDateObject["doc_from_date"] && docDateObject["doc_to_date"]) {
			if (docDateObject["doc_from_date"] > docDateObject["doc_to_date"]) {
				let msg = this.commonService.domDirectionLTR
					? "'From Date' cannot be greater than 'To Date'."
					: 'لا يمكن أن يكون "من تاريخ" أكبر من "إلى تاريخ".';
				this.toastService.displayToast(msg, null, "danger");
				return;
			}
		}
		let isDocValid = false;
		if (
			isDocValid &&
			docDateObject["doc_from_date"] &&
			docDateObject["doc_to_date"] &&
			docDateObject["doc_from_date"] == docDateObject["doc_to_date"]
		) {
			let msg = this.commonService.domDirectionLTR
				? "'From Date' and 'To Date' cannot be same."
				: 'لا يمكن أن يتطابق "من تاريخ" و "إلى تاريخ".';
			this.toastService.displayToast(msg, null, "danger");
			return;
		}

		if (this.doc_no_filter) {
			this.filterObj["doc_no"] = this.doc_no_filter;
		}

		if (this.credit_filter) {
			this.filterObj["credit"] = this.credit_filter;
		}

		if (this.debit_filter) {
			this.filterObj["debit"] = this.debit_filter;
		}
		if (docDateObject["doc_from_date"] && docDateObject["doc_to_date"]) {
			this.filterObj["doc_from_date"] = docDateObject["doc_from_date"];
			this.filterObj["doc_to_date"] = docDateObject["doc_to_date"];
		}

		if (docDateObject["post_from_date"] && docDateObject["post_to_date"]) {
			this.filterObj["post_from_date"] = docDateObject["post_from_date"];
			this.filterObj["post_to_date"] = docDateObject["post_to_date"];
		}

		this.modalCtrl.dismiss(this.filterObj);
	}

	clear() {
		this.filterObj = {};
		this.modalCtrl.dismiss(this.filterObj);
	}

	closeModal() {
		this.modalCtrl.dismiss();
	}

	onDateChange(datetime) {
		datetime.cancel(true);
	}
}
