<ion-header class="mobile-visibility app-header">
	<ion-toolbar mode="md">
		<img src="assets/icon/leftArrow.svg" class="back-icon back-img" alt="Jazeera" slot="start" (click)="goBack()">
		<ion-title mode="ios">
			<div class="order-header">
				{{'myQuoteComponent.title' | translate}}
			</div>
		</ion-title>
		<div class="section-block header-filter-block" slot="end" (click)="openFilterByModal()" *ngIf="isLoggedIn">
			<div class="filter-icon">
				<img src="./assets/icon/filterIcon.svg" alt="Jazeera">
			</div>
			<!-- <div class="filter-label" [ngClass]="showFilter ? 'close-label' : ''">{{"filters"
				| translate}}
			</div> -->
		</div>
	</ion-toolbar>

	<div class="searchbar-container mh-20 mv-12" *ngIf="isLoggedIn && false">
		<app-search-bar fromOrdersList="true" (searchClicked)="search($event)"
			(clearClicked)="clearSearch($event)"></app-search-bar>
	</div>

</ion-header>
<app-loader *ngIf="isLoading"></app-loader>

<ion-content [fullscreen]="true">
	<ion-refresher *ngIf="!getPlatformService.isDesktop" slot="fixed" refreshingSpinner="circular"
		(ionRefresh)="handleRefresh($event)">
		<ion-refresher-content></ion-refresher-content>
	</ion-refresher>
	<div class="orders-listing mh-20" *ngIf="isLoggedIn && !isLoading">
		<div class="mobile-view-listing" *ngIf="quoteList.length">
			<div class="order-container">
				<ion-grid class="p-0 m-0">
					<ion-row class="order-card" *ngFor="let data of quoteList;index as i"
						(click)="openQuoteDetailsModal(data)">
						<ion-col size="12">
							<ion-grid>
								<ion-row>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"myQuoteComponent.quoteNo" | translate}}</div>
										<div class="grid-values">{{data.quotation_no}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"myQuoteComponent.date" | translate}}</div>
										<div class="grid-values">{{data.created_at | date
											:'MMM dd, YYYY'}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"myQuoteComponent.countOfItems" |
											translate}}</div>
										<div class="grid-values">{{data.total_items}}</div>

									</ion-col>
									<!-- <ion-col size="6" class="order-card-col">
                                        <div class="grid-label">{{"myQuoteComponent.totalPrice" |
                                            translate}}</div>
                                        <div class="grid-values">{{commonService.currencySymbol}} {{data.total_price ?
                                            data.total_price: false }}</div>

                                    </ion-col> -->

								</ion-row>

								<div class="btn-div">
									<div class="grid-values">
										<ion-button fill="clear" class="order-status-btn" [ngClass]="quoteStatus(data)"
											(click)="null">
											{{data.is_fulfilled != true ? ('myQuoteComponent.requested' | translate)
											:
											('myQuoteComponent.completed' |translate)}} </ion-button>
									</div>

									<div *ngIf="data.is_fulfilled != true ">
										<ion-button class="order-status-btn btn-label"
											(click)="proceedToOrderQuote(data,$event)">
											{{'myQuoteComponent.proceedToOrder' | translate}}
										</ion-button>
									</div>
								</div>
							</ion-grid>
						</ion-col>

					</ion-row>
					<ion-infinite-scroll threshold="400px" (ionInfinite)="loadData($event)">
						<ion-infinite-scroll-content *ngIf="enableNext" loadingSpinner="circular"
							loadingText="{{'loadMoreOrders' | translate}}">
						</ion-infinite-scroll-content>
					</ion-infinite-scroll>
				</ion-grid>
			</div>
		</div>

		<div *ngIf="!quoteList.length" class="no-orders">
			<img src="./assets/icon/no-order.svg" alt="Jazeera" class="mb-30" />
			<div class="mb-20">
				<p class="label-3 color-app-black ">{{'noQuote' | translate}}</p>
			</div>
		</div>


	</div>
	<app-login-prompt *ngIf="!isLoggedIn && !isLoading"></app-login-prompt>
</ion-content>