import { DatePipe, isPlatformServer } from "@angular/common";
import { Component, Inject, Input, OnInit, PLATFORM_ID } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import * as moment from 'moment';
import { Subscription } from "rxjs";
import { URLS } from "../../_config/api.config";
import { ApiService } from "../../services/api-data.service";
import { CommonService } from "../../services/common.service";
import { ExportExcelService } from "../../services/export-excel.service";
import { ModalService } from "../../services/modal.service";
import { ToastService } from "../../services/toast.service";

@Component({
	selector: "app-voucher",
	templateUrl: "./voucher.component.html",
	styleUrls: ["./voucher.component.scss"],
})
export class VoucherComponent implements OnInit {
	isLoading = false;
	isDownloading = false;
	searchText: any;
	private subscription: Subscription = new Subscription();
	perPageInterface = { cssClass: "custom-select-dd per-page-dd" };
	currencySymbol: string = "SR";
	showSearch = false;
	enableNext = false;
	filterObj = {};
	showFilter = false;

	public count = 0;
	public voucherTotal = 0;

	public itemsPerPage = "10";

	public currentPage = 1;
	selectInterface = { cssClass: "custom-select-dd", size: "cover" };
	selectStatus: any = "all";
	fromDate: any;
	toDate: any;
	dateRange: FormGroup;
	maxFromDate: any;
	maxDate: any;
	minToDate: any;
	todaysDate = new Date();
	columnHeaders = {
		order_id: "Order #",
		date: "Date",
		ship_to: "Ship to",
		order_total: "Order total",
		status: "Status",
	};
	statusList = [];
	voucherList = [];
	@Input() showHeader: boolean;
	@Input() from: any;
	@Input() dataArray: any;

	voucherNumebr: any;
	addVoucherNumebr: any;
	addVoucherContainerShow: boolean = false;
	tempVouchers: any = [];
	addedVoucherTotal: any = 0;
	totalValidVoucher: any = 0;
	constructor(
		private modalService: ModalService,
		private dataService: ApiService,
		private toastService: ToastService,
		public commonService: CommonService,
		private translateService: TranslateService,
		private fb: FormBuilder,
		private datePipe: DatePipe,
		private router: Router,
		@Inject(PLATFORM_ID) private platformId: Object,
		private exportService: ExportExcelService
	) {
		if (this.router.url.includes("voucher")) {
			this.showHeader = true;
		}
	}

	ngOnInit() {
		if (this.router.url.includes("voucher")) {
			this.showHeader = true;

			this.statusList = [
				{ value: "all", label: this.translateService.instant("all") },
				{
					value: "CONFIRMED",
					label: this.translateService.instant("confirmed"),
				},
				{
					value: "DISPATCHED",
					label: this.translateService.instant("dispatched"),
				},
				{
					value: "DELIVERED",
					label: this.translateService.instant("delivered"),
				},
			];
			this.initializeDateForm();

			this.getVoucherList(this.filterObj);

		}
		if (this.from == "dashboard") {
			this.voucherList = this.dataArray;
		}
	}

	ionViewWillEnter() {
		this.initializeDateForm();
		if (this.router.url.includes("voucher")) {
			this.showHeader = true;
			this.statusList = [
				{ value: "all", label: this.translateService.instant("all") },
				{
					value: "CONFIRMED",
					label: this.translateService.instant("confirmed"),
				},
				{
					value: "DISPATCHED",
					label: this.translateService.instant("dispatched"),
				},
				{
					value: "DELIVERED",
					label: this.translateService.instant("delivered"),
				},
			];
			this.getVoucherList(this.filterObj);
		}
		if (this.from == "dashboard") {
			this.voucherList = this.dataArray;
		}
	}

	initializeDateForm() {
		this.dateRange = this.fb.group({
			from: new FormControl(null),
			to: new FormControl(null),
		});
		let date = new Date();
		this.maxFromDate = date.setDate(this.todaysDate.getDate() - 1);
		this.maxDate = this.datePipe.transform(this.todaysDate, "YYYY-MM-dd");
		this.maxFromDate = this.datePipe.transform(this.maxFromDate, "YYYY-MM-dd");
	}

	applyBtn() {
		this.filterObj = {};
		if ((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) {
			if (!this.fromDate) {
				let msg = this.commonService.domDirectionLTR
					? "Please select 'From Date'."
					: 'الرجاء تحديد "من التاريخ".';
				this.toastService.displayToast(msg, null, "danger");
			}
			if (!this.toDate) {
				let msg = this.commonService.domDirectionLTR
					? "Please select 'To Date'."
					: 'الرجاء تحديد "حتى الآن".';
				this.toastService.displayToast(msg, null, "danger");
			}
			return;
		}
		if (this.fromDate && this.toDate) {
			this.filterObj["from_date"] = this.datePipe.transform(
				this.fromDate,
				"yyyy-MM-dd"
			);
			this.filterObj["to_date"] = this.datePipe.transform(
				this.toDate,
				"yyyy-MM-dd"
			);
		}
		if (this.filterObj["from_date"] && this.filterObj["to_date"]) {
			if (this.filterObj["from_date"] > this.filterObj["to_date"]) {
				let msg = this.commonService.domDirectionLTR
					? "'From Date' cannot be greater than 'To Date'."
					: 'لا يمكن أن يكون "من تاريخ" أكبر من "إلى تاريخ".';
				this.toastService.displayToast(msg, null, "danger");
				return;
			}
		}
		let isValid = false;
		if (
			isValid &&
			this.filterObj["from_date"] &&
			this.filterObj["to_date"] &&
			this.filterObj["from_date"] == this.filterObj["to_date"]
		) {
			let msg = this.commonService.domDirectionLTR
				? "'From Date' and 'To Date' cannot be same."
				: 'لا يمكن أن يتطابق "من تاريخ" و "إلى تاريخ".';
			this.toastService.displayToast(msg, null, "danger");
			return;
		}
		if (this.selectStatus == "all") {
			delete this.filterObj["status"];
		} else {
			this.filterObj["status"] = this.selectStatus;
		}
		this.currentPage = 1;
		this.getVoucherList(this.filterObj);
	}

	clearFilter() {
		this.fromDate = null;
		this.toDate = null;
		this.selectStatus = "all";
		this.dateRange.reset();
		this.filterObj = {};
		if (this.searchText) {
			this.filterObj["search"] = this.searchText;
		}
		this.getVoucherList(this.filterObj);
	}

	onDateChange(datetime) {
		datetime.cancel(true);
	}

	search() {
		if (!this.searchText) return;
		this.filterObj["search"] = this.searchText;
		this.currentPage = 1;
		this.getVoucherList(this.filterObj);
	}

	openFilters() {
		this.showFilter = !this.showFilter;
	}

	gotoOrderDetails(id, orderNo) {
		this.router.navigate(["/home/account-details/voucher/", id], {
			state: {
				breadcrumb:
					this.translateService.instant("orderDetailsComponent.orders") +
					orderNo,
				url: "/home/account-details/voucher/" + id,
			},
		});
	}

	onChange(event): void {
		this.currentPage = event;
		this.getVoucherList(this.filterObj);
	}

	getVoucherList(params, infiniteScroll?, download?) {
		if (!download) {
			params = {
				page: this.currentPage,
				limit: +this.itemsPerPage,
				dropdown: false,
				sort_by: 'validity_end_date',
				sort_type: 'desc',
				...params,
			};
		}
		return new Promise((resolve, reject) => {
			if (!download) {
				this.isLoading = true;
			}
			this.subscription.add(
				this.dataService.get(URLS.voucherList, params).subscribe(
					(data) => {
						if (data && data["code"] == 200) {
							if (download) {
								let list =
									data["data"] &&
										data["data"]["result"] &&
										data["data"]["result"].length
										? data["data"]["result"]
										: [];
								if (list.length) {
									this.exportService.exportAsExcelFile(list, "voucher_list");
								}
							} else {
								this.enableNext = data["data"]["enable_next"] ? true : false;
								this.count =
									data["data"] && data["data"]["total_count"]
										? +data["data"]["total_count"]
										: 0;
								this.voucherTotal =
									data["data"] && data["data"]["voucher_total"]
										? +data["data"]["voucher_total"]
										: 0;
								let list =
									data["data"] &&
										data["data"]["result"] &&
										data["data"]["result"].length
										? data["data"]["result"]
										: [];
								if (!infiniteScroll) {
									this.voucherList = [];
								}

								this.voucherList = list;
							}
							resolve(this.voucherList);
						} else {
							reject();
						}
						this.isLoading = false;
					},
					(error) => {
						reject();
						this.isLoading = false;
					}
				)
			);
		});
	}

	loadData(event) {
		setTimeout(() => {
			if (this.enableNext) {
				this.currentPage += 1;
				this.getVoucherList(this.filterObj, true).then(
					(data) => {
						event.target.complete();
					},
					(err) => {
						event.target.complete();
					}
				);
			}
		}, 500);
	}

	selectPageLength(event) {
		this.itemsPerPage = event.detail.value;
		this.currentPage = 1;
		this.getVoucherList(this.filterObj);
	}

	clearSearch() {
		this.searchText = null;
		delete this.filterObj["search"];
		this.currentPage = 1;
		this.getVoucherList(this.filterObj);
	}

	downloadExcel() {
		if (this.isDownloading) {
			return;
		}
		const params = {
			dropdown: true,
		};
		this.isDownloading = true;
		this.getVoucherList(params, false, true).then(
			(data) => {
				this.isDownloading = false;
			},
			(err) => {
				this.isDownloading = false;
			}
		);
	}

	orderStatus(orderData, productlevel?) {
		switch (orderData.order_status) {
			case "DISPATCHED":
			case "CONFIRMED":
				if (productlevel) {
					return "btn-green product-status-btn";
				} else {
					return "btn-green";
				}
			case "DELIVERED":
				if (productlevel) {
					return "btn-black product-status-btn";
				} else {
					return "btn-black";
				}
			case "NOT_YET_DELIVERED":
			case "NOT_DELIVERED":
			case "PENDING":
				if (productlevel) {
					return "btn-red product-status-btn";
				} else {
					return "btn-red";
				}
			default:
				if (productlevel) {
					return "btn-green product-status-btn";
				} else {
					return "btn-green";
				}
		}
	}

	downloadVoucher(event, invoiceUrl) {
		event.preventDefault();
		event.stopPropagation();
		if (!isPlatformServer(this.platformId)) {
			window.open(invoiceUrl);
		}
	}

	reedemVoucher() {
		if (!this.voucherNumebr) {
			let msg = this.commonService.domDirectionLTR
				? "Please enter a voucher code"
				: 'لا يمكن أن يتطابق "من تاريخ" و "إلى تاريخ".';
			this.toastService.displayToast(msg, null, "danger");
			return;
		}

		let params = {
			"voucher_code": this.voucherNumebr
		}
		this.subscription.add(
			this.dataService.post(URLS.addVoucers, params).subscribe(
				(data) => {
					if (data && data["code"] == 200) {
						this.toastService.displayToast(data['message'], null, "success");
					} else {
						this.toastService.displayToast(data['message'], null, "danger");
					}
					this.isLoading = false;
				},
				(error) => {
					this.toastService.displayToast(error['message'], null, "danger");
					this.isLoading = false;
				}
			)
		);
	}

	addVoucherDialog() {
		this.addVoucherContainerShow = !this.addVoucherContainerShow;
		this.tempVouchers = [];
		this.voucherNumebr = null;
	}

	validateVoucher() {
		if (!this.addVoucherNumebr) {
			let duplicateMsg = this.commonService.domDirectionLTR ? 'Please Enter voucher number' : 'يرجى إدخال رقم القسيمة';
			this.toastService.displayToast(duplicateMsg, null, "danger");
			return;
		}
		if (this.tempVouchers.some(voucher => voucher.voucherNumebr === this.addVoucherNumebr)) {
			let duplicateMsg = this.commonService.domDirectionLTR ? 'Duplicate voucher number' : 'رقم قسيمة مكرر';
			this.toastService.displayToast(duplicateMsg, null, "danger");
			return;
		}

		let params = {
			"voucher_code": this.addVoucherNumebr
		}

		this.totalValidVoucher = 0;
		this.addedVoucherTotal = 0;
		this.subscription.add(
			this.dataService.post(URLS.voucherValidation, params).subscribe(
				(data) => {
					if (data && data["code"] == 200) {
						let result = data['data'];
						this.tempVouchers.push({ ...result, voucherNumebr: result.voucher_code, voucherAmount: this.commonService.currencySymbol + " " + result.voucher_amount, expiryDate: moment(result.validity_end_date).format('MMM DD, YYYY'), msg: data.message, msg_type: 'success' });
						this.addVoucherNumebr = null;

					} else {
						// this.toastService.displayToast(data['message'], null, "danger");
						this.tempVouchers.push({ voucherNumebr: this.addVoucherNumebr, voucherAmount: 'NA', expiryDate: 'NA', msg: data.message, msg_type: 'error' });
						this.addVoucherNumebr = null;

					}

					this.tempVouchers.forEach(element => {
						if (element.msg_type == 'success') {
							this.addedVoucherTotal = this.addedVoucherTotal + parseFloat(element.voucher_amount);
							this.totalValidVoucher += 1;
						}
					});
					this.isLoading = false;
				},
				(error) => {
					this.toastService.displayToast(error['message'], null, "danger");
					this.isLoading = false;
				}
			)
		);


	}


	submitAllVoucher() {

		if (this.tempVouchers && this.tempVouchers.length < 1) {
			let duplicateMsg = this.commonService.domDirectionLTR ? 'No valid vouchers found' : 'لم يتم العثور على قسائم صالحة';
			this.toastService.displayToast(duplicateMsg, null, "danger");
			return;
		}

		const successVouchers = this.tempVouchers.filter(voucher => voucher.msg_type === "success");
		let params = {
			"vouchers": successVouchers
		}

		this.subscription.add(
			this.dataService.post(URLS.bulkSubmitVoucher, params).subscribe(
				(data) => {
					if (data && data["code"] == 200) {
						this.toastService.displayToast(data['message'], null, "success");
						this.addVoucherContainerShow = false;
						this.tempVouchers = [];
						this.currentPage = 1;
						this.getVoucherList(this.filterObj);

					} else {
						this.toastService.displayToast(data['message'], null, "danger");
					}
					this.isLoading = false;
				},
				(error) => {
					this.toastService.displayToast(error['message'], null, "danger");
					this.isLoading = false;
				}
			)
		);
	}
}
