<ion-header class="product-review-header" *ngIf="!getPlatformService.isDesktop">
	<ion-toolbar mode="md">
		<img src="./assets/icon/leftArrow.svg" alt="back-arrow" slot="start" (click)="goBack()"
			[ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
		<ion-title mode="ios">
			<div class="checkout-header">
				{{"checkout" | translate}}
			</div>
		</ion-title>
	</ion-toolbar>
</ion-header>

<div class="home-page app-header" *ngIf="getPlatformService.isDesktop">
	<app-header></app-header>
</div>

<app-cart-header [countIndex]="3"></app-cart-header>

<app-loader *ngIf="isLoading"></app-loader>

<ion-content *ngIf="getPlatformService.isDesktop && !isLoading">
	<ion-row [ngClass]="getPlatformService.isDesktop ? 'p-35-190':'p-20'">
		<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="8" size-xl="8" class="pr-30">
			<p *ngIf="is_not_sample_product || (!is_not_sample_product && !is_sample_item_and_shipment_free)"
				class="title1 color-app-black">{{'checkoutStep2.Payment' | translate}}</p>
			<div>
				<div *ngIf="is_not_sample_product || (!is_not_sample_product && !is_sample_item_and_shipment_free)">
					<p class="title3 color-app-black mv-20-30">{{ 'checkoutStep2.choosePaymentmethod' | translate}}</p>
					<div class="bg-gray-90 br-5 mt-20 p-15">
						<div *ngFor="let data of payment_methods;let i =index" class="border_class">
							<div class="display-div cursor-pointer" (click)="choosePamentMethods(i)">
								<div class="radio-size mr-15">
									<div *ngIf="!data.select" class="radio-div"></div>
									<img *ngIf="data.select" src="/assets/icon/check.svg" alt="Jazeera" />
								</div>
								<div class="display-div width-100">
									<p class="title2 color-app-black p-0 width-inherit">{{data.payment_type_value}}</p>
									<img *ngIf="data.payment_type_id == 'CARDS'" src="/assets/cards/creditcard2.png"
										class="card-icons" alt="Jazeera" />

									<div *ngIf="data.payment_type_id == 'ONACCOUNT' && customerInfo"
										class="section-block width-inherit justify-end">
										<p class="title4 color-app-black p-0">
											{{'voucherComponent.availableCredit'|translate}}:
											<span class="title2 color-primary ">{{commonService.currencySymbol}}
												{{customerInfo.customer_remaining_balance|number}}</span>
										</p>
										<img src="/assets/icon/wallet.svg" class="card-icons ml-10" alt="Jazeera" />
									</div>


									<img *ngIf="data.payment_type_id == 'BANKTRANSFER'"
										src="/assets/icon/banktransfer.svg" class="card-icons" alt="Jazeera" />
								</div>
							</div>
							<div class="br-5 p-15"
								*ngIf="data.payment_type_id === 'ONACCOUNT' && (customerInfo?.customer_remaining_balance < subtotal)">

								<p class="color-danger text-bold">{{'customField.onAccountMsg'|translate}}
								</p>
							</div>
						</div>


						<div class="bg-gray-90 br-5 p-15"
							*ngIf="this.selectedPaymentMethod && this.selectedPaymentMethod.payment_type_id == 'BANKTRANSFER'">
							<ion-label
								class="text-bold">{{"customField.chooseBankTransferDetails"|translate}}</ion-label>

							<!-- Bank Option -->
							<div class="border_class mv-40" *ngFor="let data of bankListArray;let i = index">
								<div class="display-div cursor-pointer" (click)="chooseBank(i)">
									<div class="radio-size mr-15">
										<div *ngIf="!data.select" class="radio-div"></div>
										<img *ngIf="data.select" src="/assets/icon/check.svg" alt="Jazeera" />
									</div>

									<div class="width-100">
										<ion-row class="p-0 justify-space-beetwen">
											<div class="bank-div">
												<img [src]="data.bank_logo" [alt]="Jazeera" class="bank-img" />
											</div>
											<ion-col>
												<p><strong>{{"customField.bankName"|translate}}:</strong></p>
												<p class="p-0">{{data.bank_name || '--'}}</p>
											</ion-col>
											<ion-col>
												<p><strong>{{"customField.accountHolderName"|translate}}:</strong> </p>
												<p class="p-0">{{data.account_holder_name || '--'}}</p>
											</ion-col>
											<ion-col>
												<p><strong>{{"customField.accountNumber"|translate}}:</strong></p>
												<p class="p-0"> {{data.account_number || '--'}}</p>
											</ion-col>
										</ion-row>

										<ion-row class="p-0 justify-space-beetwen">
											<ion-col>
												<p><strong>{{"customField.bankSwiftCode"|translate}}:</strong></p>
												<p class="p-0"> {{data.bank_swift_code || '--'}}</p>
											</ion-col>
											<ion-col>
												<p><strong>{{"customField.accountType"|translate}}:</strong> </p>
												<p class="p-0">{{data.account_type || '--'}}</p>
											</ion-col>
											<ion-col>
												<p><strong>{{"customField.IBAN"|translate}}:</strong></p>
												<p class="p-0"> {{data.iban_code || '--'}}</p>
											</ion-col>
										</ion-row>
									</div>

								</div>
							</div>

							<p class="text-bold mv-15 fs-20">{{"customField.shareBankTransferDetail"|translate}}</p>

							<!-- Share Bank Transfer Details -->
							<p class="text-bold mt-20">{{"customField.bankAcknowledgementNumber"|translate}}*</p>
							<ion-item lines="none" class="mb-20">
								<ion-input placeholder="{{'customField.enteracknowledgementnumber'|translate}}"
									[(ngModel)]="bankAcknowledgeNo"></ion-input>
							</ion-item>

							<ion-row>
								<ion-col>
									<ion-label>{{"customField.uploadedPayment"|translate}}*</ion-label>

									<div class="upload-container" (click)="triggerFileInput()">
										<ion-icon name="document-attach-outline"></ion-icon>
										<p class="m-0">{{'customField.clicktoupload'|translate}}</p>
										<input type="file" #fileInput accept="image/*,application/pdf"
											(change)="onFileSelected($event)" multiple hidden />
									</div>
									<!-- Display the list of uploaded files -->
									<div *ngFor="let file of files; index as i" class="file-list">
										<p>{{ file.file_name }}</p>
										<ion-icon name="close-circle-outline" (click)="removeFile(i)"></ion-icon>
									</div>
								</ion-col>
								<ion-col>
									<ion-label position="stacked">{{'customField.notes'|translate}}*</ion-label>
									<ion-textarea class="upload-container2 bg-white p-0" [(ngModel)]="textareaBankNote"
										placeholder="{{'customField.enterdescriptiontomatch'|translate}}"></ion-textarea>
								</ion-col>
							</ion-row>

							<div class="d-flex justify-end mt-20">
								<app-common-button color="#FFF" background="#0081CA" type="solid"
									title="{{selectedBank && selectedBank.bank_id ? ('update'|translate) : ('save'|translate)}}"
									[isIcon]="false" [isHideInMobile]="false" [isFullWidth]="true"
									(buttonClicked)="saveBankDetails()" [specificHeight]="'50px'"
									[specificWidth]="'150px'" [specificFontSize]="'1rem'" [specificFontWeight]="700"
									[buttonDisabled]="this.buttonBankDiabledMethod()">
								</app-common-button>
							</div>

						</div>
					</div>
				</div>


				<div class="cart-modal mt-50">
					<ion-row class="row-div" *ngIf="cartProductData">
						<ion-col size-sm="6" size-md="6" size-lg="6" size-xl="6" size-xs="6" class="section-block">
							<p class="title3 color-app-black p-0 m-0">
								{{'orderDetailsMobile.title'|translate}}
							</p>
						</ion-col>
						<ion-col size-sm="6" size-md="6" size-lg="6" size-xl="6" size-xs="6"
							class="section-block justify-end">
							<p class="label1 color-app-grey"><span class="color-app-black">{{cartProductData.length}}
									{{cartProductData.length > 1 ? ('cartModal.products'|translate) :
									('cartModal.product'|translate)}}</span>
								<span class="label-in-cart"> {{'cartModal.incart'| translate}}</span>
							</p>
						</ion-col>
					</ion-row>
					<div class="divider-line"></div>

					<div class="scroll-content bg-gray-90 br-5 p-15"
						*ngIf="cartData && cartData.result && cartData.result.length && !isLoading">
						<div *ngFor="let item of cartData.result;let i =index">
							<div *ngFor="let data of item.products;let i =index">
								<ion-row class="row-padding section-block justify-space-beetwen">
									<div class="section-block">
										<div class="mr-15 position-rel"
											[ngClass]=" data.is_sold_out ? 'product-image-blur' : null">

											<div *ngIf="data.discount_type &&  data.discount_type != 'PRICE' && !data.is_sold_out && !data.is_sample_product"
												class="discout_div ">
												<span>-</span>{{ data.discount_value }}<span>%</span>
											</div>
											<div *ngIf="data.discount_type &&  data.discount_type == 'PRICE' && !data.is_sold_out && !data.is_sample_product"
												class="discout_div ">
												<span>{{"OnSale" | translate}}</span>
											</div>

											<img *ngIf="data.product_cover_image"
												src="{{data.product_cover_image[0].image_url}}" class="product_img"
												alt="Jazeera" />

											<img *ngIf="!data.product_cover_image" src="./assets/images/empty-img.png"
												class="product_img" alt="Jazeera" />
										</div>
										<div *ngIf="data.is_sold_out" class="product-outofstock">
											<div class="outofstock-label">
												{{"outOfStock" | translate}}
											</div>
										</div>

										<div class="width-col">
											<p class="label3 color-app-black">{{commonService.domDirectionLTR ?
												data.product_name_en
												:
												data.product_name_ar}}</p>
											<div *ngIf="data.is_sample_product == true" class="sample_div ">
												<p>{{'SAMPLE'| translate}}</p>
											</div>
											<div class="mv-5 color-app-grey text-left">{{data.product_sku}}</div>

											<p class="label5 color-app-grey display-div cursor-pointer "
												(click)="goToProductDetail(data)">
												{{'ViewDetails' |translate}}
												<!-- <i class="feather icon-chevron-down arrow-icon color-primary ml-5"></i> -->
											</p>

											<div class="rowQty display-div">
												<p class="label3 color-app-black product-price">
													{{commonService.currencySymbol}}{{data.is_sample_product ?
													(data.price_per_unit | number): (data.final_price_per_unit |
													number)}}
													<span *ngIf="data.unit_name_en && data.is_sample_product != true"
														class="label6 color-app-grey">/{{commonService.domDirectionLTR
														?
														data.unit_name_en:data.unit_name_ar}}</span>
												</p>
											</div>
										</div>
									</div>


									<div class="units-container">
										<div class="qty_div">
											<p class="label4 color-app-black mr-10">{{data.total_quantity}} </p>
											<span class="label6 color-app-grey">{{commonService.domDirectionLTR
												?
												data.unit_name_en:data.unit_name_ar}}</span>
										</div>
										<div class="qty_div suom"
											*ngIf="data.unit_id && data.secondary_unit_id && data.unit_id !== data.secondary_unit_id">
											<p class="label4 color-app-black">
												{{calculateSUOM(data.conversation_ratio,data.total_quantity)}}
											</p>
											<span class="label6 color-app-grey ">
												{{commonService.domDirectionLTR ? data.secondary_unit_name_en
												:
												data.secondary_unit_name_ar}} </span>
										</div>
									</div>
								</ion-row>
							</div>
						</div>
					</div>

					<div *ngIf="!cartData && !cartData.result.length && !isLoading" class="vertical_center">
						<img src="./assets/icon/emptyCart.svg" alt="Jazeera" class="mb-10" />
						<div class="mb-20">
							<p class="label-3 color-app-black ">{{'Thecartisempty' | translate}}</p>
						</div>
					</div>
				</div>

			</div>
		</ion-col>


		<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="4" size-xl="4" class="pl-30">
			<p class="title3 color-app-black">{{'cartPage.Ordersummary' | translate}}</p>

			<div class="discount-div width-100" *ngIf="cartData.result && cartData.result.length">
				<p class="apply-discount-lbl">{{'cartPage.ApplyDiscountCode' | translate}} </p>

				<div class="mb-10">
					<div class="input-div mv-10">

						<img src="./assets/icon/couponcode.svg" [alt]="jazeera" slot="start" class="ml-10" />

						<ion-input class="input-border" placeholder="{{ 'cartPage.EnterCode' | translate }}"
							[(ngModel)]="appliedCouponCodeValue" [disabled]="true">
						</ion-input>

						<app-common-button *ngIf="!appliedCouponCodeValue" color="var(--ion-color-blue-60)"
							background="rgba(var(--ion-color-blue-60-rgb), 0.3)" type="solid" [boxshadow]="false"
							title="{{'Apply'|translate}}" [isIcon]="false" [isHideInMobile]="false"
							[buttonDisabled]="true" [isSecondryBtn]="false" [isPrimaryBtn]="true"
							[specificHeight]="'48px'" [disabled]="true" [specificWidth]="'70px'"
							[specificFontSize]="'1rem'" (click)="applyCodeToCart()">
						</app-common-button>
						<app-common-button *ngIf="appliedCouponCodeValue" color="var(--ion-color-blue-40)"
							background="rgba(var(--ion-color-blue-40-rgb), 0.3)" type="solid"
							title="{{'remove'|translate}}" [isIcon]="false" [isHideInMobile]="false"
							[buttonDisabled]="true" [disabled]="true" [isSecondryBtn]="false" [isPrimaryBtn]="true"
							[specificHeight]="'48px'" [boxshadow]="false" [specificWidth]="'70px'"
							[specificFontSize]="'1rem'" (click)="removeAppliedCode()">
						</app-common-button>

					</div>
				</div>
			</div>

			<app-order-summary *ngIf="this.cartData && this.cartData.grand_total"
				[shippingEliminated]="cartData.is_shipping_cost_eliminated" [eliminatedRates]="cartData.grand_total"
				currency="{{commonService.currencySymbol}}" orderTotal="{{cartData.grand_total.total_price}}"
				orderSubTotal="{{cartData.grand_total.items_sub_total}}"
				orderDiscount="{{cartData.grand_total.coupon_discounted_value}}" discountCode="{{appliedCouponCode ?
						                                appliedCouponCode : null}}" orderVAT="{{cartData.grand_total.vat}}"
				[orderSummeryData]="cartData.grand_total" orderShipping="{{cartData.grand_total.shipping_charges}}"
				orderShippingWithoutVat="{{cartData.grand_total.shipping_charges_without_vat}}"
				shippingVat="{{cartData.grand_total.shipping_vat_charges}}"
				vatPercent="{{cartData.grand_total.vat_percentage}}">
			</app-order-summary>

			<div class="p-20  bg-gray-90">
				<app-common-button color="#FFF" background="#0081CA" type="solid"
					title="{{'checkoutStep2.ReviewOrder'|translate}}" [isIcon]="false" [isHideInMobile]="false"
					[isFullWidth]="true" (buttonClicked)="gotoChekoutReviewPage()" [specificHeight]="'50px'"
					[specificWidth]="'250px'" [specificFontSize]="'1rem'" [specificFontWeight]="700"
					[buttonDisabled]="this.buttonDiabledMethod()">
				</app-common-button>
			</div>

			<div class=" bg-gray-90 br-5 mt-20 p-15">
				<div class="section-block justify-space-beetwen">
					<p class="text-bold fs-14 color-black">{{'customField.Weaccept'|translate}}</p>
					<div class="section-block">
						<img src="./assets/icon/security-card.svg" alt="jazeera" />
						<p class="text-bold fs-14 ml-10 mv-0 color-success">
							{{'customField.SecurePayment'|translate}}</p>
					</div>
				</div>
				<div class="section-block justify-space-beetwen">
					<img src="./assets/icon/visa.svg" alt="jazeera" />
					<img src="./assets/icon/mastercard.svg" alt="jazeera" />
					<img src="./assets/icon/amex1.svg" alt="jazeera" />
				</div>
			</div>

		</ion-col>

	</ion-row>


</ion-content>

<app-footer [showOnlyLowFooter]="true" *ngIf="getPlatformService.isDesktop"></app-footer>

<ion-content *ngIf="!getPlatformService.isDesktop && !isLoading">
	<ion-row class="p-0">

		<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="9" size-xl="9" class="ph-20">
			<div>
				<div *ngIf="is_not_sample_product || (!is_not_sample_product && !is_sample_item_and_shipment_free)">
					<p class="title3 color-app-black mv-20-30">{{ 'checkoutStep2.Paymentmethod' | translate}}</p>
					<div *ngFor="let data of payment_methods;let i =index" class="border_class">
						<div class="display-div cursor-pointer" (click)="choosePamentMethods(i)">
							<div class="radio-size mr-15">
								<div *ngIf="!data.select" class="radio-div"></div>
								<img *ngIf="data.select" src="/assets/icon/check.svg" alt="Jazeera" />
							</div>

							<div class="display-div width-100">
								<div class="mobile-cart-div">
									<p class="title2 color-app-black p-0 width-inherit">{{data.payment_type_value}}</p>

									<img *ngIf="data.payment_type_id == 'CARDS'" src="/assets/cards/creditcard2.png"
										class="card-icons" alt="Jazeera" />

									<div *ngIf="data.payment_type_id == 'ONACCOUNT'">
										<img src="/assets/icon/wallet.svg" class="card-icons ml-10" alt="Jazeera" />
									</div>

									<img *ngIf="data.payment_type_id == 'BANKTRANSFER'"
										src="/assets/icon/banktransfer.svg" class="card-icons" alt="Jazeera" />
								</div>
								<!-- <ion-row class="width-100">
                                    <p class="text-right m-0 width-100" *ngIf="i == payment_methods.length -1">
                                        <i class="feather icon-plus color-primary label5"
                                            (click)="addNewCardDialog()"></i>
                                        <span class=" label5 color-primary"
                                            (click)="addNewCardDialog()">{{'checkoutStep2.AddCard' |
                                            translate}}</span>
                                    </p>
                                </ion-row> -->
							</div>


						</div>
						<div *ngIf="data.payment_type_id === 'ONACCOUNT' && customerInfo">
							<p class="title4 color-app-black ph-15 mt-10">
								{{'voucherComponent.availableCredit'|translate}}:
								<span class="title2 color-primary ">{{commonService.currencySymbol}}
									{{customerInfo.customer_remaining_balance|number}}</span>
							</p>
						</div>
						<div class="br-5 p-15"
							*ngIf="data.payment_type_id === 'ONACCOUNT'&& (customerInfo?.customer_remaining_balance < subtotal) ">
							<p class="color-danger text-bold">{{'customField.onAccountMsg'|translate}}
							</p>
						</div>
					</div>
				</div>


				<div class="bg-gray-90 br-5 p-15"
					*ngIf="this.selectedPaymentMethod && this.selectedPaymentMethod.payment_type_id == 'BANKTRANSFER'">
					<ion-label class="text-bold">{{"customField.chooseBankTransferDetails"|translate}}</ion-label>

					<!-- Bank Option -->
					<div class="border_class mv-40" *ngFor="let data of bankListArray;let i = index">
						<div class="display-div cursor-pointer" (click)="chooseBank(i)">
							<div class="radio-size mr-15">
								<div *ngIf="!data.select" class="radio-div"></div>
								<img *ngIf="data.select" src="/assets/icon/check.svg" alt="Jazeera" />
							</div>

							<div class="width-100">
								<ion-row class="p-0 justify-space-beetwen">
									<div class="bank-div">
										<img [src]="data.bank_logo" [alt]="Jazeera" class="bank-img" />
									</div>
									<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="3" size-xl="3">
										<p><strong>{{"customField.bankName"|translate}}:</strong></p>
										<p class="p-0">{{data.bank_name || '--'}}</p>
									</ion-col>
									<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="3" size-xl="3">
										<p><strong>{{"customField.accountHolderName"|translate}}:</strong> </p>
										<p class="p-0">{{data.account_holder_name || '--'}}</p>
									</ion-col>
									<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="3" size-xl="3">
										<p><strong>{{"customField.accountNumber"|translate}}:</strong></p>
										<p class="p-0"> {{data.account_number || '--'}}</p>
									</ion-col>
								</ion-row>

								<ion-row class="p-0 justify-space-beetwen">
									<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="3" size-xl="3">
										<p><strong>{{"customField.bankSwiftCode"|translate}}:</strong></p>
										<p class="p-0"> {{data.bank_swift_code || '--'}}</p>
									</ion-col>
									<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="3" size-xl="3">
										<p><strong>{{"customField.accountType"|translate}}:</strong> </p>
										<p class="p-0">{{data.account_type || '--'}}</p>
									</ion-col>
									<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="3" size-xl="3">
										<p><strong>{{"customField.IBAN"|translate}}:</strong></p>
										<p class="p-0"> {{data.iban_code || '--'}}</p>
									</ion-col>
								</ion-row>
							</div>

						</div>
					</div>

					<p class="text-bold mv-15 fs-20">{{"customField.shareBankTransferDetail"|translate}}</p>

					<!-- Share Bank Transfer Details -->
					<p class="text-bold mt-20">{{"customField.bankAcknowledgementNumber"|translate}}*</p>
					<ion-item lines="none" class="mb-20">
						<ion-input placeholder="{{'customField.enteracknowledgementnumber'|translate}}"
							[(ngModel)]="bankAcknowledgeNo"></ion-input>
					</ion-item>

					<ion-row>
						<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="3" size-xl="3">
							<ion-label>{{"customField.uploadedPayment"|translate}}*</ion-label>

							<div class="upload-container" (click)="triggerFileInput()">
								<ion-icon name="document-attach-outline"></ion-icon>
								<p class="m-0">{{'customField.clicktoupload'|translate}}</p>
								<input type="file" #fileInput accept="image/*,application/pdf"
									(change)="onFileSelected($event)" multiple hidden />
							</div>
							<!-- Display the list of uploaded files -->
							<div *ngFor="let file of files; index as i" class="file-list">
								<p>{{ file.file_name }}</p>
								<ion-icon name="close-circle-outline" (click)="removeFile(i)"></ion-icon>
							</div>
						</ion-col>
						<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="3" size-xl="3">
							<ion-label position="stacked">{{'customField.notes'|translate}}*</ion-label>
							<ion-textarea class="upload-container2 bg-white p-0" [(ngModel)]="textareaBankNote"
								placeholder="{{'customField.enterdescriptiontomatch'|translate}}"></ion-textarea>
						</ion-col>
					</ion-row>

					<div class="d-flex justify-end mt-20">
						<app-common-button color="#FFF" background="#0081CA" type="solid"
							title="{{selectedBank && selectedBank.bank_id ? ('update'|translate) : ('save'|translate)}}"
							[isIcon]="false" [isHideInMobile]="false" [isFullWidth]="true"
							(buttonClicked)="saveBankDetails()" [specificHeight]="'50px'" [specificWidth]="'150px'"
							[specificFontSize]="'1rem'" [specificFontWeight]="700"
							[buttonDisabled]="this.buttonBankDiabledMethod()">
						</app-common-button>
					</div>

				</div>
			</div>



		</ion-col>

		<div class="divider-line mv-10"></div>

		<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="3" size-xl="3" class="ph-20">
			<p class="title3 color-app-black">{{'cartPage.cartItems' | translate}}</p>
			<div class="cart-modal">
				<div *ngIf="cartData && cartData.result && cartData.result.length">
					<div *ngFor="let data of cartData.result;let i =index">
						<ion-row class="head-div">
							<p class="title-2 color-app-grey">{{data.products.length}}
								{{data.products.length > 1 ? ('cartModal.products'|translate) :
								('cartModal.product'|translate)}}</p>
						</ion-row>

						<div *ngFor="let item of data.products;let j =index" class="p-0">
							<ion-row class="mt-10">
								<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="2" size-xl="2" class="p-0">
									<div class="display-flex">
										<div *ngIf="item.discount_type &&  item.discount_type != 'PRICE' && !item.is_sold_out && !item.is_sample_product"
											class="discout_div ">
											<span>-</span>{{ item.discount_value }}<span>%</span>
										</div>
										<div *ngIf="item.discount_type &&  item.discount_type == 'PRICE' && !item.is_sold_out  && !item.is_sample_product"
											class="discout_div ">
											<span>{{"OnSale" | translate}}</span>
										</div>

										<img *ngIf="item.product_cover_image" (click)="goToProductDetail(item)"
											src="{{item.product_cover_image[0].image_url}}" alt="Jazeera"
											class="product-img pointer" />
										<img *ngIf="!item.product_cover_image" (click)="goToProductDetail(item)"
											src="./assets/images/empty-img.png" class="product-img pointer"
											alt="Jazeera" />

										<div class="pl-15 width-100">
											<p class="label-3 color-app-black pointer"
												(click)="goToProductDetail(item)">
												{{commonService.domDirectionLTR ?
												item.product_name_en: item.product_name_ar}}</p>

											<p class="label-5 color-app-grey "
												*ngFor="let config of item.product_configuration">
												{{commonService.domDirectionLTR ? config.configuration_name_en:
												config.configuration_name_ar}}:
												<span class="color-app-black mh-5">
													{{commonService.domDirectionLTR ?
													config.configuration_value_en:
													config.configuration_value_ar}}</span>
											</p>

											<div class="mv-5 color-app-grey text-left">{{item.product_sku}}</div>


											<p class="label-5 color-app-grey border-div estimated-delivery"
												*ngIf="item.estimated_delivery_date">
												{{'cartPage.EstimatedDelivery' | translate}}: <span
													class="color-app-grey mh-5 estimated-date">
													{{item.estimated_delivery_date}}</span></p>

											<p class="label-5 color-app-grey border-div estimated-delivery"
												*ngIf="item.delivery_days">
												{{'cartPage.EstimatedDelivery' | translate}}: <span
													class="color-app-grey mh-5 estimated-date">
													{{item.delivery_days}} {{item.delivery_days > 1
													?('cartPage.Days'|translate) : ('cartPage.Day'|translate)}}</span>
											</p>

										</div>
									</div>
								</ion-col>
								<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="4" size-xl="4"
									class="pos-absolute">
									<div *ngIf="item.is_sample_product == true" class="sample-div">
										<p>{{'SAMPLE'|translate}}</p>
									</div>
								</ion-col>

								<ion-row class="row-div web-ph-15 mob-pv-5 mob-ph-0">
									<ion-row class="align-center">
										<div class="units-containter">

											<div class="qty_div">
												<p class="label4 color-app-black">{{item.total_quantity}}</p>
												<span class="label6 color-app-grey">&nbsp;
													{{commonService.domDirectionLTR
													?
													item.unit_name_en:item.unit_name_ar}}</span>
											</div>
											<div class="qty_div suom"
												*ngIf="item.unit_id && item.secondary_unit_id && item.unit_id !== item.secondary_unit_id">
												<p class="label4 color-app-black">
													{{calculateSUOM(item.conversation_ratio,item.total_quantity)}}
												</p>
												<span class="label6 color-app-grey ">
													{{commonService.domDirectionLTR ? item.secondary_unit_name_en
													:
													item.secondary_unit_name_ar}} </span>
											</div>
										</div>

										<p class="label-4 color-app-black ph-15">X</p>

										<p class="label-3 color-app-black">
											{{ commonService.currencySymbol }}{{item.is_sample_product ?
											(item.price_per_unit | number) : (item.final_price_per_unit | number)}}
											<span *ngIf="item.unit_name_en && !item.is_sample_product"
												class="label-6 color-app-grey">/{{commonService.domDirectionLTR
												?
												item.unit_name_en:item.unit_name_ar}}</span>
										</p>
									</ion-row>

									<p class="label-4 color-app-black float-end">
										{{commonService.currencySymbol}}{{item.total_price|number}}</p>
								</ion-row>

								<div class="divider-line mv-10" *ngIf="j != (data.products.length - 1)"></div>
							</ion-row>
						</div>
					</div>
				</div>
			</div>

		</ion-col>

		<div class="divider-line mv-10"></div>

		<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="3" size-xl="3" class="ph-20">
			<p class="title3 color-app-black">{{'cartPage.Ordersummary' | translate}}</p>

			<app-order-summary *ngIf="this.cartData && this.cartData.grand_total"
				[shippingEliminated]="cartData.is_shipping_cost_eliminated" [eliminatedRates]="cartData.grand_total"
				currency="{{commonService.currencySymbol}}" orderTotal="{{cartData.grand_total.total_price}}"
				orderSubTotal="{{cartData.grand_total.items_sub_total}}"
				orderDiscount="{{cartData.grand_total.coupon_discounted_value}}" discountCode="{{appliedCouponCode ?
						                                appliedCouponCode : null}}" orderVAT="{{cartData.grand_total.vat}}"
				[orderSummeryData]="cartData.grand_total" orderShipping="{{cartData.grand_total.shipping_charges}}"
				orderShippingWithoutVat="{{cartData.grand_total.shipping_charges_without_vat}}"
				shippingVat="{{cartData.grand_total.shipping_vat_charges}}"
				vatPercent="{{cartData.grand_total.vat_percentage}}">
			</app-order-summary>

			<div class="mv-25">
				<app-common-button color="#FFF" background="#0081CA" type="solid" class="width-100"
					title="{{'checkoutStep2.ReviewOrder'|translate}}" [isIcon]="false" [isHideInMobile]="false"
					[isFullWidth]="true" (buttonClicked)="gotoChekoutReviewPage()" [specificHeight]="'50px'"
					[specificFontSize]="'1rem'" [specificFontWeight]="700" [buttonDisabled]="buttonDiabledMethod()">
				</app-common-button>
			</div>

		</ion-col>

	</ion-row>
</ion-content>


<ng-template let-formObject #validationMessages>
	<ng-container *ngFor="let validation of validation_messages[formObject.controlName]">
		<ion-text color="danger" class="error-msg"
			*ngIf="formObject.formName.controls[formObject.controlName].hasError(validation.type) && formObject.formName.controls[formObject.controlName].touched">
			{{validation.message | translate }}
		</ion-text>
	</ng-container>
</ng-template>