import { DOCUMENT } from "@angular/common";
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import { EventManager } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { NavController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { URLS } from "src/app/_config/api.config";
import { ApiService } from "src/app/services/api-data.service";
import { GetPlatformService } from "src/app/services/get-platform.service";
import { ToastService } from "src/app/services/toast.service";
import { CommonService } from "../../services/common.service";

@Component({
	selector: "app-search-bar",
	templateUrl: "./search-bar.component.html",
	styleUrls: ["./search-bar.component.scss"],
})
export class SearchBarComponent implements OnInit {
	@Input() fromOrdersList;
	@Output() searchClicked = new EventEmitter();
	@Output() clearClicked = new EventEmitter();
	public searchInput: any;
	@Input() searchValue = "";
	private unregisterClick?: Function;
	private unregisterTouch?: Function;
	// searchbar: ElementRef;
	showBackIcon: boolean = false;

	private subscription: Subscription = new Subscription();
	public search_type: any;
	public searchData: any = [];
	public searchTypeData: any = [];
	public results_count = 0;
	public gridArray = [];
	public noRecordFound: boolean = false;
	public searchDataType: any;
	public isLoading = false;
	dialogueWidth: any;
	visibleList: boolean;
	noSearchData: boolean;

	// @ViewChild("searchbar") searchbar: IonSearchbar;
	@ViewChild('searchbar', { static: false }) searchbar: ElementRef;


	constructor(
		public commonService: CommonService,
		private eventManager: EventManager,
		private elementRef: ElementRef,
		public router: Router,
		private route: ActivatedRoute,
		public getPlatformService: GetPlatformService,
		public cdRef: ChangeDetectorRef,
		public dataService: ApiService,
		public toastService: ToastService,
		@Inject(DOCUMENT) private document: any,
		public translate: TranslateService,
		private navCtrl: NavController
	) {

		if (this.searchValue) {
			this.searchValue = this.searchValue.trim();
		}

		this.commonService.eventObservable.subscribe((data) => {
			if (data.event == "emptySerach") {
				this.searchValue = null;
			}
		});

		if (
			this.router.url.includes("/home/search") &&
			!this.getPlatformService.isDesktop
		) {
			this.showBackIcon = true;
		}
	}

	ngOnInit() {
		this.getSearchTypes();
	}

	ngAfterViewInit() { }

	ionViewWillEnter() {
		this.getSearchTypes();
	}

	ionFocus(event) {
		const upPress = new KeyboardEvent("keydown", { key: "Shift" });
		this.document.dispatchEvent(upPress);
	}

	ngAfterViewChecked() {
		if (
			undefined === this.unregisterClick &&
			undefined === this.unregisterTouch
		) {
			const searchIcon: HTMLElement | null =
				this.elementRef.nativeElement.querySelector(".searchbar-search-icon");

			if (searchIcon) {
				searchIcon.style.pointerEvents = "all";
				this.unregisterClick = this.eventManager.addEventListener(
					searchIcon,
					"click",
					this.onSearchIconClick.bind(this)
				);
				this.unregisterTouch = this.eventManager.addEventListener(
					searchIcon,
					"touch",
					this.onSearchIconClick.bind(this)
				);
			}
		}

		const listDiv = this.document.getElementById("listDiv");
		if (listDiv) {
			listDiv.style.width = this.commonService.localStorageGet('dialogueWidth') + 'px';
		}
		this.cdRef.detectChanges();
		const myElement = this.document.getElementById("searchBlock");
		if (myElement) {
			this.cdRef.detectChanges();
			if (
				myElement.offsetWidth != 0 &&
				myElement.offsetWidth != undefined &&
				myElement.offsetWidth != null
			) {
				this.cdRef.detectChanges();
				this.commonService.localStorageSet(
					"dialogueWidth",
					myElement.offsetWidth
				); // Get the width of the your element
			}
		}
	}

	ngOnDestroy() {
		if (undefined !== this.unregisterClick) {
			this.unregisterClick();
		}
		if (undefined !== this.unregisterTouch) {
			this.unregisterTouch();
		}
	}

	ionViewWillLeave() {
		if (undefined !== this.unregisterClick) {
			this.unregisterClick();
		}
		if (undefined !== this.unregisterTouch) {
			this.unregisterTouch();
		}
	}
	onSearchIconClick() {
		if (this.fromOrdersList) {
			this.searchClicked.emit({ data: this.searchValue });
		} else {
			if (!this.searchValue) {
				this.navCtrl.navigateRoot('/' + this.commonService.currentLang + "/home", { animated: false });
			} else {
				this.searchInput = this.searchValue;
				if (this.searchInput) {
					setTimeout(() => {
						this.commonService.setEvent("emptySerach", null);
						this.searchValue = null;
					}, 300);

					this.navCtrl.navigateForward(['/' + this.commonService.currentLang + "/home/search/" + this.commonService.trimUrl(this.searchInput)], { animated: false, state: { searchInput: this.searchInput } });
				}
			}
		}
	}

	clearClick() {
		this.searchValue = null;
		if (this.fromOrdersList) {
			this.clearClicked.emit({ data: null });
		} else {
			if (!this.searchValue) {
				this.navCtrl.navigateRoot('/' + this.commonService.currentLang + "/home", { animated: false });
			} else {
				this.searchInput = this.searchValue;
				if (this.searchInput) {
					this.navCtrl.navigateForward(['/' + this.commonService.currentLang + "/home/search/" + this.commonService.trimUrl(this.searchInput)], { animated: false, state: { searchInput: this.searchInput } });
				}
			}
		}
	}

	getSearchList(event) {
		this.searchInput = this.searchValue;
		this.searchTypeData.forEach((element) => {
			this.getSearchResults(element);
		});
	}

	focusOnSearch() {
		this.visibleList = true;
	}

	focusOutSearch(event: FocusEvent) {
		event.stopPropagation();
		event.preventDefault();
		setTimeout(() => {
			this.visibleList = false;
		}, 300);
	}

	search(event) {
		setTimeout(() => {
			if (this.fromOrdersList) {
				this.searchClicked.emit({ data: this.searchValue });
			} else {
				this.searchInput = this.searchValue;
				if (this.searchInput) {
					setTimeout(() => {
						this.commonService.setEvent("emptySerach", null);
						this.searchValue = null;
					}, 300);
					this.navCtrl.navigateForward(['/' + this.commonService.currentLang + "/home/search/" + this.commonService.trimUrl(this.searchInput)], { animated: false, state: { searchInput: this.searchInput } });
				}
			}
		}, 200);
	}

	async goToProductDetail(data) {
		let prod_id = data.product_id;
		let prodnameUrl = this.commonService.trimUrl(data.product_name ? data.product_name : data.product_name_en);
		let subClassNameURL = this.commonService.trimUrl(this.commonService.domDirectionLTR ? data.display_sub_class_name_en : data.display_sub_class_name_en);
		await this.searchProductEntry(data.item_id);
		this.navCtrl.navigateForward('/' + this.commonService.currentLang + "/" + subClassNameURL + "/" + prodnameUrl + "/" + prod_id, {
			animated: false,
			state: {
				breadcrumb: data.product_description,
				url: "/" + subClassNameURL + "/" + prodnameUrl + "/" + prod_id,
			},
		});
	}

	async goToProductListing(data) {
		let sub_class_id = data.item_id;
		let subClassNameURL: any = this.commonService.trimUrl(data.item_name_en ? data.item_name_en : null);
		let currentLang = this.commonService.currentLang;
		await this.navCtrl.navigateForward('/' + this.commonService.currentLang + '/product-listing/' + subClassNameURL + "/" + sub_class_id, {
			animated: false, "state": {
				"breadcrumb":
					this.commonService.domDirectionLTR ? data['item_name_en'] : data['item_name_ar'],
				"url": '/product-listing/' + subClassNameURL + "/" + sub_class_id,
				"id": data.item_id,
				"ar_label": data.item_name_ar,
				"banner_img": data.item_image_web
			}
		});
	}

	getSearchResults(searchData: any, isScrollCalled?: boolean) {
		return new Promise((resolve, reject) => {
			let params = {
				search: this.searchInput ? this.searchInput.trim() : null,
				search_type_id: searchData.search_type_id,
				page: 1,
				limit: 10,
			};

			this.subscription.add(
				this.dataService.get(URLS.getSearchResults, params).subscribe(
					(data) => {
						let dataArray: any;
						if (data["code"] == 200) {
							dataArray = data["data"];
							if (this.searchInput) {
								this.search_type = this.searchInput.trim();
							}
							searchData.enable_next = dataArray.enable_next;
							if (searchData.search_type_en == "PRODUCTS" && dataArray) {
								searchData.total_count = dataArray.total_count
									? dataArray.total_count
									: 0;
								searchData.result = dataArray.result;
								this.searchTypeData.forEach((element) => {
									if (element.search_type_id == searchData.search_type_id) {
										element.total_count = dataArray.total_count
											? dataArray.total_count
											: 0;
										element.result = dataArray.result;
										element.enable_next = dataArray.enable_next;
									}
									this.results_count =
										this.results_count +
										parseInt(element.total_count ? element.total_count : 0);
								});
								if (dataArray.result && dataArray.result.length) {
									dataArray.result.forEach((element2) => {
										element2.product_description = element2.item_name_en
											? element2.item_name_en
											: "";
										element2.product_description_ar = element2.item_name_ar
											? element2.item_name_ar
											: "";
										element2.product_name = element2.item_name_en
											? element2.item_name_en
											: "";
										element2.product_name_ar = element2.item_name_ar
											? element2.item_name_ar
											: "";
										element2.brand_name = element2.reference_name_en
											? element2.reference_name_en
											: "";
										element2.brand_name_ar = element2.reference_name_ar
											? element2.reference_name_ar
											: "";
										element2.product_price = element2.item_price
											? element2.item_price
											: "0";
										element2.price_per_unit = element2.item_price
											? element2.item_price
											: "0";
										element2.final_price = element2.final_price
											? element2.final_price
											: "0";
										element2.product_unit = this.commonService.domDirectionLTR
											? element2.item_unit_en
											: element2.item_unit_ar;
										element2.product_id = element2.item_id
											? element2.item_id
											: null;
										element2.item_id = element2.item_id
											? element2.item_id
											: null;
										element2.discount_value = element2.discount_value
											? element2.discount_value
											: "";
										element2.discount_type = element2.discount_type
											? element2.discount_type
											: "";
										element2.wishlist_id = element2.wishlist_id
											? element2.wishlist_id
											: null;
										element2.sold_quantity = element2.sold_quantity
											? element2.sold_quantity
											: null;
										element2.is_discount_applied = element2.is_discount_applied
											? element2.is_discount_applied
											: null;
										element2.is_tax_free_product = element2.is_tax_free_product
											? element2.is_tax_free_product
											: null;
										element2.available_quantity = element2.available_quantity
											? element2.available_quantity
											: null;
										element2.is_sold_out = element2.is_sold_out
											? element2.is_sold_out
											: null;
										element2.product_sku = element2.product_sku
											? element2.product_sku
											: null;
										element2.total_quantity = element2.total_quantity
											? element2.total_quantity
											: null;
										element2.is_wishlist_product = element2.is_wishlist_product
											? element2.is_wishlist_product
											: null;

										if (this.getPlatformService.isDesktop) {
											element2["product_img"] = element2.item_image_web
												? element2.item_image_web
												: "assets/images/no-img1.png";
										} else {
											element2["product_img"] = element2.item_image_mobile
												? element2.item_image_mobile
												: "assets/images/no-img1.png";
										}
										this.gridArray.push(element2);
									});
								}
							}
							if (searchData.search_type_en == "CATEGORIES" && dataArray) {
								searchData.total_count = dataArray.total_count
									? dataArray.total_count
									: 0;
								searchData.result = dataArray.result;
								this.searchTypeData.forEach((element) => {
									if (element.search_type_id == searchData.search_type_id) {
										element.total_count = dataArray.total_count
											? dataArray.total_count
											: 0;
										element.result = dataArray.result;
										element.enable_next = dataArray.enable_next;
									}
									this.results_count =
										this.results_count +
										parseInt(element.total_count ? element.total_count : 0);
								});
							}

							let noCategoryData;
							let noProductData;
							this.searchTypeData.forEach((element) => {
								if (element.search_type_en == "CATEGORIES" && !element.result) {
									noCategoryData = true;
								}
								if (element.search_type_en == "PRODUCTS" && !element.result) {
									noProductData = true;
								}
							});
							if (noCategoryData == true && noProductData == true) {
								this.noSearchData = true;
							} else {
								this.noSearchData = false;
							}
							resolve(data);
						} else {
							reject();
						}
					},
					(error) => {
						reject();
						let dataArray: any;
					}
				)
			);
		});
	}

	getSearchTypes(isScrollCalled?: boolean) {
		this.isLoading = true;
		this.subscription.add(
			this.dataService.get(URLS.getSearchTypes).subscribe(
				(data) => {
					if (data["code"] == 200) {
						let promiseArray = [];

						this.searchTypeData = data["data"];
						if (this.searchValue) {
							this.searchTypeData.forEach((element) => {
								promiseArray.push(this.getSearchResults(element));
								this.searchDataType = element;
							});
						}
						Promise.all(promiseArray).then(
							(data) => {
								this.isLoading = false;
							},
							(error) => {
								this.isLoading = false;
							}
						);
					} else {
						this.isLoading = false;
					}
				},
				(error) => {
					this.isLoading = false;
				}
			)
		);
	}

	searchProductEntry(product_id) {
		let params = { product_id: product_id };
		this.subscription.add(
			this.dataService.post(URLS.searchedProductAdd, params).subscribe(
				(data) => {
					if (data["code"] == 200) {
						this.isLoading = false;
					} else {
						this.isLoading = false;
						this.toastService.displayToast(data["message"], "danger");
					}
				},
				(error) => {
					this.isLoading = false;
					this.toastService.displayToast(error["message"], null, "danger");
				}
			)
		);
	}
}
