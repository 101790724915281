import { Component, OnInit } from "@angular/core";
// import { Geolocation } from '@capacitor/geolocation';
import { NavigationExtras, Router } from "@angular/router";
import { NavController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { GetPlatformService } from "src/app/services/get-platform.service";
import { URLS } from "../../_config/api.config";
import { ApiService } from "../../services/api-data.service";
import { CommonService } from "../../services/common.service";
declare var google;
declare var navigator;
@Component({
	selector: "app-notification-category",
	templateUrl: "./notification-category.component.html",
	styleUrls: ["./notification-category.component.scss"],
})
export class NotificationCategoryComponent implements OnInit {
	private subscription: Subscription = new Subscription();

	notificationCategoryArray: any = [];
	noData: boolean = false;
	public isLoading = false;

	constructor(
		public getPlatformService: GetPlatformService,
		private dataService: ApiService,
		public navCtrl: NavController,
		public translate: TranslateService,
		public commonService: CommonService,
		public router: Router
	) { }

	ngOnInit() {
		this.getNotificationsCategory();
	}

	ionViewWillEnter() {
		this.getNotificationsCategory();
	}

	onChange(event): void {
		this.getNotificationsCategory();
	}

	getNotificationsCategory() {
		this.isLoading = true;
		this.noData = false;
		const params = {
			dropdown: true,
		};
		this.subscription.add(
			this.dataService.get(URLS.notificationListType, params).subscribe(
				(data) => {
					if (data && data["code"] == 200) {
						this.isLoading = false;
						let list = data["data"].length ? data["data"] : [];
						this.notificationCategoryArray = [];
						list.forEach((element) => {
							let obj = {
								id: element.notification_type_id,
								title: element.notification_type_en
									? element.notification_type_en
									: "",
								title_ar: element.notification_type_ar
									? element.notification_type_ar
									: "",
								unread: element.unread ? element.unread : "",
								image_url: element.image_url ? element.image_url : "",
								is_active: element.is_enabled ? true : false,
								sequence: element.sequence,
							};
							this.notificationCategoryArray.push(obj);
						});
						if (this.notificationCategoryArray.length < 1) {
							this.noData = true;
						}
					}
				},
				(error) => {
					this.isLoading = false;
					this.noData = false;
				}
			)
		);
	}

	goBack() {
		this.navCtrl.back({ animated: false });
	}

	gotoNotificationlist(data) {
		this.router.navigate(['/' + this.commonService.currentLang + "/home/account-details/notification/" + data.id], {
			state: {
				breadcrumb: this.commonService.domDirectionLTR
					? data.title
					: data.title_ar,
				url: "/home/account-details/notification/" + data.id,
			},
		});
	}

	gotoMobileNotificationlist(data) {
		const navigationExtras: NavigationExtras = {
			queryParams: {
				categoryId: data.id,
			},
		};

		this.router.navigate(['/' + this.commonService.currentLang + "/my-account/notification-list"], navigationExtras);
	}
}
