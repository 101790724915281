<div class="account-information" *ngIf="salesInfo && !isMobile">
	<div class="account-info-grid">
		<ion-grid>
			<ion-row>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"Salesmen Code"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{salesInfo.salesman_code || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"Salesmen Name"}}
						</div>
						<div class="information-container">
							<div class="security-note">
								<span>{{salesInfo.salesman_name || '--'}}</span>
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>

			<ion-row class="mt-30">
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"Distribution Channel Code"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{salesInfo.distribution_channel_code || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"Distribution Channel Name"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{salesInfo.distribution_channel_name || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>

			<ion-row class="mt-30">
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"Sales Office Code"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{salesInfo.sales_office_code || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"Sales Office Name"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{salesInfo.sales_office_name || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>

			<ion-row class="mt-30">
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"Sales Group Code"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{salesInfo.sales_group_code || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"Sales Group Name"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{salesInfo.sales_group_name || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>

			<ion-row class="mt-30">
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"Price Group Code"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{salesInfo.price_group_code || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>
		</ion-grid>
	</div>
</div>
<div class="account-information" *ngIf="salesInfo && isMobile">
	<div class="account-info-grid">
		<ion-grid>
			<ion-row>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"Salesmen Code"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{salesInfo.salesman_code || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"Salesmen Name"}}
						</div>
						<div class="information-container">
							<div class="security-note">
								<span>{{salesInfo.salesman_name || '--'}}</span>
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>

			<ion-row>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"Distribution Channel Code"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{salesInfo.distribution_channel_code || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"Distribution Channel Name"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{salesInfo.distribution_channel_name || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>

			<ion-row>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"Sales Office Code"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{salesInfo.sales_office_code || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"Sales Office Name"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{salesInfo.sales_office_name || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>

			<ion-row>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"Sales Group Code"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{salesInfo.sales_group_code || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"Sales Group Name"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{salesInfo.sales_group_name || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>

			<ion-row>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"Price Group Code"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{salesInfo.price_group_code || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>
		</ion-grid>
	</div>
</div>