import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import 'firebase/analytics';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';



fetch('/assets/config.json')
	.then((response) => response.json())
	.then((config) => {
		environment.sentryAuthToken = config.sentryAuthToken;
		environment.SENTRY_AUTH_TOKEN = config.sentryAuthToken;
		platformBrowserDynamic()
			.bootstrapModule(AppModule)
			.catch((err) => console.error(err));
	});

if (environment.production) {
	enableProdMode();
}

function bootstrap() {
	platformBrowserDynamic().bootstrapModule(AppModule)
		.catch(err => console.log(err));
};


if (document.readyState === 'complete') {
	bootstrap();
} else {
	document.addEventListener('DOMContentLoaded', bootstrap);
}

