<app-loader *ngIf="isLoading"></app-loader>
<div class="my-account" *ngIf="getPlatformService.isDesktop && !isLoading">
	<div class="main-title">
		<h2>{{'myAccountComponent.myAccount' | translate}}</h2>
	</div>
	<ng-container *ngFor="let data of componentData">
		<div *ngIf="!isLoading">
			<div class="section-heading">
				<div class="section-name">{{data.section_name | translate}}</div>
				<!-- code maybe needed in future -->
				<!-- <div class="section-button-containter" *ngIf="data.section_btn">
                    <span class="section-btn" (click)="data.id == '0' ? editProfileWebOpen() : manageAddressClick()">
                        {{data.section_btn | translate}}
                    </span>
                </div> -->
			</div>

			<div class="section-content" class="pt-30" *ngIf="!isLoading && customerSAPData">
				<!-- <app-orders *ngIf="data.id == '2'"></app-orders> -->
				<app-account-information *ngIf="data.id == '0'"></app-account-information>
				<app-payment-information *ngIf="data.id == '1' && customerSAPData" [paymentInfo]="customerSAPData">
				</app-payment-information>
				<app-customer-group *ngIf="data.id == '2' && customerSAPData" [customerGroupInfo]="customerSAPData">
				</app-customer-group>
				<app-financial-details *ngIf="data.id == '3' && customerSAPData" [financialInfo]="customerSAPData">
				</app-financial-details>
				<app-sales-information *ngIf="data.id == '4' && customerSAPData" [salesInfo]="customerSAPData">
				</app-sales-information>
				<app-address *ngIf="data.id == '5'" [showEditBtn]="true" [showTable]="false"></app-address>
			</div>
		</div>
	</ng-container>
	<div class="status-info-container">
		<div class="status-header">
			Status
		</div>
		<div class="status-label pt-30 pl-10">
			Acitve
		</div>
		<div class="status pl-10">
			Yes
		</div>
	</div>
</div>

<ng-container *ngIf="!getPlatformService.isDesktop && !isLoading">
	<ion-header class="address-header" mode="md">
		<ion-toolbar mode="md">
			<img src="./assets/icon/leftArrow.svg" alt="back-arrow" slot="start" (click)="goBack()"
				[ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
			<ion-title mode="ios">
				<div>{{'myAccountComponent.myAccountDetails'| translate}}</div>
			</ion-title>
		</ion-toolbar>
	</ion-header>




	<ion-content *ngIf="!isLoading">

		<ng-container *ngFor="let data of mobComponentData">
			<div class="my-mob-account" *ngIf="!isLoading">
				<div class="section-heading">
					<div class="section-name">{{data.section_name | translate}}</div>
				</div>

				<div class="section-content" *ngIf="!isLoading && customerSAPData">
					<!-- <app-orders *ngIf="data.id == '2'"></app-orders> -->
					<app-account-information *ngIf="data.id == '0'"
						[isMobile]="!getPlatformService.isDesktop"></app-account-information>
					<app-payment-information [isMobile]="!getPlatformService.isDesktop"
						*ngIf="data.id == '1' && customerSAPData" [paymentInfo]="customerSAPData">
					</app-payment-information>
					<app-customer-group [isMobile]="!getPlatformService.isDesktop"
						*ngIf="data.id == '2' && customerSAPData" [customerGroupInfo]="customerSAPData">
					</app-customer-group>
					<app-financial-details [isMobile]="!getPlatformService.isDesktop"
						*ngIf="data.id == '3' && customerSAPData" [financialInfo]="customerSAPData">
					</app-financial-details>
					<app-sales-information [isMobile]="!getPlatformService.isDesktop"
						*ngIf="data.id == '4' && customerSAPData" [salesInfo]="customerSAPData">
					</app-sales-information>
					<!-- Commenting address code as of now, as per pooja's instructions -->
					<!-- <app-address [isMobile]="!getPlatformService.isDesktop" *ngIf="data.id == '5'" [showEditBtn]="true"
						[showTable]="false"></app-address> -->


				</div>
			</div>
		</ng-container>
		<div class=" my-mob-account status-info-container">
			<div class="mob-status-header">
				Status
			</div>
			<div class="status-label  pl-10">
				Acitve
			</div>
			<div class="status pl-10">
				Yes
			</div>
		</div>
		<!-- Don't remove important code -->
		<!-- <div class="ph-20 pb-30">
            <app-common-button color="#FFF" background="#0081CA" type="solid" style="width: 100%;"
                (click)="editProfileOpen()" title="{{'Edit' | translate}}" [isIcon]="false" [isHideInMobile]="false"
                [isFullWidth]="true" [specificFontSize]="'1rem'">
            </app-common-button>
        </div> -->

	</ion-content>


</ng-container>