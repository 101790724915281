import { DatePipe, isPlatformServer } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { IonContent, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MobileStatementFilterComponent } from 'src/app/components/mobile-statement-filter/mobile-statement-filter.component';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { URLS } from '../../_config/api.config';
import { ModalService } from '../../services/modal.service';
import { ApiService } from './../../services/api-data.service';
import { CommonService } from './../../services/common.service';
import { ToastService } from './../../services/toast.service';


@Component({
	selector: 'app-statement-mobile',
	templateUrl: 'statement-mobile.page.html',
	styleUrls: ['statement-mobile.page.scss'],
	providers: []
})
export class StatementMobilePage {
	@ViewChild(IonContent) content: IonContent;
	isLoading = false;
	isDownloading = false;
	searchText: any;
	private subscription: Subscription = new Subscription();
	perPageInterface = { cssClass: "custom-select-dd per-page-dd" };
	currencySymbol: string = "SR";
	showSearch = false;
	enableNext = false;
	filterObj = {};
	showFilter = false;

	public count = 0;

	public itemsPerPage = "10";

	public currentPage = 1;
	selectInterface = { cssClass: "custom-select-dd", size: "cover" };
	selectStatus: any = "all";
	fromDate: any;
	toDate: any;
	dateRange: FormGroup;

	docFromDate: any;
	docToDate: any;

	defaultFromDate: any;
	defaultToDate: any;
	defaultDateRange: FormGroup;
	@ViewChild('popover') popover!: HTMLIonPopoverElement;
	isOpen = false;
	maxFromDate: any;
	maxDate: any;
	minToDate: any;
	todaysDate = new Date();
	columnHeaders = {
		order_id: "Statement #",
		date: "Date",
		ship_to: "Ship to",
		order_total: "Order total",
		status: "Status",
	};
	statusList = [];
	statementList = [];
	backupStatementList = [];
	@Input() showHeader: boolean;
	@Input() from: any;
	@Input() dataArray: any;
	gridViewData: any = [];
	doc_no_filter: any;
	credit_filter: any;
	debit_filter: any;
	isLoggedIn: boolean;
	currentLang;
	constructor(
		private modalService: ModalService,
		private dataService: ApiService,
		private toastService: ToastService,
		public commonService: CommonService,
		private navCtrl: NavController,

		private translateService: TranslateService,
		private fb: FormBuilder,
		public datePipe: DatePipe,
		private router: Router,
		@Inject(PLATFORM_ID) private platformId: Object,
		private exportService: ExportExcelService,
		public getPlatformService: GetPlatformService,
		public platform: Platform,
	) {

		this.currentLang = this.translateService.currentLang;
		if (this.router.url.includes("my-statement")) {
			this.showHeader = true;
		}

		this.commonService.eventObservable.subscribe(data => {
			if (data.event == 'profileData' || data.event == 'logoutCall') {
				const token = this.commonService.localStorageGet('accessToken');
				if (token) { this.isLoggedIn = true; } else this.isLoggedIn = false;
				if (this.isLoggedIn) {
					// this.getOrderList();  uncomment when integrating API

				}
			}
		});
	}

	ngOnInit() {
		if (this.router.url.includes("my-statement")) {
			this.showHeader = true;

			this.statusList = [
				{ value: "all", label: this.translateService.instant("all") },
				{
					value: "CONFIRMED",
					label: this.translateService.instant("confirmed"),
				},
				{
					value: "DISPATCHED",
					label: this.translateService.instant("dispatched"),
				},
				{
					value: "DELIVERED",
					label: this.translateService.instant("delivered"),
				},
			];
			let date = new Date();
			this.maxFromDate = date.setDate(this.todaysDate.getDate() - 1);
			this.maxDate = this.datePipe.transform(this.todaysDate, "YYYY-MM-dd");
			this.maxFromDate = this.datePipe.transform(this.maxFromDate, "YYYY-MM-dd");

			this.initializeDefaultDateForm();
			this.initializeDateForm();
		}
		if (this.from == "dashboard") {
			this.statementList = this.dataArray;
		}

		const token = this.commonService.localStorageGet('accessToken');
		if (token) { this.isLoggedIn = true; } else this.isLoggedIn = false;

		if (this.isLoggedIn) {
			this.filterObj = {};
			this.currentPage = 1;
			this.getStatementList(this.filterObj);

		}
	}

	ionViewWillEnter() {
		let date = new Date();
		this.maxFromDate = date.setDate(this.todaysDate.getDate() - 1);
		this.maxDate = this.datePipe.transform(this.todaysDate, "YYYY-MM-dd");
		this.maxFromDate = this.datePipe.transform(this.maxFromDate, "YYYY-MM-dd");

		this.initializeDefaultDateForm();
		this.initializeDateForm();
		if (this.router.url.includes("my-statement")) {
			this.showHeader = true;
			this.statusList = [
				{ value: "all", label: this.translateService.instant("all") },
				{
					value: "CONFIRMED",
					label: this.translateService.instant("confirmed"),
				},
				{
					value: "DISPATCHED",
					label: this.translateService.instant("dispatched"),
				},
				{
					value: "DELIVERED",
					label: this.translateService.instant("delivered"),
				},
			];
		}
		if (this.from == "dashboard") {
			this.statementList = this.dataArray;
		}
		const token = this.commonService.localStorageGet('accessToken');
		if (token) { this.isLoggedIn = true; } else this.isLoggedIn = false;

		if (this.isLoggedIn) {
			this.filterObj = {};
			this.currentPage = 1;
			this.getStatementList(this.filterObj);

		}
	}

	initializeDateForm() {
		this.dateRange = this.fb.group({
			from: new FormControl(null),
			to: new FormControl(null),
			docfrom: new FormControl(null),
			docto: new FormControl(null),
		});

	}
	initializeDefaultDateForm() {
		this.defaultDateRange = this.fb.group({
			defaultfrom: new FormControl(null),
			defaultto: new FormControl(null),
		});

	}


	applyBtn() {
		if ((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) {
			if (!this.fromDate) {
				let msg = this.commonService.domDirectionLTR
					? "Please select 'From Date'."
					: 'الرجاء تحديد "من التاريخ".';
				this.toastService.displayToast(msg, null, "danger");
			}
			if (!this.toDate) {
				let msg = this.commonService.domDirectionLTR
					? "Please select 'To Date'."
					: 'الرجاء تحديد "حتى الآن".';
				this.toastService.displayToast(msg, null, "danger");
			}
			return;
		}
		if (this.fromDate && this.toDate) {
			this.filterObj["post_from_date"] = this.datePipe.transform(
				this.fromDate,
				"yyyy-MM-dd"
			);
			this.filterObj["post_to_date"] = this.datePipe.transform(
				this.toDate,
				"yyyy-MM-dd"
			);
		}
		if (this.filterObj["post_from_date"] && this.filterObj["post_to_date"]) {
			if (this.filterObj["post_from_date"] > this.filterObj["post_to_date"]) {
				let msg = this.commonService.domDirectionLTR
					? "'From Date' cannot be greater than 'To Date'."
					: 'لا يمكن أن يكون "من تاريخ" أكبر من "إلى تاريخ".';
				this.toastService.displayToast(msg, null, "danger");
				return;
			}
		}
		let isValid = false;
		if (
			isValid &&
			this.filterObj["post_from_date"] &&
			this.filterObj["post_to_date"] &&
			this.filterObj["post_from_date"] == this.filterObj["post_to_date"]
		) {
			let msg = this.commonService.domDirectionLTR
				? "'From Date' and 'To Date' cannot be same."
				: 'لا يمكن أن يتطابق "من تاريخ" و "إلى تاريخ".';
			this.toastService.displayToast(msg, null, "danger");
			return;
		}




		let docDateObject = {};
		if ((this.docFromDate && !this.docToDate) || (!this.docFromDate && this.docToDate)) {
			if (!this.docFromDate) {
				let msg = this.commonService.domDirectionLTR
					? "Please select 'From Date'."
					: 'الرجاء تحديد "من التاريخ".';
				this.toastService.displayToast(msg, null, "danger");
			}
			if (!this.docToDate) {
				let msg = this.commonService.domDirectionLTR
					? "Please select 'To Date'."
					: 'الرجاء تحديد "حتى الآن".';
				this.toastService.displayToast(msg, null, "danger");
			}
			return;
		}
		if (this.docFromDate && this.docToDate) {
			docDateObject["doc_from_date"] = this.datePipe.transform(
				this.docFromDate,
				"yyyy-MM-dd"
			);
			docDateObject["doc_to_date"] = this.datePipe.transform(
				this.docToDate,
				"yyyy-MM-dd"
			);
		}
		if (docDateObject["doc_from_date"] && docDateObject["doc_to_date"]) {
			if (docDateObject["doc_from_date"] > docDateObject["doc_to_date"]) {
				let msg = this.commonService.domDirectionLTR
					? "'From Date' cannot be greater than 'To Date'."
					: 'لا يمكن أن يكون "من تاريخ" أكبر من "إلى تاريخ".';
				this.toastService.displayToast(msg, null, "danger");
				return;
			}
		}
		let isDocValid = false;
		if (
			isDocValid &&
			docDateObject["doc_from_date"] &&
			docDateObject["doc_to_date"] &&
			docDateObject["doc_from_date"] == docDateObject["doc_to_date"]
		) {
			let msg = this.commonService.domDirectionLTR
				? "'From Date' and 'To Date' cannot be same."
				: 'لا يمكن أن يتطابق "من تاريخ" و "إلى تاريخ".';
			this.toastService.displayToast(msg, null, "danger");
			return;
		}


		if (this.selectStatus == "all") {
			delete this.filterObj["status"];
		} else {
			this.filterObj["status"] = this.selectStatus;
		}

		if (this.doc_no_filter) {
			this.filterObj["doc_no"] = this.doc_no_filter;
		}

		if (this.credit_filter) {
			this.filterObj["credit"] = this.credit_filter;
		}

		if (this.debit_filter) {
			this.filterObj["debit"] = this.debit_filter;
		}
		if (docDateObject["doc_from_date"] && docDateObject["doc_to_date"]) {
			this.filterObj["doc_from_date"] = docDateObject["doc_from_date"];
			this.filterObj["doc_to_date"] = docDateObject["doc_to_date"];
		}

		if (docDateObject["post_from_date"] && docDateObject["post_to_date"]) {
			this.filterObj["post_from_date"] = docDateObject["post_from_date"];
			this.filterObj["post_to_date"] = docDateObject["post_to_date"];
		}

		this.currentPage = 1;
		// this.getStatementList(this.filterObj, false, false, true);

		this.processAndPaginateData(this.backupStatementList, this.itemsPerPage, this.currentPage, false, false, this.filterObj)
			.then((statementList) => {
			})
			.catch((error) => {
				console.error("Error processing data:", error);
			});
	}

	clearFilter() {
		this.fromDate = null;
		this.toDate = null;
		this.docFromDate = null;
		this.docToDate = null;
		this.doc_no_filter = null;
		this.debit_filter = null;
		this.credit_filter = null;
		this.selectStatus = "all";
		this.dateRange.reset();
		this.filterObj = {};
		this.processAndPaginateData(this.backupStatementList, this.itemsPerPage, this.currentPage, false, false, null)
			.then((statementList) => {
			})
			.catch((error) => {
				console.error("Error processing data:", error);
			});
	}

	applyDefaultBtn() {
		let defaultDateObject = {};
		if ((this.defaultFromDate && !this.defaultToDate) || (!this.defaultFromDate && this.defaultToDate)) {
			if (!this.defaultFromDate) {
				let msg = this.commonService.domDirectionLTR
					? "Please select 'From Date'."
					: 'الرجاء تحديد "من التاريخ".';
				this.toastService.displayToast(msg, null, "danger");
			}
			if (!this.defaultToDate) {
				let msg = this.commonService.domDirectionLTR
					? "Please select 'To Date'."
					: 'الرجاء تحديد "حتى الآن".';
				this.toastService.displayToast(msg, null, "danger");
			}
			return;
		}
		if (this.defaultFromDate && this.defaultToDate) {
			defaultDateObject["start_date"] = this.datePipe.transform(
				this.defaultFromDate,
				"yyyy-MM-dd"
			);
			defaultDateObject["end_date"] = this.datePipe.transform(
				this.defaultToDate,
				"yyyy-MM-dd"
			);
		}
		if (defaultDateObject["start_date"] && defaultDateObject["end_date"]) {
			if (defaultDateObject["start_date"] > defaultDateObject["end_date"]) {
				let msg = this.commonService.domDirectionLTR
					? "'From Date' cannot be greater than 'To Date'."
					: 'لا يمكن أن يكون "من تاريخ" أكبر من "إلى تاريخ".';
				this.toastService.displayToast(msg, null, "danger");
				return;
			}
		}
		let isValid = false;
		if (
			isValid &&
			defaultDateObject["start_date"] &&
			defaultDateObject["end_date"] &&
			defaultDateObject["start_date"] == defaultDateObject["end_date"]
		) {
			let msg = this.commonService.domDirectionLTR
				? "'From Date' and 'To Date' cannot be same."
				: 'لا يمكن أن يتطابق "من تاريخ" و "إلى تاريخ".';
			this.toastService.displayToast(msg, null, "danger");
			return;
		}

		this.currentPage = 1;
		this.getStatementList();
	}

	clearDefaultFilter() {
		this.statementList = []
		this.defaultFromDate = null;
		this.defaultToDate = null;
		this.defaultDateRange.reset();
		this.getStatementList();
	}

	onDateChange(datetime) {
		datetime.cancel(true);
	}

	search() {
		if (!this.searchText) return;
		this.filterObj["search"] = this.searchText;
		this.currentPage = 1;

		this.processAndPaginateData(this.backupStatementList, this.itemsPerPage, this.currentPage, false, false, null)
			.then((statementList) => {
			})
			.catch((error) => {
				console.error("Error processing data:", error);
			});
	}

	openFilters() {
		this.showFilter = !this.showFilter;
	}

	onChange(event): void {
		this.currentPage = event;
		this.processAndPaginateData(this.backupStatementList, this.itemsPerPage, this.currentPage, false, false)
			.then((statementList) => {
			})
			.catch((error) => {
				console.error("Error processing data:", error);
			});
	}

	getStatementList(params?, infiniteScroll?, download?, filter?) {
		if (!download && this.defaultFromDate && this.defaultToDate) {
			try {
				this.defaultFromDate = this.datePipe.transform(this.defaultFromDate, "yyyy-MM-dd") || '';
				this.defaultToDate = this.datePipe.transform(this.defaultToDate, "yyyy-MM-dd") || '';

				params = {
					page: this.currentPage,
					limit: +this.itemsPerPage,
					start_date: this.defaultFromDate,
					end_date: this.defaultToDate,
					...params,
				};
			} catch (error) {
				console.error("Error in date transformation:", error);
				return Promise.reject(error);
			}
		}

		return new Promise((resolve, reject) => {
			if (!download) {
				this.isLoading = true;
			}
			this.subscription.add(
				this.dataService.post(URLS.statementList, params).subscribe(
					(data) => {
						if (data?.code === 200) {
							this.gridViewData = data['data'] || [];
							this.enableNext = data['data']['enable_next'] ? true : false;
							this.backupStatementList = data['data'].statements || [];
							this.backupStatementList.forEach(element => {
								if (element.document_date == "0000-00-00") element.document_date = null;
								if (element.document_date == "    -  -  ") element.document_date = null;
								if (element.invoice_date == "    -  -  ") element.invoice_date = null;
								if (element.invoice_date == "0000-00-00") element.invoice_date = null;
							});

							this.processAndPaginateData(
								this.backupStatementList,
								this.itemsPerPage,
								this.currentPage,
								download,
								infiniteScroll
							)
								.then((statementList) => {
									resolve(statementList);
								})
								.catch((error) => {
									console.error("Error processing data:", error);
									reject(error);
								});
						} else {
							reject(data?.message || "Failed to fetch data");
						}
						this.isLoading = false;
					},
					(error) => {
						console.error("Error in API call:", error);
						reject(error);
						this.isLoading = false;
					}
				)
			);
		});
	}


	processAndPaginateData(data, pageSize, pageIndex, download = false, infiniteScroll, filterPayload?) {
		return new Promise((resolve, reject) => {
			if (!data) {
				reject("No data available.");
				return;
			}

			let filterData = [];

			if (filterPayload) {
				// Apply filtering

				filterData = filterData = data.filter((item) => {
					const doc_from_date = filterPayload.doc_from_date ? new Date(filterPayload.doc_from_date) : null;
					const doc_to_date = filterPayload.doc_to_date ? new Date(filterPayload.doc_to_date) : null;

					const post_from_date = filterPayload.post_from_date ? new Date(filterPayload.post_from_date) : null;
					const post_to_date = filterPayload.post_to_date ? new Date(filterPayload.post_to_date) : null;

					const documentDate = item.document_date && item.document_date != "0000-00-00" ? new Date(item.document_date) : null;
					const invoiceDate = item.invoice_date && item.invoice_date != "0000-00-00" ? new Date(item.invoice_date) : null;
					// Match conditions only if the respective filter exists
					const matchesDocNo = filterPayload.doc_no ? item.document_number.includes(filterPayload.doc_no) : false;
					const matchesCredit = filterPayload.credit ?
						(parseFloat(item.invoice_credit_amount || "0") == parseFloat(filterPayload.credit)) : false;
					const matchesDebit = filterPayload.debit ? (parseFloat(item.invoice_debit_amount || "0") == parseFloat(filterPayload.debit)) : false;

					const withinDateRange =
						doc_from_date && doc_to_date && documentDate
							? documentDate >= doc_from_date && documentDate <= doc_to_date
							: false;

					const withinPostDateRange =
						post_from_date && post_to_date && invoiceDate
							? invoiceDate >= post_from_date && invoiceDate <= post_to_date
							: false;

					// Return true if any of the conditions are satisfied
					return matchesDocNo || matchesCredit || matchesDebit || withinDateRange || withinPostDateRange;
				});
			}

			// Apply the search filter if there is a search text
			if (this.searchText) {
				const lowerCaseSearchTerm = this.searchText.toLowerCase();

				filterData = data.filter(item => {
					// Check if any field in the item matches the search term
					return Object.keys(item).some(key => {
						const value = item[key];
						if (value) {
							// Check if the value is a string or a number, and match case-insensitively
							return value.toString().toLowerCase().includes(lowerCaseSearchTerm);
						}
						return false;
					});
				});
			}


			let listData = filterPayload || this.searchText ? filterData : data;


			pageSize = +pageSize;
			// Total items and pages
			const totalCount = listData.length;
			const startIndex = (pageIndex - 1) * pageSize; // Adjust for pageIndex starting from 1
			const endIndex = startIndex + pageSize;
			const paginatedData = listData.slice(startIndex, endIndex);

			if (download) {
				this.exportService.exportAsExcelFile(paginatedData, "statements_list");
				resolve([]);
				return;
			}
			const mappedData = paginatedData.map((item) => ({
				customer_name: item.customer_name || "",
				customer_code: item.customer_code || "",
				balance: parseFloat(item.invoice_total_amount || "0"),
				credit: parseFloat(item.invoice_credit_amount || "0"),
				debit: parseFloat(item.invoice_debit_amount || "0"),
				post_date: item.document_date || "",
				doc_date: item.invoice_date || "",
				doc_text: item.invoice_text || "",
				doc_type: item.invoice_document_type || "",
				doc_no: item.document_number || "",
				invoice_no: item.invoice_allocation_no || "",
				invoice_url: item.invoice_url || null,
				invoice_total_amount: item.invoice_total_amount || "",
				invoice_payment_terms: item.invoice_payment_terms || "",
				invoice_assignment_no: item.invoice_assignment_no || "",
				invoice_clearance_date: item.invoice_clearance_date || "",
			}));

			if (!infiniteScroll) {
				this.statementList = mappedData; // Replace the list
			} else {
				// this.statementList = [...this.statementList, ...mappedData]; // Append for infinite scroll
				mappedData.forEach((data: any) => {
					this.statementList.push(data)
				})
			}

			this.count = totalCount; // Update total count
			this.enableNext = endIndex < totalCount; // Enable/disable next page
			resolve(this.statementList);
		});
	}

	loadData(event) {
		setTimeout(() => {
			if (this.enableNext) {
				this.currentPage += 1;
				this.processAndPaginateData(this.backupStatementList, this.itemsPerPage, this.currentPage, false, true)
					.then((statementList) => {
						event.target.complete();
					})
					.catch((error) => {
						event.target.complete();
					});
			}
		}, 500);
	}

	selectPageLength(event) {
		this.itemsPerPage = event.detail.value;
		this.currentPage = 1;
		this.processAndPaginateData(this.backupStatementList, this.itemsPerPage, this.currentPage, false, false)
			.then((statementList) => {
			})
			.catch((error) => {
			});
	}

	clearSearch() {
		this.searchText = null;
		delete this.filterObj["search"];
		this.currentPage = 1;
		this.processAndPaginateData(this.backupStatementList, this.itemsPerPage, this.currentPage, false, false)
			.then((statementList) => {
			})
			.catch((error) => {
			});
	}

	downloadExcel() {
		if (this.isDownloading) {
			return;
		}

		// Create a new Date object for the current date
		const currentDate = new Date();

		// Set the `start_date` to one month before the current date
		const startDate = new Date(currentDate);
		startDate.setMonth(currentDate.getMonth() - 10);

		const params = {
			page: this.currentPage,
			limit: +this.itemsPerPage,
			start_date: this.defaultFromDate || this.datePipe.transform(new Date(), "yyyy-MM-dd"),
			end_date: this.defaultToDate || this.datePipe.transform(new Date(), "yyyy-MM-dd"),
		};
		this.isDownloading = true;

		this.subscription.add(
			this.dataService.post(URLS.exportStatementList, params).subscribe(
				(data) => {
					if (data && data["code"] == 200) {
						if (!isPlatformServer(this.platformId)) {
							window.open(data["data"]);
						}
					} else {
					}
					this.isDownloading = false;
				},
				(error) => {
					this.isDownloading = false;
				}
			)
		);
	}

	downloadStatement(event, invoiceUrl) {
		event.preventDefault();
		event.stopPropagation();
		if (!isPlatformServer(this.platformId)) {
			window.open(invoiceUrl);
		}
	}

	downloadInvoice(event, data) {
		const params = {
			invoice_no: data.invoice_no,
		};
		this.isDownloading = true;

		this.subscription.add(
			this.dataService.post(URLS.invoiceDownload, params).subscribe(
				(data) => {
					if (data && data["code"] == 200) {
						if (!isPlatformServer(this.platformId)) {
							window.open(data["data"]);
						}
					} else {
					}
					this.isDownloading = false;
				},
				(error) => {
					this.isDownloading = false;
				}
			)
		);
	}

	goBack() {
		this.navCtrl.navigateRoot('/' + this.currentLang + '/my-account', { animated: false });
	}

	openFilterByModal() {
		let modal: any;
		let options: any = {
			id: "mobileStatementFilterByModal",
			componentProps: { statusList: this.statusList, fromOrdersList: true, filterObj: this.filterObj },
		};
		options['component'] = MobileStatementFilterComponent;
		modal = this.modalService.openModal(options)
		modal.then((data) => {
			if (data && data['data']) {
				this.filterObj = {
					...data['data']
				};


				this.currentPage = 1;
				this.processAndPaginateData(this.backupStatementList, this.itemsPerPage, this.currentPage, false, false, this.filterObj)
					.then((statementList) => {
					})
					.catch((error) => {
						console.error("Error processing data:", error);
					});
			}

		})
	}



	showBalance(e: Event) {
		this.popover.event = e;
		this.isOpen = true;
	}

	hideStatementPopOver() {
		this.isOpen = false
	}

}
