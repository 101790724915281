import { isPlatformServer } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { MyAccountMenuComponent } from '../components/my-account-menu/my-account-menu.component';
import { ApiService } from '../services/api-data.service';
import { CommonService } from '../services/common.service';
import { ModalService } from '../services/modal.service';
import { TranslateConfigService } from '../services/translate-config.service';
import { ToastService } from './../services/toast.service';

@Component({
	selector: 'app-tab1',
	templateUrl: 'tab1.page.html',
	styleUrls: ['tab1.page.scss']
})
export class Tab1Page {
	selectedLanguage: any;
	languageArray: any = [
		{ code: 'en', title: 'English', dir: 'ltr' },
		{ code: 'ar', title: 'Arabic', dir: 'rtl' }]
	showData: boolean = false;
	@Inject(PLATFORM_ID) private platformId: Object;
	private subscription: Subscription = new Subscription();
	public isLoading = false;
	public page: number = 1;
	public size: number = 10;
	public categoryData: any = [];
	public enableNext = false;

	public selectedSortBy: any = "A-Z";
	public filterCount: any = null;

	filterparams: any = {
		page: 1,
		size: 10
	};

	classId: any;
	groupData: any = [];
	fromHome: boolean;
	currentLang;
	constructor(
		private modalService: ModalService,
		private translateConfigService: TranslateConfigService,
		public translate: TranslateService,
		public commonService: CommonService,
		public dataService: ApiService,
		public toastService: ToastService,
		public navCtrl: NavController,
		public route: ActivatedRoute
	) {

		this.currentLang = this.commonService.currentLang;
		let selectedLanguage = this.translateConfigService.getDefaultLanguage();
		for (let i = 0; i < this.languageArray.length; i++) {
			if (selectedLanguage == this.languageArray[i].code) {
				this.selectedLanguage = this.languageArray[i];
			}
		}
	}

	ngOnInit() {
	}

	ionViewWillEnter() {
		this.route.queryParams
			.subscribe(params => {
				this.classId = params && params['class_id'] ? +params['class_id'] : null;
				this.fromHome = params && params['from_home'] ? params['from_home'] : null;
			});

		this.getAllGroups();
		this.page = 1;
		this.size = 10;
		this.categoryData = [];
		this.selectedSortBy = "A-Z";
		this.filterparams['sort_by'] = 'A-Z';
		this.filterparams['sort_type'] = 'ASC';
		if (this.classId && this.fromHome == true) {
			this.filterCount = [+this.classId];
		}
		else {
			this.filterCount = null;
			this.classId = null;
			this.filterparams = {
				page: 1,
				size: 10,
				sort_by: 'A-Z',
				sort_type: 'ASC'
			};
		};

		this.getProductCategories();
	}

	openModal() {
		this.modalService.openModal({ component: MyAccountMenuComponent, componentProps: { mobileView: true } })
	}

	getAllGroups() {
		let params = {
			dropdown: true,
		}
		this.subscription.add(
			this.dataService.get(URLS.allClassList, params).subscribe(data => {
				if (data['code'] == 200) {
					if (data['data']) {
						this.groupData = data['data'] ? data['data'] : [];
					}
				} else {
					this.isLoading = false;
				}
			}, error => {
				this.isLoading = false;
			})
		)

	}

	async languageChanged(event) {
		await this.translateConfigService.setLanguage(this.selectedLanguage.code);
		await this.translateConfigService.setDir(this.selectedLanguage.dir);
		this.translateConfigService.setLanguage(this.selectedLanguage.code);
	}


	getProductCategories(isScrollCalled?: boolean) {
		this.filterparams['page'] = this.page;
		this.filterparams['size'] = this.size;

		if (this.classId) { this.filterparams['class_id'] = [this.classId] }
		if (!isScrollCalled) {
			this.isLoading = true;
		}
		this.subscription.add(
			this.dataService.get(URLS.allCategoryFromGroup, this.filterparams).subscribe(data => {

				if (data['code'] == 200) {
					this.isLoading = false;
					let categoryData = data['data'];
					this.enableNext = data?.['data']?.['enable_next'] ? data['data']['enable_next'] : false;
					if (!isScrollCalled) {
						this.categoryData = [];
						categoryData.forEach((element, i) => {
							let childObj = {
								display_sub_class_name_en: element.display_sub_class_name_en ? element.display_sub_class_name_en : "",
								display_sub_class_name_ar: element.display_sub_class_name_ar ? element.display_sub_class_name_ar : "",
								display_class_name_en: element.display_class_name_en ? element.display_class_name_en : "",
								display_class_name_ar: element.display_class_name_ar ? element.display_class_name_ar : "",
								sub_class_id: element.sub_class_id ? element.sub_class_id : "",
								category_image_url: element.category_image_url ? element.category_image_url : "",
								total_product_count: element.total_product_count ? element.total_product_count : 0,
							}
							this.categoryData.push(childObj);
						});
					}
					if (isScrollCalled) {
						categoryData.forEach((element, i) => {
							let childObj = {
								display_sub_class_name_en: element.display_sub_class_name_en ? element.display_sub_class_name_en : "",
								display_sub_class_name_ar: element.display_sub_class_name_ar ? element.display_sub_class_name_ar : "",
								class_name_en: element.display_class_name_en ? element.display_class_name_en : "",
								display_class_name_ar: element.display_class_name_ar ? element.display_class_name_ar : "",
								sub_class_id: element.sub_class_id ? element.sub_class_id : "",
								category_image_url: element.category_image_url ? element.category_image_url : "",
								total_product_count: element.total_product_count ? element.total_product_count : 0,
							}
							this.categoryData.push(childObj);
						});

					} else {
						this.isLoading = false;
					}
				}
				else {
					this.toastService.displayToast(data['message'], null, 'danger');
					this.isLoading = false;
				}

			}, error => {
				this.isLoading = false;
			})
		)
	}

	loadMoreData(event) {
		if (this.enableNext) {
			setTimeout(() => {
				this.page += 1;
				this.getProductCategories(true);
				event.target.complete();
			}, 500);
		}
	}

	handleRefresh(event) {
		setTimeout(() => {
			this.page = 1;
			this.size = 10;
			this.categoryData = [];
			this.getProductCategories();
			// code maybe required
			// this.isLoading = false;
			event.target.complete();
		}, 1000);
	};

	selectSorting(event) {
		this.filterparams['sort_by'] = this.selectedSortBy;
		if (this.selectedSortBy == 'A-Z') {
			this.filterparams['sort_by'] = 'A-Z';
			this.filterparams['sort_type'] = 'ASC';
		}
		if (this.selectedSortBy == 'Z-A') {
			this.filterparams['sort_by'] = 'Z-A';
			this.filterparams['sort_type'] = 'DESC';
		}
		if (this.selectedSortBy == 'New_To_Old') {
			this.filterparams['sort_by'] = 'New_To_Old';
			this.filterparams['sort_type'] = 'DESC';
		}
		if (this.selectedSortBy == 'Old_To_New') {
			this.filterparams['sort_by'] = 'Old_To_New';
			this.filterparams['sort_type'] = 'ASC';
		}
		this.page = 1;
		this.size = 10;
		this.scrollToTop();
		this.getProductCategories();
	}

	scrollToTop() {
		if (!isPlatformServer(this.platformId)) {
			const element = document.getElementById('mainHomePagescrollId');
			const divClass = document.getElementsByClassName('page-scroll')[0];

			divClass.scrollIntoView({ behavior: 'smooth' });
		}
	}


	selectGroup(event) {
		this.classId = this.filterCount;
		this.page = 1;
		this.size = 10;
		this.scrollToTop();
		this.getProductCategories();
	}


	goBack() {
		this.navCtrl.navigateRoot('/' + this.currentLang + '/home', { animated: false });
	}
}


