import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { ModalService } from 'src/app/services/modal.service';
import { ApiService } from '../../services/api-data.service';
import { CartService } from '../../services/cart.service';
import { CommonService } from '../../services/common.service';
import { GetPlatformService } from '../../services/get-platform.service';
import { ToastService } from '../../services/toast.service';
import { TranslateConfigService } from '../../services/translate-config.service';

@Component({
	selector: 'app-category-page',
	templateUrl: './category-page.page.html',
	styleUrls: ['./category-page.page.scss'],
})
export class CategoryPage implements OnInit {
	isLoading: boolean = false;
	categoryData: any = [];
	classId: any;
	private subscription: Subscription = new Subscription();

	pagination: any = {
		page: 1,
		limit: 10
	}
	enableNext: boolean = false;

	filterparams: any = {
		page: 1,
		limit: 10
	};
	inerfaceOption = { cssClass: 'sortby-dropdown' };
	classData: any = [];
	constructor(private translateConfigService: TranslateConfigService,
		public getPlatformService: GetPlatformService,
		public modalService: ModalService,
		public router: Router,
		public cartService: CartService,
		private dataService: ApiService,
		public navCtrl: NavController,
		private translate: TranslateService,
		private alertController: AlertController,
		private toastService: ToastService,
		public zone: NgZone,
		public route: ActivatedRoute,
		public commonService: CommonService) {
		this.translateConfigService.getDefaultLanguage();

		this.classId = +this.route.snapshot.paramMap.get('id');
		this.filterparams['sort_by'] = 'A-Z';
		this.filterparams['sort_type'] = 'ASC';

	}



	ngOnInit() {
		this.getAllClass();
		this.getSubClass();
	}

	getAllClass() {
		let params = {
			dropdown: true,
		}
		this.subscription.add(
			this.dataService.get(URLS.allClassList, params).subscribe(data => {
				if (data['code'] == 200) {
					if (data['data']) {
						this.classData = data['data'] ? data['data'] : [];
					}
				} else {
					this.isLoading = false;
				}
			}, error => {
				this.isLoading = false;
			})
		)

	}

	getSubClass(fromPagination?) {

		if (!fromPagination) {
			this.isLoading = true;
		}
		this.filterparams['page'] = this.pagination.page;
		this.filterparams['size'] = this.pagination.limit;
		if (this.classId) {
			this.filterparams['class_id'] = [this.classId];
		}

		this.subscription.add(
			this.dataService.get(URLS.allCategoryFromGroup, this.filterparams).subscribe(data => {
				if (data['code'] == 200) {
					this.isLoading = false;
					if (!fromPagination) {
						this.categoryData = [];
					}
					if (data.data) {
						this.enableNext = data.data.enable_next ? data.data.enable_next : false;
						let list = data.data ? data.data : [];
						list.forEach((element, index,) => {
							let obj = {
								product_img: element.sub_class_image_url ? element.sub_class_image_url : "",
								product_description: element.display_sub_class_name_en ? element.display_sub_class_name_en : null,
								product_descrption_ar: element.display_sub_class_name_ar ? element.display_sub_class_name_ar : null,
								product_title: element.display_sub_class_name_en ? element.display_sub_class_name_en : null,
								product_title_ar: element.display_sub_class_name_ar ? element.display_sub_class_name_ar : null,
								product_name: element.display_sub_class_name_en ? element.display_sub_class_name_en : null,
								product_name_ar: element.display_sub_class_name_ar ? element.display_sub_class_name_ar : null,
								sub_class_id: element.sub_class_id ? element.sub_class_id : 0,
								category_image_url: element.sub_class_image_url ? element.sub_class_image_url : null
							}
							this.categoryData.push(obj);
						});
					}

				} else {
					this.isLoading = false;
				}
			}, error => {
				this.isLoading = false;
			})
		)
	}

	async goToProductListing(data) {
		let sub_class_id = data.sub_class_id;
		let subClassNameURL = this.commonService.trimUrl(data.product_name ? data.product_name : data.product_name_en);
		let currentLang = this.commonService.currentLang;
		await this.navCtrl.navigateForward('/' + currentLang + '/product-listing/' + subClassNameURL + "/" + sub_class_id, {
			animated: false, "state": {
				"breadcrumb": this.commonService.domDirectionLTR ? data.product_name : data.product_name_ar,
				"url": '/product-listing/' + subClassNameURL + "/" + sub_class_id,
				id: data.sub_class_id,
				"ar_label": data.product_name_ar,
				"banner_img": data.category_image_url
			}
		});
	}

	loadMoreData() {
		this.pagination.page = this.pagination.page + 1;
		this.getSubClass(true)
	}

	selectSorting(event) {
		this.filterparams['sort_by'] = event['detail']['value'];
		if (event['detail']['value'] == 'A-Z') {
			this.filterparams['sort_by'] = 'A-Z';
			this.filterparams['sort_type'] = 'ASC';
		}
		if (event['detail']['value'] == 'Z-A') {
			this.filterparams['sort_by'] = 'Z-A';
			this.filterparams['sort_type'] = 'DESC';
		}
		if (event['detail']['value'] == 'New_To_Old') {
			this.filterparams['sort_by'] = 'New_To_Old';
			this.filterparams['sort_type'] = 'DESC';
		}
		if (event['detail']['value'] == 'Old_To_New') {
			this.filterparams['sort_by'] = 'Old_To_New';
			this.filterparams['sort_type'] = 'ASC';
		}
		this.pagination.page = 1;
		this.pagination.limit = 10;
		this.getSubClass();
	}

	selectGroup(event) {
		this.classId = event.detail.value;
		this.pagination.page = 1;
		this.pagination.limit = 10;
		this.getSubClass();
	}
	goBack() {
		this.navCtrl.navigateRoot('/' + this.commonService.currentLang + '/home', { animated: false });
	}
}
