<ion-header *ngIf="!getPlatformService.isDesktop" class="address-header" mode="md">
	<ion-toolbar mode="md">
		<img src="./assets/icon/leftArrow.svg" alt="back-arrow" slot="start" (click)="goBack()"
			[ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
		<ion-title mode="ios">
			<div class="address-header">
				{{'voucherComponent.addVoucher' | translate }}
			</div>
		</ion-title>
	</ion-toolbar>
</ion-header>

<ion-content id="addEditAddressContent">
	<div [ngClass]="getPlatformService.isDesktop ? 'p-24-30':'ph-24'">
		<div class="d-flex align-center width-100">
			<ion-row class="align-center mv-5 common-box justify-end">
				<p class="m-0 color-black text-bold"> {{"voucherComponent.label2"|translate}}
					<span class="text-bold color-blue-60">
						{{totalValidVoucher}}</span>
				</p>
			</ion-row>

			<nz-divider nzType="vertical"></nz-divider>

			<ion-row class="align-center mv-5 common-box justify-end">
				<p class="m-0 color-black text-bold"> {{"voucherComponent.label"|translate}}
					<span class="text-bold color-blue-60">{{commonService.currencySymbol}}
						{{addedVoucherTotal|number}}</span>
				</p>
			</ion-row>
		</div>

		<ion-row class="bg-gray-90 mb-20 p-20">
			<ion-item class="from-date-class" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">
				<ion-input [(ngModel)]="addVoucherNumebr" placeholder="{{'voucherComponent.field1'| translate}}"
					class="ion-text-start" (keyup.enter)="validateVoucher()"
					(keyup.tab)="validateVoucher()"></ion-input>
			</ion-item>
		</ion-row>

		<ng-container *ngFor="let data of tempVouchers">
			<ion-row class="bg-gray-90 mb-20 p-16">
				<ion-item class="from-date-class" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">
					<ion-input [(ngModel)]="data.voucherNumebr" placeholder="{{'voucherComponent.field1'| translate}}"
						class="ion-text-start"></ion-input>

				</ion-item>

				<ion-item class="from-date-class mt-10" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">
					<ion-input [(ngModel)]="data.voucherAmount"
						placeholder="{{'voucherComponent.voucherAmount'| translate}}"
						class="ion-text-start"></ion-input>

				</ion-item>

				<ion-item class="from-date-class mt-10" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">
					<ion-input [(ngModel)]="data.expiryDate" placeholder="{{'voucherComponent.expiryDate'| translate}}"
						class="ion-text-start"></ion-input>

				</ion-item>

				<ion-item class="from-date-class item-padding-0 mt-10"
					[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">
					<div class="align-center mv-5 common-box box2">
						<p class="m-0 color-black">
							{{data.msg}}
						</p>
					</div>
				</ion-item>
			</ion-row>
		</ng-container>


	</div>
	<ng-template let-formObject #validationMessages>
		<ng-container *ngFor="let validation of validation_messages[formObject.controlName];">
			<ion-text color="danger" class="error-msg"
				*ngIf="formObject.formName.controls[formObject.controlName].hasError(validation.type) && formObject.formName.controls[formObject.controlName].touched">
				{{validation.message |translate }}
			</ion-text>
		</ng-container>
	</ng-template>
</ion-content>


<ion-footer *ngIf="!getPlatformService.isDesktop">
	<div class="ph-20 pb-30">
		<app-common-button color="#FFF" background="var(--ion-color-green-80)" type="solid" class="width-100"
			(click)="submitAllVoucher()" title="{{ 'voucherComponent.submitAllVoucher' | translate }}" [isIcon]="false"
			[isHideInMobile]="false" [isFullWidth]="true" [specificFontSize]="'1rem'">
		</app-common-button>
	</div>
</ion-footer>