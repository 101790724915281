import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { ToastService } from 'src/app/services/toast.service';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { ModalService } from '../../services/modal.service';

@Component({
	selector: 'app-add-voucher',
	templateUrl: './add-voucher.component.html',
	styleUrls: ['./add-voucher.component.scss'],
})
export class AddVoucherComponent implements OnInit {

	public isLoading = false;
	private subscription: Subscription = new Subscription();

	tempVouchers: any = [];
	addVoucherNumebr: any;
	voucherTotal: any;

	addedVoucherTotal: any = 0;
	totalValidVoucher: any = 0;

	constructor(private modalService: ModalService,
		public commonService: CommonService,
		public getPlatformService: GetPlatformService,
		private dataService: ApiService,
		private translate: TranslateService,
		private alertController: AlertController,
		public navCtrl: NavController,
		private toastService: ToastService,
		private router: Router) {

	}

	ngOnInit() {

	}

	ionViewWillEnter() {
	}
	goBack() {
		this.navCtrl.back({ animated: false });
	}


	closeModal() {
		this.modalService.dismissModal();
	}



	validateVoucher() {
		if (!this.addVoucherNumebr) {
			let duplicateMsg = this.commonService.domDirectionLTR ? 'Please Enter voucher number' : 'يرجى إدخال رقم القسيمة';
			this.toastService.displayToast(duplicateMsg, null, "danger");
			return;
		}
		if (this.tempVouchers.some(voucher => voucher.voucherNumebr === this.addVoucherNumebr)) {
			let duplicateMsg = this.commonService.domDirectionLTR ? 'Duplicate voucher number' : 'رقم قسيمة مكرر';
			this.toastService.displayToast(duplicateMsg, null, "danger");
			return;
		}

		let params = {
			"voucher_code": this.addVoucherNumebr
		}

		this.totalValidVoucher = 0;
		this.addedVoucherTotal = 0;
		this.subscription.add(
			this.dataService.post(URLS.voucherValidation, params).subscribe(
				(data) => {
					if (data && data["code"] == 200) {
						let result = data['data'];
						this.tempVouchers.push({ ...result, voucherNumebr: result.voucher_code, voucherAmount: this.commonService.currencySymbol + " " + result.voucher_amount, expiryDate: moment(result.validity_end_date).format('MMM DD, YYYY'), msg: data.message, msg_type: 'success' });
						this.addVoucherNumebr = null;

					} else {
						// this.toastService.displayToast(data['message'], null, "danger");
						this.tempVouchers.push({ voucherNumebr: this.addVoucherNumebr, voucherAmount: 'NA', expiryDate: 'NA', msg: data.message, msg_type: 'error' });
						this.addVoucherNumebr = null;

					}

					this.tempVouchers.forEach(element => {
						if (element.msg_type == 'success') {
							this.addedVoucherTotal = this.addedVoucherTotal + parseFloat(element.voucher_amount);
							this.totalValidVoucher += 1;
						}
					});
					this.isLoading = false;
				},
				(error) => {
					this.toastService.displayToast(error['message'], null, "danger");
					this.isLoading = false;
				}
			)
		);


	}


	submitAllVoucher() {

		if (this.tempVouchers && this.tempVouchers.length < 1) {
			let duplicateMsg = this.commonService.domDirectionLTR ? 'No valid vouchers found' : 'لم يتم العثور على قسائم صالحة';
			this.toastService.displayToast(duplicateMsg, null, "danger");
			return;
		}

		const successVouchers = this.tempVouchers.filter(voucher => voucher.msg_type === "success");
		let params = {
			"vouchers": successVouchers
		}
		this.subscription.add(
			this.dataService.post(URLS.bulkSubmitVoucher, params).subscribe(
				(data) => {
					if (data && data["code"] == 200) {
						this.toastService.displayToast(data['message'], null, "success");
						this.tempVouchers = [];
						this.modalService.dismissModalwithData(true);
					} else {
						this.toastService.displayToast(data['message'], null, "danger");
					}
					this.isLoading = false;
				},
				(error) => {
					this.toastService.displayToast(error['message'], null, "danger");
					this.isLoading = false;
				}
			)
		);
	}
}
