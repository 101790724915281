import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { GetLatLongService } from 'src/app/services/get-lat-long';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { ModalService } from 'src/app/services/modal.service';
import { AddEditAddressPage } from '../../pages/add-edit-address/add-edit-address.page';
import { TranslateConfigService } from '../../services/translate-config.service';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { CartService } from './../../services/cart.service';
import { CommonService } from './../../services/common.service';
import { ToastService } from './../../services/toast.service';

@Component({
	selector: 'app-checkout-step1-page',
	templateUrl: './checkout-step1.html',
	styleUrls: ['./checkout-step1.scss'],
})
export class CheckOutStep1Page implements OnInit, OnDestroy {

	public shipping_address: any = [];

	public shipping_methods: any = [
		{
			id: 1,
			expiry_days: '1-2 days',
			currency: 'SR',
			amount: 100
		},
		{
			id: 2,
			expiry_days: '3-4 days',
			currency: 'SR',
			amount: 40
		}

	]


	public isShowDiscount: boolean = false;
	shippingOption: any;
	public isLoading = false;
	private subscription: Subscription = new Subscription();
	defalutShippingAddress: any;
	defalutBillingAddress: any;
	billing_address: any = [];

	cartData: any = [];
	cartProductData: any = [];
	appliedCouponCode: any;
	tempShippingSelectedAddress: any;
	tempBillingSelectedAddress: any;

	closed$ = new Subject<any>();
	addFromCheckout: boolean = false;
	currentLang;
	appliedCouponCodeValue: any;
	constructor(private translateConfigService: TranslateConfigService,
		public getPlatformService: GetPlatformService,
		public modalService: ModalService,
		public commonService: CommonService,
		public navCtrl: NavController,
		private dataService: ApiService,
		private translate: TranslateService,
		private alertController: AlertController,
		private toastService: ToastService,
		public cartService: CartService,
		public getLatLongService: GetLatLongService,
		public router: Router) {
		this.currentLang = this.commonService.currentLang;
		this.translateConfigService.getDefaultLanguage();
	}

	ngOnInit() {
		this.isLoading = true;
		this.commonService.routerEventVariable = this.router.events.pipe(
			filter(e => e instanceof NavigationEnd),
			takeUntil(this.closed$)
		).subscribe(event => {
			const token = this.commonService.localStorageGet('accessToken');
			if (token && this.router.url.includes("checkout-step-1")) {
				this.getShippingAddresses();
				this.getBillingAddresses();
				this.cartService.getCart().then((data) => {
					this.cartData = data;
					this.cartProductData = [];
					this.cartData.result.forEach(element => {
						element.products.forEach(element2 => {
							this.cartProductData.push(element2);
						});
					});
					this.routeHistory();
					this.isLoading = false;
				}).catch(error => {
					this.routeHistory();
					this.isLoading = false;
				});
			}

		})
	}

	routeHistory() {
		if (!this.cartService.cartList.length && this.getPlatformService.isDesktop && this.router.url.includes("checkout-step-1")) {
			let url = this.commonService.localStorageGet('url');
			if (url) {
				this.commonService.localStorageRemove('url');
				this.navCtrl.navigateRoot(`/${this.currentLang}${url}`, { animated: false, replaceUrl: true })
			}
		}
	}

	ionViewWillEnter() {
		this.translateConfigService.getDefaultLanguage();
		this.routeHistory();

		const token = this.commonService.localStorageGet('accessToken');
		if (token && this.router.url.includes("checkout-step-1")) {
			this.getShippingAddresses();
			this.getBillingAddresses();
			this.cartService.getCart().then((data) => {
				this.cartData = data;
				this.cartProductData = [];
				this.cartData.result.forEach(element => {
					element.products.forEach(element2 => {
						this.cartProductData.push(element2);
					});
				});
				this.routeHistory();
				this.isLoading = false;
			}).catch(error => {
				this.routeHistory();
				this.isLoading = false;
			});
		}

	}

	showDiscount() {
		this.isShowDiscount = !this.isShowDiscount;
	}

	getShippingAddresses() {
		this.shipping_address = [];

		this.subscription.add(
			this.dataService.get(URLS.getAddressList).subscribe(data => {
				if (data['data'] && data['code'] == 200) {
					this.shipping_address = data['data'];
					this.shipping_address.forEach(element => {
						if (element.is_default_shipping_address == true) {
							element.select = true;
							this.defalutShippingAddress = element;
							this.cartService.shipping_address = element;
							this.commonService.localStorageSet('cart_shipping_address', element.address_id);

							if (this.addFromCheckout == true) {
								this.isLoading = true;
								this.getLatLongService.getLatLongLocation(element['city_en'], +element.address_id, true).then(data => {
									this.cartService.getCart().then((data) => {
										this.cartData = data;
										this.cartProductData = [];
										this.cartData.result.forEach(element => {
											element.products.forEach(element2 => {
												this.cartProductData.push(element2);
											});

										});
										this.addFromCheckout = false;
										this.isLoading = false;
									}).catch(error => { });
								})
							}
						}
					});

					if (this.shipping_address.length == 1 && this.shipping_address[0].is_default_shipping_address != true) {
						const params = {
							"address_id": +this.shipping_address[0].address_id,
							"is_default_shipping_address": true,
						}

						this.subscription.add(
							this.dataService.put(URLS.updateAddress, params).subscribe(data => {
								if (data['data'] && data['code'] == 200) {
									this.tempShippingSelectedAddress = null;
									this.getShippingAddresses();
								} else {
									this.toastService.displayToast(data['message'], null, 'danger');
								}
							}, error => {
								this.toastService.displayToast(error['message'], null, 'danger');
							}))
					}

					else if (!this.defalutShippingAddress) {
						const params = {
							"address_id": +this.shipping_address[0].address_id,
							"is_default_billing_address": true
						}
						this.subscription.add(
							this.dataService.put(URLS.updateAddress, params).subscribe(data => {
								if (data['data'] && data['code'] == 200) {
									this.tempBillingSelectedAddress = null;
									this.getShippingAddresses();
								} else {
									this.toastService.displayToast(data['message'], null, 'danger');
								}
							}, error => {
								this.toastService.displayToast(error['message'], null, 'danger');
							}))
					}


				} else {
				}
			}, error => {
			})
		)
	}

	getBillingAddresses() {
		this.billing_address = [];
		this.subscription.add(
			this.dataService.get(URLS.getAddressList).subscribe(data => {
				if (data['data'] && data['code'] == 200) {
					this.billing_address = data['data'];
					this.billing_address.forEach(element => {
						if (element.is_default_billing_address == true) {
							element.select = true;
							this.defalutBillingAddress = element;
							this.cartService.billing_address = element;
							this.commonService.localStorageSet('cart_billing_address', element.address_id);

							if (this.addFromCheckout == true) {
								this.isLoading = true;
								this.getLatLongService.getLatLongLocation(element['city_en'], +element.address_id, true).then(data => {
									this.cartService.getCart().then((data) => {
										this.cartData = data;
										this.cartProductData = [];
										this.cartData.result.forEach(element => {
											element.products.forEach(element2 => {
												this.cartProductData.push(element2);
											});

										});
										this.addFromCheckout = false;
										this.isLoading = false;
									}).catch(error => { });
								})
							}
						}
					});

					if (this.billing_address.length == 1 && this.billing_address[0].is_default_billing_address != true) {
						const params = {
							"address_id": +this.billing_address[0].address_id,
							"is_default_shipping_address": true,
						}

						this.subscription.add(
							this.dataService.put(URLS.updateAddress, params).subscribe(data => {
								if (data['data'] && data['code'] == 200) {
									this.tempBillingSelectedAddress = null;
									this.getBillingAddresses();
								} else {
									this.toastService.displayToast(data['message'], null, 'danger');
								}
							}, error => {
								this.toastService.displayToast(error['message'], null, 'danger');
							}))
					}

					else if (!this.defalutBillingAddress) {
						const params = {
							"address_id": +this.billing_address[0].address_id,
							"is_default_billing_address": true
						}

						this.subscription.add(
							this.dataService.put(URLS.updateAddress, params).subscribe(data => {
								if (data['data'] && data['code'] == 200) {
									this.tempBillingSelectedAddress = null;
									this.getBillingAddresses();
								} else {
									this.toastService.displayToast(data['message'], null, 'danger');
								}
							}, error => {
								this.toastService.displayToast(error['message'], null, 'danger');
							}))
					}


				} else {
				}
			}, error => {
			})
		)
	}


	chooseAddress(element, index, is_shipping) {
		if (is_shipping == true) {
			this.shipping_address.forEach(data => {
				if (data.address_id == element.address_id) {
					data.select = true;
					this.tempShippingSelectedAddress = data.address_id;
				}
				else {
					data.select = false;
				}
			});
			this.commonService.localStorageSet('cart_shipping_address', this.tempShippingSelectedAddress);

			this.isLoading = true;

			this.getLatLongService.getLatLongLocation(element['city_en'], +element.address_id, true).then(data => {
				this.cartService.getCart().then((data) => {
					this.cartData = data;
					this.cartProductData = [];
					this.cartData.result.forEach(element => {
						element.products.forEach(element2 => {
							this.cartProductData.push(element2);
						});

					});
					this.isLoading = false;
				}).catch(error => { });
			})
		}
		else {
			this.billing_address.forEach(data => {
				if (data.address_id == element.address_id) {
					data.select = true;
					this.tempBillingSelectedAddress = data.address_id;
				}
				else {
					data.select = false;
				}
			});
			console.log(this.tempBillingSelectedAddress);
			this.commonService.localStorageSet('cart_billing_address', this.tempBillingSelectedAddress);
		}



	}

	chooseMethods(i) {
		this.shipping_methods.forEach((element, index) => {
			if (index == i) {
				element.select = true;
			}
			else element.select = false;
		});
	}

	async addNewAddressDialog(isMobile?) {
		if (this.shipping_address.length > 9) {
			this.toastService.displayToast("Maximum 10 addresses can be added!", null, 'danger');
			return;
		}
		let componentData;
		if (isMobile) {
			componentData = { mobileView: true }
		}

		let options: any = {
			componentProps: componentData,
			id: "addressModal"
		};
		options['component'] = AddEditAddressPage;

		let modal = this.modalService.openModal(options);
		modal.then((data) => {
			if (data['data']['data']) {
				this.addFromCheckout = true;
				this.getShippingAddresses();
				this.getBillingAddresses();
			}
		})

	}

	buttonDiabledMethod() {
		let isButtonDisable = false;
		if (!this.cartData.grand_total) {
			isButtonDisable = true;
		}

		if (!this.defalutShippingAddress) {
			isButtonDisable = true;
		}

		if (!this.defalutBillingAddress) {
			isButtonDisable = true;
		}

		return isButtonDisable;
	}

	gotoPayment() {

		if (!this.defalutShippingAddress) {
			this.toastService.displayToast("Please select shipping address", null, 'danger');
			return;
		}
		if (!this.defalutBillingAddress) {
			this.toastService.displayToast("Please select billing address", null, 'danger');
			return;
		}

		if (this.tempBillingSelectedAddress && this.tempShippingSelectedAddress) {
			this.router.navigate(['' + this.commonService.currentLang + '/checkout-step-2'])
		}
		else {
			this.billing_address.forEach(element => {
				if (element.is_default_billing_address == true) {
					element.select = true;
					this.defalutBillingAddress = element;
					this.cartService.billing_address = element;
				}
			});

			this.shipping_address.forEach(element => {
				if (element.is_default_shipping_address == true) {
					element.select = true;
					this.defalutShippingAddress = element;
					this.cartService.shipping_address = element;
				}
			});

			this.router.navigate(['' + this.commonService.currentLang + '/checkout-step-2'])
		}

	}


	goToProductDetail(data) {
		let prod_id = data.main_product_ref_id;
		let prodnameUrl = this.commonService.trimUrl(data.product_name ? data.product_name : data.product_name_en);
		let subClassNameURL = this.commonService.trimUrl(this.commonService.domDirectionLTR ? data.display_sub_class_name_en : data.display_sub_class_name_en);

		this.navCtrl.navigateForward('/' + this.currentLang + "/" + subClassNameURL + "/" + prodnameUrl + "/" + prod_id,
			{
				animated: false,
				"state": {
					"breadcrumb": data.product_name_en,
					"url": "/" + subClassNameURL + "/" + prodnameUrl + "/" + prod_id,
				}
			});
	}

	calculateSUOM(ratio, quantity) {
		if (ratio && quantity) {
			return ratio * (+quantity);
		} else {
			return ratio;
		}
	}

	goBack() {
		this.cartService.getCart().then((data) => {
			let cartData: any = data;
			let is_not_sample_product = true;
			let is_sample_item_and_shipment_free = false;
			for (let i = 0; i < cartData.result.length; i++) {
				const element = cartData.result[i];
				if (element.products && element.products.length) {
					is_not_sample_product = element.products.some(obj => !obj.is_sample_product);
					is_sample_item_and_shipment_free = element.products.some(obj => obj.is_sample_item_and_shipment_free);
					if (is_not_sample_product || (!is_not_sample_product && !is_sample_item_and_shipment_free)) {
						break;
					}

				}
			}

			if (is_not_sample_product || (!is_not_sample_product && !is_sample_item_and_shipment_free)) {
				this.navCtrl.navigateBack(['/' + this.commonService.currentLang + '/cart']);
			} else if (!is_not_sample_product && is_sample_item_and_shipment_free) {
				this.modalService.dismissModal();
				this.navCtrl.navigateBack(['' + this.commonService.currentLang + '/home'], { animated: false });
			}
		});
	}

	ngOnDestroy() {
		this.commonService.localStorageRemove('fromCartHeader');
		this.commonService.routerEventVariable.unsubscribe();
	}

	ionViewWillLeave() {
		this.commonService.localStorageRemove('fromCartHeader');
		this.commonService.routerEventVariable.unsubscribe();
	}
}
