import { isPlatformServer } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import Swiper from 'swiper';
import { register } from 'swiper/element';
import { CommonService } from '../../services/common.service';


register();

@Component({
	selector: 'app-product-slider',
	templateUrl: './product-slider.component.html',
	styleUrls: ['./product-slider.component.scss'],
})
export class ProductSliderComponent implements OnInit, AfterViewInit {

	// @ViewChild("productsSlides", { static: false }) slider: IonSlides;
	@ViewChild('productsSlides', { static: false }) prodSlider!: ElementRef;

	@Input() productTitle: string = "";
	@Input() public sliderData = [];
	@Input() public relatedProducts: boolean = false;
	@Input() public categories: boolean = false;
	@Input() public viewAll: boolean = false;

	productSliderSwiper: Swiper;

	public lastSlide: boolean = false;
	public slideIndex: any;
	public firstSlide: boolean = true;
	public sliderEnd: any;
	public prodSlideEl: any;
	currentLang;
	public slideOpts = {
		initialSlide: 0,
		speed: 200,
		spaceBetween: 16,
		breakpoints: {
			320: {
				slidesPerView: 1.2,
			},
			360: {
				slidesPerView: 1.2,
			},
			375: {
				slidesPerView: 1.2,
			},
			385: {
				slidesPerView: 1.2,
			},
			500: {
				slidesPerView: 1.2,
			},
			488: {
				slidesPerView: 1.2,
			},
			700: {
				slidesPerView: 1.2,
			},
			768: {
				slidesPerView: 1.2,
			},
			800: {
				slidesPerView: 1.2,
			},
			1000: {
				slidesPerView: 3.3,
			},
			1100: {
				slidesPerView: 3.5,
			},
			1280: {
				slidesPerView: 3.8,
			},
			1400: {
				slidesPerView: 3.8,
			},
			1480: {
				slidesPerView: 3.8,
			},
			1600: {
				slidesPerView: 3.8,
			},
			1800: {
				slidesPerView: 4,
			},
			1920: {
				slidesPerView: 4,
			},
		},
	};

	public relatedSlideOptions = {
		initialSlide: 0,
		speed: 200,
		spaceBetween: 16,
		pagination: {
			el: '.swiper-pagination',
			clickable: true
		},
		breakpoints: {
			320: {
				slidesPerView: 2,
			},
			488: {
				slidesPerView: 2,
			},
			768: {
				slidesPerView: 1.2,
			},
			850: {
				slidesPerView: 1.2,
			},
			1100: {
				slidesPerView: 4,
			},
			1280: {
				slidesPerView: 5,
			},
			1600: {
				slidesPerView: 5,
			},
			1800: {
				slidesPerView: 5,
			},
			1920: {
				slidesPerView: 5,
			},
		},
	}

	public categoriesOpts = {
		initialSlide: 0,
		speed: 200,
		spaceBetween: 0,
		pagination: {
			el: '.swiper-pagination',
			clickable: true
		},
		breakpoints: {
			320: {
				slidesPerView: 1.2,
			},
			360: {
				slidesPerView: 1.2,
			},
			488: {
				slidesPerView: 3.5,
			},
			768: {
				slidesPerView: 3.5,
			},
		},
	};


	relatedBreakpoints = [
		{ width: 320, slides: 2.1 },
		{ width: 488, slides: 2.1 },
		{ width: 768, slides: 2.1 },
		{ width: 850, slides: 2.5 },
		{ width: 1100, slides: 4 },
		{ width: 1280, slides: 3.8 }, // Fixed the typo here (was 53.8)
		{ width: 1400, slides: 4 },
		{ width: 1480, slides: 4 },
		{ width: 1600, slides: 4 },
		{ width: 1800, slides: 4 },
		{ width: 1920, slides: 4 },
	];
	private categoryBreakpoints = [
		{ width: 320, slides: 1.2 },
		{ width: 360, slides: 2.7 },
		{ width: 488, slides: 3.5 },
		{ width: 768, slides: 3.5 },
	];

	slideBreakpoints = [
		{ width: 320, slides: 2.1 },
		{ width: 488, slides: 2.1 },
		{ width: 768, slides: 2.1 },
		{ width: 850, slides: 2.5 },
		{ width: 1100, slides: 4 },
		{ width: 1280, slides: 3.8 }, // Fixed the typo here (was 53.8)
		{ width: 1400, slides: 4 },
		{ width: 1480, slides: 4 },
		{ width: 1600, slides: 4 },
		{ width: 1800, slides: 4 },
		{ width: 1920, slides: 4 },
	];

	constructor(
		public router: Router,
		public commonService: CommonService,
		private navCtrl: NavController,
		private translate: TranslateService,
		@Inject(PLATFORM_ID) private platformId: Object,
	) {
		this.currentLang = this.commonService.currentLang;
	}

	ngOnInit() {
	}

	ngAfterViewInit() {
		this.prodSlideEl = this.prodSlider.nativeElement;

		if (!this.prodSlideEl.swiper) {
			Object.assign(this.prodSlideEl, this.slideOpts);  // Apply slide options
			// this.prodSlideEl.initialize();  // Initialize Swiper
			this.prodSlideEl.swiper = new Swiper(this.prodSlideEl, this.slideOpts);

		}
	}

	async goToProductDetail(data) {
		if (data.sub_class_id) {
			let sub_class_id = +data.sub_class_id
			this.commonService.localStorageSet("sub_class_id", sub_class_id)
		}
		if (data.product_id) {
			let prod_id = data.product_id;

			// this.navCtrl.navigateForward("/"+this.currentLang+"/"+subClassNameURL+"/"+ prodnameUrl+"/"+ prod_id,
			let prodnameUrl = this.commonService.trimUrl(data.product_name);
			let subClassNameURL = this.commonService.trimUrl(data.display_sub_class_name_en);
			let breadcrumbProdNameUrl = this.commonService.trimUrl(this.commonService.domDirectionLTR ? data.product_name : data.product_name_ar);
			let breadcrumbsubClassNameURL = this.commonService.trimUrl(this.commonService.domDirectionLTR ? data.display_sub_class_name_en : data.display_sub_class_name_en);
			await this.navCtrl.navigateForward("/" + this.currentLang + "/" + subClassNameURL + "/" + prodnameUrl + "/" + prod_id,
				{
					animated: false,
					"state": {
						"breadcrumb": this.commonService.domDirectionLTR ? data.product_description : data.product_description_ar,
						"url": "/" + this.currentLang + "/" + subClassNameURL + "/" + prodnameUrl + "/" + prod_id,
					},
				});
		}
	}

	viewAllProducts(data) {

		switch (data) {

			case "FEATURED":
				this.navCtrl.navigateForward("/" + this.currentLang + '/product-listing/' + 'featured', { animated: false, "state": { "breadcrumb": this.translate.instant('featuredProduct'), "url": '/product-listing/' + 'featured', product_type: data, banner_img: '/assets/images/slider.svg' } })
				break;

			case "RECOMMENDED":
				this.navCtrl.navigateForward("/" + this.currentLang + '/product-listing/' + 'recommended', { animated: false, "state": { "breadcrumb": this.translate.instant('recommendProduct'), "url": '/product-listing/' + 'recommended', product_type: data, banner_img: '/assets/images/slider.svg' } })
				break;

			case "BESTSELLING":
				this.navCtrl.navigateForward("/" + this.currentLang + '/product-listing/' + 'bestselling', { animated: false, "state": { "breadcrumb": this.translate.instant('bestSellingProduct'), "url": '/product-listing/' + 'bestselling', product_type: data, banner_img: '/assets/images/slider.svg' } })
				break;

			default:
				this.navCtrl.navigateForward("/" + this.currentLang + '/product-listing/', { animated: false, "state": { banner_img: '/assets/images/slider.svg' } })

		}
	}

	goToProductListing(data?) {
		if (data && data.sub_class_id) {
			this.navCtrl.navigateForward("/" + this.currentLang + '/product-listing/' + data.category_name, {
				animated: false, "state": {
					"breadcrumb": data.category_name,
					"url": "/" + this.currentLang + '/product-listing/' + data.category_name,
					id: data.sub_class_id,
					"ar_label": data.category_name
				}
			});
		} else {
			this.navCtrl.navigateBack("/" + this.currentLang + '/product-listing');
		}
	}

	goToCategoryListing(data?) {
		if (data && data.sub_class_id) {
			this.navCtrl.navigateForward("/" + this.currentLang + '/tabs/categories', {
				animated: false, queryParams: {
					"class_id": data.sub_class_id,
					"from_home": true
				},
			});

		}
		else {
			this.navCtrl.navigateForward("/" + this.currentLang + '/tabs/categories', { animated: false });

		}

	}

	slideToNext() {
		this.prodSlideEl.swiper.slideNext();
		this.slideChanged();
	}

	slideToPrev() {
		this.prodSlideEl.swiper.slidePrev();
		this.slideChanged();
	}

	async slideChanged() {
		this.slideIndex = await this.prodSlideEl.swiper.activeIndex;
		this.sliderEnd = await this.prodSlideEl.swiper.isEnd;
		if (this.slideIndex === 0) {
			this.firstSlide = true;
			this.lastSlide = false;
		} else if (this.slideIndex > 0) {
			this.firstSlide = false;
			this.lastSlide = false;
		}
	}

	getSlidesPerView(): number {
		if (!isPlatformServer(this.platformId)) {
			const width = window.innerWidth;
			for (let i = this.slideBreakpoints.length - 1; i >= 0; i--) {
				if (width >= this.slideBreakpoints[i].width) {
					return this.slideBreakpoints[i].slides;
				}
			}
			return this.slideBreakpoints[0].slides; // Default to the smallest number of slides
		}
	}


	getRelatedSlidesPerView(): number {
		if (!isPlatformServer(this.platformId)) {
			const width = window.innerWidth;
			for (let i = this.relatedBreakpoints.length - 1; i >= 0; i--) {
				if (width >= this.relatedBreakpoints[i].width) {
					return this.relatedBreakpoints[i].slides;
				}
			}
			return this.relatedBreakpoints[0].slides; // Default to the smallest number of slides
		}
	}


	getCateSlidesPerView(): number {
		if (!isPlatformServer(this.platformId)) {
			const width = window.innerWidth;
			for (let i = this.categoryBreakpoints.length - 1; i >= 0; i--) {
				if (width >= this.categoryBreakpoints[i].width) {
					return this.categoryBreakpoints[i].slides;
				}
			}
			return this.categoryBreakpoints[0].slides; // Default to the smallest number of slides
		}
	}
}
