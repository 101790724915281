import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from 'src/app/services/common.service';
import { CustomValidator } from '../../../validators/customvalidators';
import { TwoFactorAuthenticationComponent } from '../../components/two-factor-authentication/two-factor-authentication.component';
import { ModalService } from '../../services/modal.service';
import { GetPlatformService } from './../../services/get-platform.service';
@Component({
	selector: 'app-add-edit-payment-card',
	templateUrl: './add-edit-payment-card.page.html',
	styleUrls: ['./add-edit-payment-card.page.scss'],
})
export class AddEditPaymentCardPage implements OnInit {



	isCheckedList = [];

	public validation_messages = {

		cardNumber: [
			{ type: 'required', message: 'Please enter card number' },
			{ type: 'invalidString', message: 'Please enter valid card number' }
		],
		name: [
			{ type: 'required', message: 'Please enter name' },
			{ type: 'invalidString', message: 'Please enter valid name' }
		],
		expiry_date: [
			{ type: 'required', message: 'Please enter expiry date' },
			{ type: 'invalidString', message: 'Please enter valid expiry date' }
		],
		cvv: [
			{ type: 'required', message: 'Please enter cvv' },
			{ type: 'invalidString', message: 'Please enter valid cvv' }
		],

	}

	registerFromGroup: FormGroup;

	constructor(
		private modalService: ModalService,
		public getPlatformService: GetPlatformService,
		private formBuilder: FormBuilder,
		public navCtrl: NavController,
		public translate: TranslateService,
		public commonService: CommonService
	) {
	}

	ngOnInit() {
		this.registerFromGroup = this.initializeRegisterFromGroup();
	}


	selectCheckbox(item) {

		if (item["isChecked"] === false) {
			item["isChecked"] = true;
			this.isCheckedList.push(item);
		} else {
			item["isChecked"] = false;
			this.isCheckedList.splice(this.isCheckedList.indexOf(item), 1);
		}
		if (this.isCheckedList.length === 0) {
			this.f1.selectIntrestList.setErrors({ 'required': true });
		}
	}

	closeModal() {
		this.modalService.dismissModal();
	}

	openLoginModal() {
		this.closeModal();
		this.navCtrl.navigateForward('/' + this.commonService.currentLang + '/login');

	}

	get f1() { return this.registerFromGroup.controls; }

	register() {
		for (const i in this.f1) {
			this.f1[i].markAsDirty();
			this.f1[i].updateValueAndValidity();
			this.f1[i].markAsTouched();
		}
		if (this.isCheckedList.length === 0) {
			this.f1.selectIntrestList.setErrors({ 'required': true });
			return;
		}
		if (this.registerFromGroup.valid) {
			if (this.getPlatformService.isDesktop) {
				this.closeModal();
			}
			setTimeout(() => {
				let options: any = {
					id: "twoFaModal",
					backdropDismiss: false
				};
				options['component'] = TwoFactorAuthenticationComponent;
				this.modalService.openModal(options)
			}, 300);
		} else {
			return;
		}
	}

	initializeRegisterFromGroup() {
		return this.formBuilder.group({
			cardNumber: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.noWhiteSpaceValidator]),
			}),
			name: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.noWhiteSpaceValidator]),
			}),
			expiry_date: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.noWhiteSpaceValidator]),
			}),
			cvv: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.noWhiteSpaceValidator]),
			})
		})
	}

}
