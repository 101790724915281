<app-loader *ngIf="isLoading && !getPlatformService.isDesktop "></app-loader>

<ion-content>

	<ion-row class="height-100">
		<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="5" size-xl="5" class="center-div height-100"
			[ngClass]="!getPlatformService.isDesktop ? 'background-blur':''">

			<ion-toolbar mode="md">
				<img router-direction="none" src="./assets/icon/companyLogo.svg" alt="Jazeera" height="100%"
					width="100%" class="company-logo">

				<div class="registration-desktop-header ion-text-center">
					{{ 'Create account' | translate }}
				</div>
			</ion-toolbar>

			<div class="registration-container"
				[ngClass]="[ getPlatformService.isDesktop ? 'registration-container-desktop pt-0': '']">
				<form [formGroup]="registerFromGroup">

					<div class="account-info" [ngClass]="getPlatformService.isDesktop ? 'pt-0':'pt-25'">
						<div class="email">
							<ion-input label="{{'EMAIL' | translate }}" label-placement="floating" fill="outline"
								formControlName="email" readonly></ion-input>
							<ng-container
								*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'email', 'formName': registerFromGroup} }">
							</ng-container>
						</div>
						<div class="password">
							<ion-icon name="eye-outline" slot="end" *ngIf="passwordType == 'text'"
								(click)="passwordType = 'password'"
								[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
							<ion-icon name="eye-Off-outline" slot="end" *ngIf="passwordType == 'password'"
								(click)="passwordType = 'text'"
								[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
							<ion-input type="{{ passwordType }}" clearOnEdit="false" label="{{'PASSWORD' | translate }}"
								label-placement="floating" fill="outline" formControlName="password"
								(keydown.space)="$event.preventDefault();"></ion-input>
							<ng-container
								*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'password', 'formName': registerFromGroup} }">
							</ng-container>
						</div>
						<div class="repeat-password">
							<ion-icon name="eye-outline" slot="end" *ngIf="repeatPasswordType == 'text'"
								(click)="repeatPasswordType = 'password'"
								[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
							<ion-icon name="eye-Off-outline" slot="end" *ngIf="repeatPasswordType == 'password'"
								(click)="repeatPasswordType = 'text'"
								[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
							<ion-input label="{{'REPEAT_PASSWORD' | translate }}" label-placement="floating"
								fill="outline" type="{{ repeatPasswordType }}" clearOnEdit="false"
								formControlName="repeatPassword" (keydown.space)="$event.preventDefault();">
							</ion-input>
							<ng-container
								*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'repeatPassword', 'formName': registerFromGroup} }">
							</ng-container>
						</div>
					</div>

				</form>

				<div class="bottom-container">
					<div class="select-intrest p-0">
						<ion-item lines="none">
							<ion-label class="checkbox-labels2" [ngClass]="getPlatformService.isDesktop ? 'p-5':'p-0'">
								{{'agree' | translate }} <span class="terms-and-condition"
									(click)="gotoTermsConditions()">
									{{'termsCondition' | translate}}</span>
							</ion-label>
							<ion-checkbox mode="md" slot="start" [(ngModel)]="termsCheck"
								[checked]="termsCheck"></ion-checkbox>
						</ion-item>
					</div>

					<div class="register-bottom-container" *ngIf="getPlatformService.isDesktop">
						<div class="register-button">
							<ion-button (click)="register()" [disabled]="isLoading">
								{{ 'Register' | translate }}
							</ion-button>
						</div>
					</div>
				</div>
			</div>
			<ng-template let-formObject #validationMessages>
				<ng-container *ngFor="let validation of validation_messages[formObject.controlName]">
					<ion-text color="danger" class="error-msg"
						*ngIf="formObject.formName.controls[formObject.controlName].hasError(validation.type) && formObject.formName.controls[formObject.controlName].dirty">
						{{validation.message | translate}}
					</ion-text>
				</ng-container>
			</ng-template>

			<ion-footer *ngIf="!getPlatformService.isDesktop">
				<div class="register-button ion-text-center mh-10">
					<app-common-button color="#FFFFFF" background="#0081CA" title="{{'Register'|translate}}"
						[isIcon]="false" [isHideInMobile]="false" [specificWidth]="'161px'" [specificHeight]="'48px'"
						[specificFontWeight]="'bold'" [specificFontSize]="'0.9rem'" (buttonClicked)="register()">
					</app-common-button>
				</div>
				<div class="login-container ion-text-center mh-10">
					{{ 'Already have an account?' | translate }} <span class="login pl-5" (click)="openLoginModal()">{{
						'Login' |
						translate }}<span class="right-icon"><img src="assets/icon/right-arrow.svg" alt="Jazeera"
								height="100%" width="100%"></span></span>
				</div>
			</ion-footer>

			<ion-footer *ngIf="getPlatformService.isDesktop">
				<div class="login-container ion-text-center"
					[ngClass]="getPlatformService.isDesktop ? 'login-desktop':'login-mobile'">
					{{ 'Already have an account?' | translate }} <span class="login pl-10" (click)="openLoginModal()">{{
						'Login' |
						translate }}<span class="right-icon"
							[ngClass]="getPlatformService.isDesktop ? 'top-position-desktop':'top-position-mobile'"><img
								src="assets/icon/right-arrow.svg" alt="Jazeera" height="100%"
								width="100%"></span></span>
				</div>
			</ion-footer>

		</ion-col>

		<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="7" size-xl="7" class="p-0 m-0"
			*ngIf="getPlatformService.isDesktop">
			<div class="bg-img"></div>
		</ion-col>

	</ion-row>

</ion-content>