<ion-content *ngIf="!isLoading && cartDetails && cartData.length > 0 && cartDetails.grand_total">
	<div>
		<ion-row class="row-div">
			<ion-col size-sm="6" size-md="6" size-lg="6" size-xl="6" size-xs="6">
				<p class="label1 color-app-grey"><span class="color-app-black">{{cartData.length}}
						{{cartData.length > 1 ? ('cartModal.products'|translate) :
						('cartModal.product'|translate)}}</span>
					<span class="label-in-cart"> {{'cartModal.incart'| translate}}</span>
				</p>
			</ion-col>
			<ion-col size-sm="6" size-md="6" size-lg="6" size-xl="6" size-xs="6" class="textEnd">
				<p class="label2 color-app-black">{{'cartModal.CartSubTotal'|translate}} </p>
				<p class="label-total color-app-black" *ngIf="cartDetails.grand_total &&
                    items_sub_total">{{commonService.currencySymbol}}
					{{items_sub_total|number}}</p>

				<ion-skeleton-text *ngIf="!cartDetails.grand_total &&
                    !items_sub_total" class="skeleton-container" name="lines-sharp-small"
					[animated]="true"></ion-skeleton-text>
				<p class="label2 clearCart color-app-black mt-10 pointer" (click)="clearCart()">
					{{'cartModal.clearCart'|translate}} </p>
			</ion-col>
		</ion-row>
		<div class="divider-line"></div>

		<div class="scroll-content" *ngIf="cartData.length && !isLoading">
			<div *ngFor="let data of cartData;let i =index">
				<ion-row class="row-padding">
					<div class="mr-15 position-rel" [ngClass]=" data.is_sold_out ? 'product-image-blur' : null">

						<div *ngIf="data.discount_type &&  data.discount_type != 'PRICE' && !data.is_sold_out && !data.is_sample_product"
							class="discout_div ">
							<span>-</span>{{ data.discount_value }}<span>%</span>
						</div>
						<div *ngIf="data.discount_type &&  data.discount_type == 'PRICE' && !data.is_sold_out  && !data.is_sample_product"
							class="discout_div ">
							<span>{{"OnSale" | translate}}</span>
						</div>

						<img *ngIf="data.product_cover_image" src="{{data.product_cover_image[0].image_url}}"
							class="product_img" alt="jazeera" />

						<img *ngIf="!data.product_cover_image" src="./assets/images/empty-img.png" class="product_img"
							alt="Jazeera" />
					</div>
					<div *ngIf="data.is_sold_out" class="product-outofstock">
						<div class="outofstock-label">
							{{"outOfStock" | translate}}
						</div>
					</div>

					<div class="width-col">
						<p class="label3 color-app-black">{{commonService.domDirectionLTR ? data.product_name_en :
							data.product_name_ar}}</p>
						<div *ngIf="data.is_sample_product == true" class="sample_div ">
							<p>{{'SAMPLE'| translate}}</p>
						</div>

						<p class="fs-16 color-app-grey mt-10 mb-0">
							{{data.product_sku}}
						</p>

						<p class="label5 color-app-grey display-div cursor-pointer " (click)="goToProductDetail(data)">
							{{'ViewDetails' |translate}}
						</p>

						<div class="rowQty display-div">
							<p class="label3 color-app-black product-price">
								{{commonService.currencySymbol}}{{data.is_sample_product ? (data.price_per_unit |
								number): (data.final_price_per_unit | number)}}
							</p>

							<div class="units-containter">

								<div class="qty_div">
									<p class="label4 color-app-black">{{'Quantity'|translate}} : {{data.total_quantity}}
									</p>
								</div>
								<div class="qty_div suom"
									*ngIf="data.unit_id && data.secondary_unit_id && data.unit_id !== data.secondary_unit_id">
									<p class="label4 color-app-black">
										{{calculateSUOM(data.conversation_ratio,data.total_quantity)}}
									</p>
								</div>
							</div>
							<div class="ml-10">
								<img src="/assets/icon/trash.svg" class="deleticon color-app-black"
									(click)="deleteProduct(data)" title="Delete" alt="Jazeera" />
							</div>
						</div>
					</div>



					<div class="width-100" *ngIf="i != (cartData.length - 1)">
						<div class="divider-line mt-15 border-right"></div>
					</div>


				</ion-row>

			</div>
		</div>
	</div>
</ion-content>

<app-loader *ngIf="isLoading" [fromCartPopup]="true"></app-loader>
<app-loader *ngIf="!isLoading && !cartDetails.grand_total && cartData.length != 0" [fromCartPopup]="true"></app-loader>
<div *ngIf="!cartData.length && !isLoading" class="vertical_center">
	<img src="./assets/icon/emptyCart.svg" alt="Jazeera" class="mb-10" />
	<div class="mb-20">
		<p class="label-3 color-app-black ">{{'Thecartisempty' | translate}}</p>
	</div>
</div>

<ion-footer *ngIf="!isLoading && cartDetails && cartData.length > 0 && cartDetails.grand_total">
	<div class="divider-line"></div>
	<div class="ph-25 pv-10">
		<!-- <app-common-button color="#FFF" background="#38C2D4" type="solid" class="width-100"
			title="{{'cartModal.Viewandeditcart'|translate}}" [isIcon]="false" [isHideInMobile]="false"
			[isFullWidth]="true" [specificHeight]="'46px'" [specificFontSize]="'0.9rem'"
			[isButtonLoading]="isAddToCartLoading" (buttonClicked)="gotoCartPage()" [specificFontWeight]="700"
			*ngIf="cartData.length">
		</app-common-button> -->

		<app-common-button class="width-100" (buttonClicked)="gotoCartPage()" color="white"
			background="var(--ion-color-blue-100)" type="solid" title="{{'cartModal.Viewandeditcart'|translate}}"
			[isIcon]="false" [specificPaddingStart]="'16px'" [isPrimaryBtn]="true"
			[isButtonLoading]="isAddToCartLoading" [isSecondryBtn]="false" [specificFontWeight]="700"
			*ngIf="cartData.length" [isFullWidth]="true" [buttonDisabled]="false" [specificFontSize]="'0.9rem'"
			[specificPaddingEnd]="'16px'" [isHideInMobile]="false" [specificWidth]="'fit-content'"
			[borderRadius]="'4px'" [specificHeight]="'46px'" [borderWidth]="'1px'" [borderStyle]="'solid'"
			borderColor="var(--ion-color-blue-100)"></app-common-button>
	</div>
</ion-footer>