<div>
	<div class="upper-part-watchlist">
		<div class="listing-header">
			<h1 class="heading m-0">{{"myWishListPage.myWishList" | translate}}</h1>
			<div class="search-and-filter" *ngIf="isNoData">
				<div class="filter-div">
					<div class="section-block">
						<div class="filter-icon" (click)="openFilters()" *ngIf="!showFilter">
							<img src="./assets/icon/filterIcon.svg" alt="Jazeera">
						</div>
						<div class="close-filter-icon" (click)="openFilters()"
							*ngIf="showFilter && myWishlist.length > 0">
							<img src="./assets/icon/modal-close-mobile.svg" alt="Jazeera">
						</div>
						<div class="filter-label" [ngClass]="showFilter ? 'close-label' : ''" (click)="openFilters()">
							{{"ordersComponent.filters" | translate}}
						</div>

					</div>
				</div>
			</div>
		</div>
		<ng-container>
			<div class="wishlist-btn-block mb-20">
				<div class="wishlist-block mr-20 common-input"
					[ngClass]="[ commonService.domDirectionLTR ? 'new-address-modal' : 'new-address-modal-arabic']">
					<ion-item lines="none" mode="md" class="new-address-modal-dropdowns">
						<img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/name-icon.svg"
							alt="Jazeera">

						<ion-label *ngIf="!selectedCategory" class="static-label ">{{"myWishListPage.categoryName" |
							translate}}</ion-label>
						<ion-label class="texUpperCasep" *ngIf="selectedCategory"
							position="floating">{{"myWishListPage.categoryName" |
							translate}}</ion-label>
						<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
							[interfaceOptions]="inerfaceOption" interface="popover" mode="md" fill=""
							label-placement="floating"
							[ngClass]="[ selectedCategory ? 'option-selected' : 'not-selected']"
							[(ngModel)]="selectedCategory" (ionChange)="categoyChanged($event)">

							<ion-select-option *ngFor="let cat of catagoryListData"
								[value]="cat.sub_class_id">{{commonService.domDirectionLTR ?
								cat.display_class_name_en :
								cat.display_class_name_ar}}</ion-select-option>
						</ion-select>
					</ion-item>
				</div>
				<div class="wishlist-block common-input"
					[ngClass]="[ commonService.domDirectionLTR ? 'new-address-modal' : 'new-address-modal-arabic']">
					<ion-item lines="none" mode="md" class="new-address-modal-dropdowns">
						<ion-label *ngIf="!selectedSubClass" position="static"
							class="static-label texUpperCasep">{{"subcategory" |
							translate}}</ion-label>
						<ion-label class="texUpperCasep" *ngIf="selectedSubClass" position="floating">{{"subcategory" |
							translate}}</ion-label>
						<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
							[disabled]="!selectedCategory && subClassArray.length < 1"
							[ngClass]="[ selectedSubClass ? 'option-selected' : 'not-selected']" mode="md" fill=""
							label-placement="floating" [interfaceOptions]="inerfaceOption" interface="popover"
							[(ngModel)]="selectedSubClass" (ionChange)="subClassChanged($event)">

							<ion-select-option *ngFor="let subClass of subClassArray"
								[value]="subClass.sub_class_id">{{commonService.domDirectionLTR ?
								subClass.sub_class_name_en
								:
								subClass.sub_class_name_ar}}
							</ion-select-option>
						</ion-select>
					</ion-item>
				</div>

				<div class="filter-buttons select-block ml-20">
					<div class="apply-button ion-text-end">
						<button class="filter-apply-button" (click)="clearFilter()">{{"clear" | translate}}</button>
					</div>
					<!-- <button class="filter-apply-button" (click)="applyBtn()">{{"apply" | translate}}</button> -->
				</div>
			</div>
			<div class="space-between desktop-visibility-in-flex mt-20 mb-40" *ngIf="false">
				<form class="desktop-visibility-in-flex w-49" [formGroup]="dateRange" *ngIf="showFilter">
					<div class="select-block mr-20  desktop-visibility-in-flex">
						<div class="input-div-container">
							<div class="from-date-div">
								<ion-item class="from-date-class"
									[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'" id="fromDateId">
									<ion-input value="{{ fromDate | date: 'MMM dd, YYYY'}}" placeholder="{{'from'
                                            | translate}}" class="ion-text-start"></ion-input>
									<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end"
										alt="Jazeera"></ion-icon>
									<ion-popover trigger="fromDateId" size="cover" id="datePopover">
										<ng-template>
											<ion-datetime presentation="date" [min]="'2000-01-01'"
												[max]="toDate ? toDate : maxFromDate" formControlName="from"
												[(ngModel)]="fromDate" #fromdate
												(ionChange)="onDateChange(fromdate,'from')"><span slot="title">{{"from"
													| translate}}</span></ion-datetime>
										</ng-template>
									</ion-popover>
								</ion-item>
							</div>
						</div>
					</div>
					<div class="select-block desktop-visibility-in-flex">
						<div class="to-date-div">
							<ion-item class="to-date-class" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'"
								id="toDateId">
								<ion-input value="{{ toDate | date: 'MMM dd, YYYY' }}" placeholder="{{'to'
                                            | translate}}" class="ion-text-start"></ion-input>
								<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end"
									alt="Jazeera"></ion-icon>
								<ion-popover trigger="toDateId" size="cover" id="datePopover">
									<ng-template>
										<ion-datetime presentation="date" [min]="fromDate ? fromDate : '2000-01-01'"
											[max]="maxDate" [(ngModel)]="toDate" formControlName="to" #todate
											(ionChange)="onDateChange(todate,'to')"><span slot="title">{{"to"
												| translate}}</span></ion-datetime>
									</ng-template>
								</ion-popover>
							</ion-item>
						</div>
					</div>
				</form>



				<!-- <div class="filter-buttons select-block ml-20">
					<div class="apply-button ion-text-end">
						<button class="filter-apply-button" (click)="clearFilter()">{{"clear" | translate}}</button>
					</div>
					<button class="filter-apply-button" (click)="applyBtn()">{{"apply" | translate}}</button>
				</div> -->
				<!-- code required -->
				<!-- <div class="add-cart-button btn-container">
        				<ion-button>
        					<span class="cart-icon"><img src="assets/icon/fi_shopping-cart.svg"></span>
        					{{"myWishListPage.addToCart" | translate}}
        				</ion-button>
        			</div> -->
			</div>
		</ng-container>
	</div>
	<div *ngIf="myWishlist && myWishlist.length > 0 && !isLoading">
		<ion-row>
			<ion-col class="card-block" *ngFor="let data of myWishlist;index as i" size-xs="6" size-sm="6" size-md="6"
				size-lg="3" size-xl="3">
				<app-product-card (productClicked)="goToProductDetail(data)"
					(deleteFromWishList)="presentAlert(data, i)" [cardData]="data" [productDetailLabel]="true"
					[wishListPage]="true"></app-product-card>
			</ion-col>
		</ion-row>
	</div>
	<div class="load-more-btn ion-text-center">
		<app-common-button color="white"
			*ngIf="myWishlist && myWishlist.length >= 8 && enableNext && !isLoadMoreLoading"
			(buttonClicked)="loadMoreData($event)" background="var(--ion-app-primary)" type="solid"
			title="{{ 'productGridComponent.Load more' | translate }}" [isIcon]="true"
			iconSrc="assets/icon/down-arrow.svg" [specificPaddingStart]="'40px'" [specificPaddingEnd]="'10px'"
			[isHideInMobile]="true" [specificWidth]="'190px'" [specificHeight]="'46px'"
			[specificFontWeight]="'bold'"></app-common-button>
		<ion-spinner *ngIf="isLoadMoreLoading" name="circular"></ion-spinner>
	</div>
	<div class="ion-text-center pt-70" *ngIf="isLoading">
		<ion-spinner name="circular"></ion-spinner>
	</div>
	<div *ngIf="myWishlist && myWishlist.length <= 0 && !isLoading">
		<div class="no-record-found ion-text-center">
			{{'noRecordFound' | translate}}
		</div>
	</div>
</div>