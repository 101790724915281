import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { VoucherMobilePage } from './voucher-mobile.page';

import { SharedModule } from 'src/app/common/shared.module';
import { ExploreContainerComponentModule } from 'src/app/explore-container/explore-container.module';
import { VoucherMobilePageRoutingModule } from './voucher-mobile-routing.module';

@NgModule({
	imports: [
		IonicModule,
		CommonModule,
		FormsModule,
		ExploreContainerComponentModule,
		RouterModule.forChild([{ path: '', component: VoucherMobilePage }]),
		VoucherMobilePageRoutingModule,
		SharedModule
	],
	declarations: [VoucherMobilePage]
})
export class VoucherMobilePageModule { }
