<ion-grid class="order-detail-grid">
	<ion-row class="order-detail-header-row">
		<ion-col class="order-detail-header-col">
			<div class="order-detail-header">
				<div class="order-number">
					{{"orderDetailsComponent.orders" | translate}}{{ orderData['order_no']}}
				</div>
				<div class="order-detail-actions">

					<ion-button (click)="reorder()" class="filter-apply-button">
						<div class="re-order">
							<div class="mr-10">
								<img src="assets/icon/white-reorder.svg" alt="Jazeera">
							</div>
							<div>
								{{"orderDetailsComponent.reorder" | translate}}
							</div>
						</div>
					</ion-button>
				</div>
			</div>
			<div class="order-detail-info pt-20">
				<div class="order-detail-date">
					<div class="common-detail-header">
						{{"orderDate" | translate}}
					</div>
					<div class="common-detail">
						{{orderData['order_date'] | date : 'MMM dd, YYYY'}}
					</div>
				</div>
				<div class="vertical-line"></div>
				<div class="order-total">
					<div class="common-detail-header">
						{{"orderDetailsComponent.totalOrder" | translate}}
					</div>
					<div class="common-detail">
						{{commonService.currencySymbol}} {{orderData['total_price'] ? (orderData['total_price'] |
						number) :'0'}}
					</div>
				</div>
				<div class="vertical-line"></div>

				<div class="ship-to">
					<div class="common-detail-header">
						{{"orderDetailsComponent.shipTo" | translate}}
					</div>
					<div class="common-detail">
						{{orderData['shipping_address']}} {{orderData['city_en'] ? ',':''}} {{orderData['city_en'] ?
						orderData['city_en'] :''}}{{orderData['region_en'] ? ',':''}}
						{{orderData['region_en'] ? orderData['region_en'] :''}} {{orderData['country_en'] ? ',':''}}
						{{orderData['country_en'] ?
						orderData['country_en'] :''}} {{orderData['zipcode'] ? ',':''}} {{orderData['zipcode'] ?
						orderData['zipcode'] :''}}
						<p class="m-0 p-0">
							{{orderData['mobile_no']}}
						</p>
					</div>
				</div>
				<div class="vertical-line"></div>
				<div class="order-total">
					<div class="common-detail-header">
						{{"customField.shippingNo" | translate}}
					</div>
					<div class="common-detail">
						{{orderData['shipping_no'] ? orderData['shipping_no'] :'--'}}
					</div>
				</div>
				<div class="vertical-line"></div>
				<div class="order-detail-status pl-70">
					<div class="common-detail-header">
						{{"customField.paymentMode" | translate}}
					</div>
					<div>
						<div fill="clear" class="order-status" [ngClass]="orderStatus(orderData)">
							{{orderData['payment_mode'] ? orderData['payment_mode'] :'--'}}
						</div>
					</div>
				</div>
			</div>
		</ion-col>
	</ion-row>
	<div class="scrollable-container">
		<ion-row class="order-detail-row row-header">
			<ion-col size-md="12" size-lg="8" size-xl="8" class="order-detail-left-col">
				<div class="order-summery-header pb-20">
					{{"customField.itemsList" | translate}}
				</div>
				<ion-grid class="order-detail-list">
					<!-- <ion-row class="order-detail-list-header scrollable-row">
						<ion-col size="6" class="header-col">
							<div class="order-detail-header">
								{{"orderDetailsComponent.item" | translate}}
							</div>
						</ion-col>
						<ion-col size="2">
							
						</ion-col>
						<ion-col size="2">
							
						</ion-col>
						<ion-col size="2">
							
						</ion-col>
						<ion-col size="2">
							<div class="order-detail-header">
								{{"orderDetailsComponent.balanceQty" | translate}}
							</div>
						</ion-col>
						<ion-col size="2">
							
						</ion-col>
						<ion-col size="2">
							<div class="order-detail-header pl-25">
								{{"orderDetailsComponent.status" | translate}}
							</div>
						</ion-col>
						<ion-col size="2">
							<div class="ion-text-end pe-20 order-detail-header mr-15">
								{{"orderDetailsComponent.subTotal" | translate}}
							</div>
						</ion-col>
					</ion-row> -->
					<ion-grid class="order-detail-inner-grid " *ngFor="let detailData of supplierData">
						<ng-container *ngIf="detailData.products && detailData.products.length">
							<ng-container *ngFor="let supplierData of detailData.products">
								<div class="bg-white mb-25 br-5 ph-16 pv-20">

									<div class="product-description">
										<div class="product-name pb-5" (click)="goToProductDetail(supplierData)">
											{{ commonService.domDirectionLTR ? supplierData.product_name_en :
											supplierData.product_name_ar}}
										</div>
										<div class="ion-text-center product-details-col pl-0">
											<div class="product-status">
												<ion-button *ngIf="supplierData.shipping_status" fill="clear"
													class="order-status-btn"
													[ngClass]="statusMap[supplierData.shipping_status]?.class || ''">
													{{ statusMap[supplierData.shipping_status]?.label | translate }}
												</ion-button>

												<div *ngIf="supplierData.is_cancellation_allowed || supplierData.is_return_allowed"
													class="cancel-product">
													<ion-button fill="clear"
														class="order-status-btn product-status-btn return-request-btn"
														*ngIf="supplierData.is_cancellation_allowed">
														{{"orderDetailsComponent.cancelProduct" | translate}}
													</ion-button>
													<span *ngIf="supplierData.is_return_allowed"
														(click)="openCancelProductModal(supplierData, true)">
														{{"orderDetailsComponent.returnProduct" | translate}}
													</span>
												</div>
											</div>
										</div>

									</div>
									<ion-row class="product-details-row pt-15 pb-10 scrollable-row">
										<div class="product-details-col pl-0">
											<div class="product-container">
												<div class="product-image" (click)="goToProductDetail(supplierData)">
													<img [src]="supplierData.product_cover_image[0].image_url"
														*ngIf="supplierData.product_cover_image && supplierData.product_cover_image.length"
														alt="Jazeera">
													<img src="./assets/images/empty-img.png"
														*ngIf="!supplierData.product_cover_image || (supplierData.product_cover_image && !supplierData.product_cover_image.length)"
														alt="Jazeera">
												</div>

											</div>
										</div>


										<div class="product-details-col pl-0">
											<div class="order-detail-header">
												{{ "orderDetailsComponent.orderQty" | translate}}
											</div>
											<div class="product-price">
												{{ supplierData.total_quantity }}
											</div>
										</div>
										<div class="vertical-line"></div>

										<div class="product-details-col pl-0">
											<div class="order-detail-header">
												{{"orderDetailsComponent.deliverQty" | translate}}
											</div>
											<div class="product-price">
												{{ supplierData.total_quantity }}
											</div>
										</div>

										<div class="vertical-line"></div>

										<div class="product-details-col pl-0">
											<div class="order-detail-header">
												{{"orderDetailsComponent.invoiceQty" | translate}}
											</div>
											<div class="product-price">
												{{ supplierData.total_quantity }}
											</div>
										</div>
										<div class="vertical-line"></div>

										<div class="product-details-col pl-0">
											<div class="order-detail-header">
												{{"orderDetailsComponent.balanceQty" | translate}}
											</div>
											<div class="product-price">
												{{ supplierData.total_quantity }}
											</div>
										</div>
										<div class="vertical-line"></div>

										<div class="product-details-col pl-0">
											<div class="order-detail-header">
												{{"orderDetailsComponent.subTotal" | translate}}
											</div>
											<div class="product-price">
												{{commonService.currencySymbol}} {{
												supplierData.is_discount_applied ?
												supplierData.discounted_price_per_product
												?
												(supplierData.discounted_price_per_product | number) :'0':
												supplierData.price_per_unit ? (supplierData.price_per_unit | number) :
												'0'}}
												<span class="product-unit">/{{
													commonService.domDirectionLTR ? supplierData.unit_name_en :
													supplierData.unit_name_ar}}</span>
												<span class="price-units"
													*ngIf="supplierData.unit_id && supplierData.secondary_unit_id && supplierData.unit_id !== supplierData.secondary_unit_id">
													({{calculateSUOM(supplierData.conversation_ratio ,
													supplierData.total_quantity)}})
												</span>
												<!-- <span class="product-unit">
											({{commonService.domDirectionLTR ?
											supplierData.secondary_unit_name_en :supplierData.secondary_unit_name_ar}}) </span> -->
											</div>
										</div>
									</ion-row>

									<ion-row *ngIf="supplierData.isvisible"
										class="product-details-row pt-15 pb-10 scrollable-row">
										<div class="product-details-col pl-0">
											<div class="product-container">
												<div class="product-image">

												</div>

											</div>
										</div>


										<div class="product-details-col pl-0">
											<div class="order-detail-header">
												{{ "customField.warehouseName" | translate}}
											</div>
											<div class="product-price">
												{{ supplierData.warehouse_name || "--" }}
											</div>
										</div>
										<div class="vertical-line"></div>

										<div class="product-details-col pl-0">
											<div class="order-detail-header">
												{{ "customField.warehouseID" | translate}}
											</div>
											<div class="product-price">
												{{ supplierData.warehouse_id || "--" }}
											</div>
										</div>

										<div class="vertical-line bg-transparent"></div>

										<div class="product-details-col pl-0">
											<div class="order-detail-header">
											</div>
											<div class="product-price">
											</div>
										</div>

										<div class="vertical-line bg-transparent"></div>

										<div class="product-details-col pl-0">
											<div class="order-detail-header">
											</div>
											<div class="product-price">
											</div>
										</div>

										<div class="vertical-line bg-transparent"></div>

										<div class="product-details-col pl-0">
											<div class="order-detail-header">
											</div>
											<div class="product-price">
											</div>
										</div>

										<div class="vertical-line bg-transparent"></div>

										<div class="product-details-col pl-0">
											<div class="order-detail-header">
											</div>
											<div class="product-price">
											</div>
										</div>

									</ion-row>

									<ion-row class="align-center justify-center">
										<div class="section-block cursor-pointer"
											(click)="expandProductDetails(supplierData)">
											<p class=" m-0 p-0 color-primary text-bold">
												{{supplierData.isvisible ? ('ViewLessDetails'|translate)
												:('ViewMoreDetails'|translate) }}
											</p>
											<ion-icon *ngIf="!supplierData.isvisible" name="chevron-down-outline"
												class="color-primary ml-10 fs-18"></ion-icon>
											<ion-icon *ngIf="supplierData.isvisible" name="chevron-up-outline"
												class="color-primary ml-10 fs-18"></ion-icon>
										</div>

									</ion-row>
								</div>

							</ng-container>
						</ng-container>


					</ion-grid>
				</ion-grid>
			</ion-col>
			<ion-col size-md="12" size-lg="4" size-xl="4" class="order-detail-right-col"
				[ngClass]="commonService.domDirectionLTR ? '' : 'order-detail-right-col-rtl'">
				<div class="order-summery-header pb-20">
					{{"orderDetailsComponent.orderSummery" | translate}}
				</div>

				<div>
					<app-order-summary [currency]="commonService.currencySymbol" [orderTotal]="orderData.total_price"
						[orderSubTotal]="orderData.items_sub_total" [orderDiscount]="orderData.discount_price"
						[orderVAT]="orderData.vat" [vatPercent]="orderData.vat_percent"
						[orderSummeryData]="orderData.grand_total" [discountCode]="orderData.discount_code"
						[orderShipping]="orderData.shipping_charges" isTotalSummary="true"
						[shippingVat]="orderData.shipping_vat_charges" [background]="'white'"
						[orderShippingWithoutVat]="orderData.shipping_charges_without_vat">
					</app-order-summary>
				</div>
			</ion-col>
		</ion-row>
	</div>

</ion-grid>