<ion-row class="main-div" *ngIf="getPlatformService.isDesktop">
	<!-- <ion-col size-xs="3" size-sm="3" size-md="2" size-lg="2" size-xl="2">
        <img routerLink="/" src="./assets/icon/companyLogo.svg" alt="company-logo" height="100%" width="100%"
            class="company-logo pointer">
    </ion-col> -->

	<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
		<ion-row class="row-div" *ngIf="countIndex != 0">
			<div (click)="navigate('cart')" class="section-block flex-column">
				<div class="round-main-div" [ngClass]="countIndex >= 1 ? 'bg-primary pointer':'bg-gray-90'">
					<img [src]="'/assets/icon/select1.svg'" alt="Jazeera" class="round-div" />
				</div>
				<p class="label1" [ngClass]="countIndex >= 1 ? 'pointer color-primary text-bold': 'color-app-grey'"
					(click)="navigate('cart')">
					{{"cartHeader.Shoppingcart"|translate}}</p>
			</div>

			<div class="horizontal-line"></div>

			<div (click)="navigate('shipping')" class="section-block flex-column">
				<div class="round-main-div" [ngClass]="countIndex >= 2? 'bg-primary pointer':'bg-gray-90'">
					<img [src]="countIndex >= 2 ? './assets/icon/select2.svg':'./assets/icon/unselect2.svg'"
						alt="Jazeera" class="round-div" />
				</div>
				<p class="label1" [ngClass]="countIndex >= 2 ? 'pointer color-primary text-bold':'color-app-grey'"
					(click)="navigate('shipping')">{{'cartHeader.Shipping'|translate}}</p>
			</div>

			<div class="horizontal-line"></div>

			<div (click)="navigate('payment')" class="section-block flex-column">
				<div class="round-main-div" [ngClass]="countIndex >= 3 ? 'bg-primary pointer':'bg-gray-90'">
					<img [src]="countIndex >= 3 ? './assets/icon/select3.svg':'./assets/icon/unselect3.svg'"
						alt="Jazeera" class="round-div" />
				</div>
				<p class="label1" [ngClass]="countIndex >= 3 ? 'pointer color-primary text-bold':'color-app-grey'"
					(click)="navigate('payment')">
					{{'cartHeader.Payment'|translate}}</p>
			</div>
			<div class="horizontal-line"></div>

			<div (click)="navigate('review')" class="section-block flex-column">
				<div class="round-main-div" [ngClass]="countIndex >= 4 ? 'bg-primary pointer':'bg-gray-90'">
					<img [src]="countIndex >= 4 ? './assets/icon/select4.svg':'./assets/icon/unselect4.svg'"
						alt="Jazeera" class="round-div" />
				</div>
				<p class="label1" [ngClass]="countIndex >= 4 ? 'pointer color-primary text-bold':'color-app-grey'"
					(click)="navigate('review')">
					{{'cartHeader.Review'|translate}}</p>
			</div>
		</ion-row>
	</ion-col>

	<!-- <ion-col size-xs="3" size-sm="3" size-md="2" size-lg="2" size-xl="2" class="text-right display-div">
        <i class="feather icon-help-circle color-primary cursor-pointer" (click)="gotToContactUs()"></i>
        <span class="label2 color-primary mh-10 cursor-pointer"
            (click)="gotToContactUs()">{{'cartHeader.Needhelp?'|translate}}</span>
    </ion-col> -->
</ion-row>

<!-- <ion-row class="main-div2" *ngIf="!getPlatformService.isDesktop">
    <ion-col size-xs="6" size-sm="6" size-md="6" size-lg="2" size-xl="2">
        <img routerLink="/" src="./assets/icon/companyLogo.svg" alt="company-logo" class="company-logo">
    </ion-col>

    <ion-col size-xs="6" size-sm="6" size-md="6" size-lg="2" size-xl="2" class="text-right display-div">
        <i class="feather icon-help-circle color-primary cursor-pointer"></i>
        <span class="label2 color-primary mh-10 cursor-pointer">{{'cartHeader.Needhelp?'|translate}}</span>
    </ion-col>
</ion-row> -->
<ion-row class="main-div2" *ngIf="!getPlatformService.isDesktop && countIndex != 1">
	<ion-row class="row-full-div mt-10">
		<!-- <div class="column-css">
            <p class="label1 color-app-black text-center">{{"cartHeader.Shoppingcart"|translate}}</p>
        </div> -->

		<div class="column-css">
			<p class="label1 color-app-grey text-center" (click)="navigate('shipping')">
				{{'cartHeader.Shipping'|translate}}</p>
		</div>

		<div class="column-css">
			<p class="label1 color-app-grey text-center" (click)="navigate('payment')">
				{{'cartHeader.Payment'|translate}}</p>
		</div>

		<div class="column-css">
			<p class="label1 color-app-grey text-center" (click)="navigate('review')">{{'cartHeader.Review'|translate}}
			</p>
		</div>

	</ion-row>
</ion-row>

<ion-range *ngIf="!getPlatformService.isDesktop && countIndex != 1"
	[value]="countIndex == 2 ? 20 : countIndex == 3 ? 50 : 80 " [pin]="false" class="m-0 p-0"></ion-range>