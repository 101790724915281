<!-- ----------------- from login flow ------------------------------- -->



<div class="two-fa-container ion-text-center" *ngIf="fromPage != 'MyAccount' && !twoFaSettingsConformation">
	<div class="modal-close text-right" *ngIf="getPlatformService.isDesktop" (click)="closeModal()">
		<img src="assets/icon/modal-close.svg" alt="Close" height="100%" width="100%">
	</div>
	<div class="modal-close-mobile text-right" *ngIf="!getPlatformService.isDesktop" (click)="closeModal()">
		<img src="assets/icon/modal-close-mobile.svg" alt="Jazeera">
	</div>
	<div class="header-line" *ngIf="!getPlatformService.isDesktop">
	</div>
	<div class="two-fa-header pb-10">
		{{ "twoFaDescription" | translate }}
	</div>
	<div class="two-fa-discription">
		{{ "enabled2fa" | translate }}
	</div>

	<div *ngIf="isLoading && !getPlatformService.isDesktop" class="loader-div">
		<ion-item lines="none">
			<ion-spinner name="circular"></ion-spinner>
		</ion-item>
	</div>

	<ng-container *ngIf="!isLoading">
		<div class="code-input ion-text-center">
			<code-input class="code-input-container" #codeInput [isCodeHidden]="isCodeHidden" [codeLength]="5"
				[initialFocusField]="0" [isPrevFocusableAfterClearing]="false" (codeCompleted)="verifyOtp()"
				(codeChanged)="onOtpChanged($event, codeInput)">
			</code-input>
		</div>
		<div class="two-fa-Resend" *ngIf="resendTimer != null">
			{{ "fiveDigitVerification" | translate }} {{ mobileNumber }}.
		</div>
		<div class="two-fa-Resend" *ngIf="resendTimer == null">
			{{ "receiveVerification" | translate }} <br><span class="resend-it"
				[ngClass]="isGettingOtp ?  'cursor-disabled': ''" (click)="getOtp()" *ngIf="otpCount <= 3">{{
				"clickToResend" | translate }}</span>
		</div>
		<div class="two-fa-Resend resend-in" *ngIf="resendTimer !== null">
			<span class="resend-it cursor-disabled" *ngIf="otpCount <= 3"> {{ "resendIn" | translate }} {{ resendTimer
				}}</span>
		</div>
	</ng-container>

	<div class="login-bottom-container" *ngIf="getPlatformService.isDesktop">
		<div class="cancel" (click)="closeModal()">
			{{ 'Cancel' | translate }}
		</div>
		<div class="login-button">
			<ion-button *ngIf="fromPage != 'MyAccount'" (keydown)="enterOtpSubmit($event)" (click)="verifyOtp()"
				[disabled]="disableBtn" tabindex="0">
				<ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
				{{'Verify' | translate}}
			</ion-button>
		</div>
	</div>
	<div class="pt-25 login-button-container" *ngIf="!getPlatformService.isDesktop">
		<div class="login-button ion-text-center mh-10">
			<app-common-button color="#FFFFFF" background="#0081CA" title="{{'Verify'|translate}}" [isIcon]="false"
				[isHideInMobile]="false" [specificHeight]="'48px'" [specificFontWeight]="'bold'"
				[specificFontSize]="'0.9rem'" [isFullWidth]="true" [buttonDisabled]="disableBtn"
				(buttonClicked)="verifyOtp()">
			</app-common-button>
		</div>
	</div>
</div>

<!-- ----------------- from my account ------------------------------- -->

<div class="two-fa-container ion-text-center" *ngIf="fromPage == 'MyAccount' && !twoFaSettingsConformation">
	<div class="modal-close text-right" (click)="closeModal()">
		<img src="assets/icon/modal-close.svg" alt="Close" height="100%" width="100%">
	</div>
	<div class="two-fa-header pb-10">
		<span *ngIf="!isTwoFaEnabled">{{'Enable2FA'| translate }}</span>
		<span *ngIf="isTwoFaEnabled">{{'Disable2FA'| translate }}</span>
	</div>
	<div class="two-fa-discription-my-account">
		{{"2FAAccountDialogDescription" | translate}}
	</div>

	<div class="select-input ion-text-center">
		<ion-toggle [(ngModel)]="isTwoFaEnabled" mode="ios" (click)="twoFaToggle($event)"></ion-toggle>
	</div>
</div>

<div class="two-fa-container ion-text-center" *ngIf="twoFaSettingsConformation">
	<div class="modal-close text-right" (click)="closeModal()">
		<img src="assets/icon/modal-close.svg" alt="Close" height="100%" width="100%">
	</div>
	<div class="pt-35">
		<img class="mark-icon" src="assets/icon/mark.svg" alt="Jazeera">
	</div>
	<div class="two-fa-header pb-10 pt-20">
		<span>{{ 'congratulations' | translate }}</span>
	</div>
	<div class="two-fa-discription-my-account">
		{{ 'enabledTwoFA' | translate }}
	</div>
	<div class="login-button ion-text-center pt-10 pb-30">
		<app-common-button color="#FFFFFF" background="#0081CA" title="{{'done'|translate}}" [isIcon]="false"
			[isHideInMobile]="false" [specificHeight]="'46px'" [specificFontWeight]="'bold'"
			[specificFontSize]="'0.938rem'" [specificWidth]="'150px'" (buttonClicked)="closeModal()">
		</app-common-button>
	</div>
</div>