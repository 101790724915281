import { DatePipe, DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { DomSanitizer, Meta, Title } from "@angular/platform-browser";
import { NavController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { GetPlatformService } from "src/app/services/get-platform.service";
import { URLS } from "../../_config/api.config";
import { ApiService } from "../../services/api-data.service";
import { CommonService } from "../../services/common.service";
import { ToastService } from "../../services/toast.service";
import { newsData } from "./news-data";

@Component({
	selector: "app-news",
	templateUrl: "./news.page.html",
	styleUrls: ["./news.page.scss"],
})
export class NewsPage implements OnInit {

	public subscription: Subscription = new Subscription();
	isLoading: boolean = false;
	newsData: any = [];
	searchText: any;

	fromDate: any;
	todaysDate = new Date();
	maxFromDate: any;
	selectedSortBy: any;
	btnStatus: any = true;
	currentLang;
	filterObj = {};

	public itemsPerPage = 8;
	public currentPage = 1;
	public count = 0;
	public enableNext = false;
	constructor(
		private dataService: ApiService,
		private toastService: ToastService,
		public commonService: CommonService,
		public navCtrl: NavController,
		public getPlatformService: GetPlatformService,
		@Inject(DOCUMENT) private document: Document,
		@Inject(PLATFORM_ID) private platformId: Object,
		private metaService: Meta,
		private setTitleService: Title,
		protected sanitizer: DomSanitizer,
		private datePipe: DatePipe,
		public translate: TranslateService,

	) {
		this.currentLang = this.commonService.currentLang;
		this.initializeDateForm();
	}

	ngOnInit() {
		this.getData();
		this.newsData = newsData;

	}

	ionViewWillEnter() {
		this.getData();
	}

	initializeDateForm() {
		let date = new Date();
		this.maxFromDate = date.setDate(this.todaysDate.getDate());
		this.maxFromDate = this.datePipe.transform(this.maxFromDate, "YYYY-MM-dd");
	}

	onDateChange(datetime) {
		datetime.cancel(true);
	}


	getData(isInfiniteScroll?, filter?: any) {
		this.isLoading = true;
		const params = {
			page: this.currentPage,
			size: this.itemsPerPage,
			...filter
		}
		this.subscription.add(
			this.dataService.get(URLS.pressList, params).subscribe(data => {
				if (data['code'] == 200) {
					if (data['data']) {
						if (!isInfiniteScroll) {
							this.newsData = data['data']['result'];
							this.newsData.forEach(element => {
								element.media_type = element.media_url ? element.media_url.substring(element.media_url.lastIndexOf('.') + 1) : null;
							});
							this.enableNext = data['data']['enable_next'];
							this.count = data['data']['total_count'] ? data['data']['total_count'] : 0;
						}
						if (isInfiniteScroll) {
							let pressGridDataInfinte = data['data']['result'];
							this.newsData = this.newsData.concat(pressGridDataInfinte);
							this.newsData.forEach(element => {
								element.media_type = element.media_url ? element.media_url.substring(element.media_url.lastIndexOf('.') + 1) : null;
							});
							this.enableNext = data['data']['enable_next'];
							this.count = data['data']['total_count'] ? data['data']['total_count'] : 0;
						}

					}

					this.isLoading = false;
				}
				else {
					this.isLoading = false;
					this.newsData = [];
				}
			}, error => {
				this.isLoading = false;
				this.newsData = [];
			})
		)
	}

	search() {
		if (!this.searchText) return;
		this.filterObj["search"] = this.searchText;
		this.currentPage = 1;
		this.getData(this.filterObj);
	}

	clearSearch() {
		this.searchText = null;
		delete this.filterObj["search"];
		this.currentPage = 1;
		this.getData(this.filterObj);
	}

	goBack() {
		this.navCtrl.back({ animated: false });
	}

	selectSorting($event) {

	}

	reset() {

	}

	goToDetails(data) {
		console.log(data);

		this.navCtrl.navigateForward('/' + this.currentLang + '/news-details/' +
			this.commonService.trimUrl(data.content_title_en) + "/" + data.press_release_document_id,
			{
				animated: false, "state":
				{
					"breadcrumb": data.content_title_en,
					"url": '/news-details/' + this.commonService.trimUrl(data.content_title_en),
					id: data.press_release_document_id,
				}
			});

	}
}
