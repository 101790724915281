<div class="two-fa-container">

	<div class="modal-close-mobile text-right" (click)="closeModal()">
		<img src="assets/icon/modal-close-mobile.svg" alt="Jazeera">
	</div>
	<div class="header-line">
	</div>
	<div class="two-fa-header pb-10">
		{{'filterBy' | translate}}
	</div>
	<div class="filter-by-options ph-20">
		<form [formGroup]="dateRange">
			<div class="filter-section">

				<!-- ---------------------- post_date filter -------------- -->

				<div class="filter-header">
					<div class="status-label">
						<ion-label
							[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">{{'statementComponent.post_date' |
							translate}}</ion-label>
					</div>
					<ion-row>
						<div class="center-select-date ">

							<div class="input-div-container">
								<div class="from-date-div">
									<ion-item class="from-date-class"
										[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'" id="fromDateId">
										<ion-input value="{{ fromDate | date: 'MMM dd, YYYY'}}" placeholder="{{'from'
							| translate}}" class="ion-text-start"></ion-input>
										<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end"
											alt="Jazeera"></ion-icon>
										<ion-popover trigger="fromDateId" size="cover" id="datePopover">
											<ng-template>
												<ion-datetime presentation="date" [min]="'2000-01-01'"
													[max]="toDate ? toDate : maxFromDate" [(ngModel)]="fromDate"
													formControlName="from" #fromdate
													(ionChange)="onDateChange(fromdate)"><span slot="title">{{"from"
														| translate}}</span></ion-datetime>
											</ng-template>
										</ion-popover>
									</ion-item>
								</div>
							</div>

							<div class="input-div-container">
								<div class="to-date-div">
									<ion-item class="to-date-class"
										[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'" id="toDateId">
										<ion-input value="{{ toDate | date: 'MMM dd, YYYY' }}" placeholder="{{'to'
											| translate}}" class="ion-text-start"></ion-input>
										<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end"
											alt="Jazeera"></ion-icon>
										<ion-popover trigger="toDateId" size="cover" id="datePopover">
											<ng-template>
												<ion-datetime presentation="date"
													[min]="fromDate ? fromDate : '2000-01-01'" [max]="maxDate"
													[(ngModel)]="toDate" formControlName="to" #todate
													(ionChange)="onDateChange(todate)"><span slot="title">{{"to"
														| translate}}</span></ion-datetime>
											</ng-template>
										</ion-popover>
									</ion-item>
								</div>
							</div>
						</div>
					</ion-row>
				</div>

				<!-- ---------------------- doc_date filter -------------- -->

				<div class="filter-header">
					<div class="status-label">
						<ion-label
							[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">{{'statementComponent.doc_date'|translate}}*</ion-label>
					</div>

					<div>
						<ion-row>
							<div class="center-select-date ">
								<div class="input-div-container">
									<div class="from-date-div">
										<ion-item class="from-date-class"
											[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'"
											id="docFromDateId">
											<ion-input value="{{ docFromDate | date: 'MMM dd, YYYY'}}" placeholder="{{'from'
									| translate}}" class="ion-text-start"></ion-input>
											<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end"
												alt="Jazeera"></ion-icon>
											<ion-popover trigger="docFromDateId" size="cover" id="datePopover">
												<ng-template>
													<ion-datetime presentation="date" [min]="'2000-01-01'"
														[max]="docToDate ? docToDate : maxFromDate"
														[(ngModel)]="docFromDate" formControlName="docfrom" #docfromDate
														(ionChange)="onDateChange(docfromDate)"><span
															slot="title">{{"from"
															| translate}}</span></ion-datetime>
												</ng-template>
											</ion-popover>
										</ion-item>
									</div>
								</div>

								<div class="input-div-container">
									<div class="to-date-div">
										<ion-item class="to-date-class"
											[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'" id="docToDateId">
											<ion-input value="{{docToDate | date: 'MMM dd, YYYY' }}" placeholder="{{'to'
													| translate}}" class="ion-text-start"></ion-input>
											<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end"
												alt="Jazeera"></ion-icon>
											<ion-popover trigger="docToDateId" size="cover" id="datePopover">
												<ng-template>
													<ion-datetime presentation="date"
														[min]="docFromDate ? docFromDate : '2000-01-01'" [max]="maxDate"
														[(ngModel)]="docToDate" formControlName="docto" #doctoDate
														(ionChange)="onDateChange(doctoDate)"><span slot="title">{{"to"
															| translate}}</span></ion-datetime>
												</ng-template>
											</ion-popover>
										</ion-item>
									</div>
								</div>
							</div>
						</ion-row>
					</div>
				</div>

				<!-- ---------------------- doc_no_filter -------------- -->
				<div class="filter-header mr-16 center-select-date">
					<div>
						<div class="status-label">
							<ion-label
								[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">{{'statementComponent.doc_no'
								|
								translate}}</ion-label>
						</div>
						<ion-row>
							<div class="input-div-container">
								<div class="from-date-div">
									<ion-item class="from-date-class"
										[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">
										<ion-input [(ngModel)]="doc_no_filter" [ngModelOptions]="{standalone: true}"
											placeholder="{{'statementComponent.doc_no'| translate}}"
											appDigitDecimalNumber class="ion-text-start"></ion-input>
									</ion-item>
								</div>
							</div>
						</ion-row>
					</div>
					<div>
						<div class="status-label">
							<ion-label
								[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">{{'statementComponent.credit'
								|
								translate}}</ion-label>
						</div>
						<ion-row>
							<div class="input-div-container">
								<div class="from-date-div">
									<ion-item class="from-date-class"
										[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">
										<ion-input [(ngModel)]="credit_filter" [ngModelOptions]="{standalone: true}"
											placeholder="{{'statementComponent.credit'| translate}}"
											appTwoDigitDecimalNumber class="ion-text-start"></ion-input>
									</ion-item>
								</div>
							</div>
						</ion-row>
					</div>
				</div>


				<!-- ---------------------- debit_filter -------------- -->
				<div class="filter-header">
					<div class="status-label">
						<ion-label
							[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">{{'statementComponent.debit' |
							translate}}</ion-label>
					</div>
					<ion-row>
						<div class="width-100">
							<div class="from-date-div">
								<ion-item class="from-date-class"
									[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">
									<ion-input [(ngModel)]="debit_filter" [ngModelOptions]="{standalone: true}"
										appTwoDigitDecimalNumber placeholder="{{'statementComponent.debit'| translate}}"
										class="ion-text-start"></ion-input>
								</ion-item>
							</div>
						</div>
					</ion-row>
				</div>



			</div>
		</form>
	</div>

	<ion-footer>
		<div class="footer-button-container ph-20">
			<div class="apply-filter-btn clear-btn">
				<ion-button fill="clear" class="red-btn" (click)="clear()">{{'clear' | translate }}</ion-button>
			</div>
			<div class="apply-filter-btn">
				<ion-button fill="clear" class="red-btn" (click)="apply()">{{'apply' | translate }}</ion-button>
			</div>
		</div>
	</ion-footer>
</div>