import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/services/api-data.service';
import { CartService } from 'src/app/services/cart.service';
import { CommonService } from 'src/app/services/common.service';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { ModalService } from 'src/app/services/modal.service';
import { QuoteService } from 'src/app/services/quote.service';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
	selector: 'app-bank-transfer',
	templateUrl: './bank-transfer.component.html',
	styleUrls: ['./bank-transfer.component.scss'],
})
export class BankTranferComponent implements OnInit {

	mobileView = false;
	isLoading = false;
	@Input() paymentInfo: any;
	private subscription: Subscription = new Subscription();

	bankTransferForm: FormGroup;

	// Bank list for the select dropdown
	banks = [
		{ id: 1, name: 'Bank A' },
		{ id: 2, name: 'Bank B' },
		{ id: 3, name: 'Bank C' },
		{ id: 4, name: 'Bank D' },
	];
	payment_method: any;

	constructor(private formBuilder: FormBuilder,
		private alertController: AlertController,
		public commonService: CommonService,
		public modalService: ModalService,
		private dataService: ApiService,
		private toastService: ToastService,
		public getPlatformService: GetPlatformService,
		private translateConfigService: TranslateConfigService,
		public navCtrl: NavController,
		public router: Router,
		public cartService: CartService,
		private translate: TranslateService,
		public quoteService: QuoteService,
	) {
		// Initialize the form group with controls and validation
		this.bankTransferForm = this.formBuilder.group({
			bankName: ['', Validators.required],
			bank: ['', Validators.required],
			accountNumber: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]], // Assuming account number has 10 digits
			iban: ['', [Validators.required, Validators.pattern(/^[A-Za-z0-9]{15,34}$/)]], // IBAN validation regex
			receiptNo: ['', Validators.required],
			acknowledgmentFile: [null, Validators.required],
			notes: ['', Validators.maxLength(200)]
		});
	}


	ngOnInit() {
		this.payment_method = this.commonService.localStorageGet('selectedPaymentMethod') ? JSON.parse(this.commonService.localStorageGet('selectedPaymentMethod')) : null;
	}


	onFileChange(event: any) {
		const file = event.target.files[0];
		if (file) {
			this.bankTransferForm.patchValue({
				acknowledgmentFile: file
			});
		}
	}


	// Submit the payment form
	async submitPayment() {
		if (this.bankTransferForm.invalid) {
			return;
		}

		this.isLoading = true;
		let shipping_id = this.commonService.localStorageGet('cart_shipping_address');
		let billing_id = this.commonService.localStorageGet('cart_billing_address');

		const params = {
			"shipping_address_id": +shipping_id,
			"billing_address_id": +billing_id,
		}
		if (this.payment_method && this.payment_method.payment_type_id) {
			params['payment_type'] = this.payment_method.payment_type_id
		}

		let discount_id = this.commonService.localStorageGet('discount_id');
		let discount_code = this.commonService.localStorageGet('discount_code');

		if ((discount_id || discount_code) && (discount_code != "null" ||
			discount_id != "null") && (discount_code != null || discount_id != null)) {
			if (discount_id && discount_id != "null" && discount_id != null) {
				params['discount_code_id'] = discount_id;
			}
			if (discount_code && discount_code != "null" && discount_code != null) {
				params['discount_code'] = discount_code;
			}
		}

		const cardNumber = this.bankTransferForm.get('cardNumber').value.trim().replace(/\s+/g, '');  // Remove spaces
		params["card_number"] = cardNumber;
		params["expiration_month"] = +this.bankTransferForm.get('expiryMonth').value;
		params["expiration_year"] = +this.bankTransferForm.get('expiryYear').value;
		params["cvv"] = +this.bankTransferForm.get('cvv').value;

		this.subscription.add(
			this.dataService.post(URLS.checkoutInvoice, params).subscribe(async data => {
				if (data && data['code'] == 200) {
					this.isLoading = false;
					this.commonService.localStorageRemove('fromCartHeader');
					if (data && data['data']) {
						if (this.commonService.localStorageGet('tempQuoteId')) {
							await this.quoteService.updateQuote(this.commonService.localStorageGet('tempQuoteId'), null, null, null, data['data'].invoice_id).then(data2 => {
								if (data2 && data2['code'] == 200) {
									this.commonService.localStorageRemove('tempQuoteId');
								}
							}).catch(error => {
								console.log(error);
							});
						}

						// window.location.href = data['data'].paymentUrl;


						this.router.navigate(['' + this.commonService.currentLang + '/home/order-payment'], {
							queryParams: {
								"orderId": data['data'].order_no ? data['data'].order_no : 0,
								"id": data['data'].invoice_id ? data['data'].invoice_id : 0,
								"payment_url": data['data'].payment_url ? data['data'].payment_url : null,
								"transaction_id": data['data'].transaction_id ? data['data'].transaction_id : null,
							}
						});
					}


				} else {
					this.isLoading = false;
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				this.isLoading = false;
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

}
