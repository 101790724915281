<ion-header class="mobile-visibility app-header">
	<ion-toolbar mode="md">
		<img src="assets/icon/leftArrow.svg" class="back-icon back-img" alt="Jazeera" slot="start" (click)="goBack()">
		<ion-title mode="ios">
			<div class="order-header">
				{{'ordersPage.Orders' | translate}}
			</div>
		</ion-title>
		<div class="section-block header-filter-block" slot="end" (click)="openFilterByModal()" *ngIf="isLoggedIn">
			<div class="filter-icon">
				<img src="./assets/icon/filterIcon.svg" alt="Jazeera">
			</div>
		</div>
	</ion-toolbar>

	<div class="searchbar-container mh-20 mv-12" *ngIf="isLoggedIn && false">
		<app-search-bar fromOrdersList="true" (searchClicked)="search($event)"
			(clearClicked)="clearSearch($event)"></app-search-bar>
	</div>

</ion-header>
<app-loader *ngIf="isLoading"></app-loader>

<ion-content [fullscreen]="true">
	<ion-refresher *ngIf="!getPlatformService.isDesktop" slot="fixed" refreshingSpinner="circular"
		(ionRefresh)="handleRefresh($event)">
		<ion-refresher-content></ion-refresher-content>
	</ion-refresher>
	<div class="orders-listing mh-20" *ngIf="isLoggedIn && !isLoading">
		<div class="mobile-view-listing" *ngIf="orderList.length">
			<div class="order-container">
				<ion-grid class="p-0 m-0">
					<ion-row class="order-card" *ngFor="let order of orderList;index as i"
						(click)="openOrderDetailsModal(order.invoice_id)">
						<ion-col size="11">
							<ion-grid>
								<ion-row>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"ordersComponent.order_id" | translate}}</div>
										<div class="grid-values">{{order.order_no}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"ordersComponent.order_total" | translate}}</div>
										<div class="grid-values">{{commonService.currencySymbol}}{{order.order_total ?(
											order.order_total | number): '0'}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"ordersComponent.date" | translate}}</div>
										<div class="grid-values">{{order.date | date
											:'MMM dd, YYYY'}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"ordersComponent.countOfItems" |
											translate}}</div>
										<div class="grid-values">{{order.items_count}}</div>
										<div class="grid-values">
											<ion-button fill="clear" *ngIf="false" class="order-status-btn"
												[ngClass]="orderStatus(order)"
												(click)="order.order_status == 'DELIVERED' && order.invoice_url ? downloadInvoice($event,order.invoice_url) : null">
												{{order.order_status== 'CANCELLED'? ('cancelled' | translate) :
												order.order_status == 'DISPATCHED' ?
												('dispatched' |translate) :order.order_status == 'DELIVERED' ?
												('delivered' |translate):('confirmed' |translate)}}

												<img *ngIf="order.order_status == 'DELIVERED' && order.invoice_url"
													alt="Jazeera" class="ml-10" src="./assets/icon/fi_download.svg">

											</ion-button>


										</div>
									</ion-col>
								</ion-row>
							</ion-grid>
						</ion-col>
						<ion-col class="mobile-right-arrow" size="1">
							<div [ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
								<img src="./assets/icon/rightArrow.svg" alt="righta-arrow">
							</div>
						</ion-col>
					</ion-row>
					<ion-infinite-scroll threshold="400px" (ionInfinite)="loadData($event)">
						<ion-infinite-scroll-content *ngIf="enableNext" loadingSpinner="circular"
							loadingText="{{'loadMoreOrders' | translate}}">
						</ion-infinite-scroll-content>
					</ion-infinite-scroll>
				</ion-grid>
			</div>
		</div>

		<div *ngIf="!orderList.length" class="no-orders">
			<img src="./assets/icon/no-order.svg" alt="Jazeera" class="mb-30" />
			<div class="mb-20">
				<p class="label-3 color-app-black ">{{'noOrdersYet' | translate}}</p>
			</div>
		</div>


	</div>
	<app-login-prompt *ngIf="!isLoggedIn && !isLoading"></app-login-prompt>
</ion-content>