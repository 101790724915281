import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from "@sentry/angular";
import { JwtInterceptor } from './_helpers/http-interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './common/shared.module';
import { TranslateConfigService } from './services/translate-config.service';


// isSupported().then((check) => {
// 	if (check) {
// 		const app = initializeApp(environment.firebaseConfig);
// 		const auth = getAuth(app);
// 		const analytics = getAnalytics(app);
// 		const firestore = getFirestore(app);
// 		// Your additional logic here
// 	} else {
// 		console.error("Firebase features not supported in this environment.");
// 	}
// });


@NgModule({
	declarations: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	bootstrap: [AppComponent],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		IonicModule.forRoot({ animated: false }),
		AppRoutingModule,
		SharedModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (HttpLoaderFactory),
				deps: [HttpClient]
			}
		})],
	providers: [
		TranslateConfigService,
		TranslateService,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler(),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => { },
			deps: [Sentry.TraceService],
			multi: true,
		},
		provideHttpClient(withInterceptorsFromDi())
	]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, "./assets/language/", ".json");
}

