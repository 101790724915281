import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, NavController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { ModalService } from 'src/app/services/modal.service';
import { ToastService } from 'src/app/services/toast.service';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { TwoFactorAuthenticationComponent } from '../two-factor-authentication/two-factor-authentication.component';
import { URLS } from './../../_config/api.config';
import { TranslateConfigService } from './../../services/translate-config.service';


@Component({
	selector: 'app-setting',
	templateUrl: './setting.component.html',
	styleUrls: ['./setting.component.scss'],
	providers: [NavParams]
})
export class SettingComponent implements OnInit {

	public isLoading = false;
	private subscription: Subscription = new Subscription();
	selectedLanguage: any;
	languageArray: any = [
		{ code: 'en', title: 'English', dir: 'ltr', img: '/assets/language/en.webp' },
		{ code: 'ar', title: 'arabic', dir: 'rtl', img: '/assets/language/ar.webp' }]

	locationArray: any = [
		{ code: 'en', title: 'USA' },
		{ code: 'ar', title: 'UK' },
		{ code: 'ar', title: 'India' },
		{ code: 'ar', title: 'Soudi Arabia' },
	]

	public locationData: any = [];
	public locationSelect = {
		size: 'cover',
		side: 'bottom',
		alignment: 'center',
		cssClass: 'location-popover'
	}
	inerfaceOption = { cssClass: 'language-dropdown' };
	isTwoFaEnabled: boolean = false;
	twoFaSettingsConformation: boolean = false;
	selectedLocation: any;
	selectedFlag: any;
	isLocationSelected: boolean = false;
	componentData = [
		// {
		// 	section_name: 'settingComponent.notifications',
		// 	section_btn: '',
		// 	section_content: "",
		// 	route: '',
		// 	id: '0'
		// },
		{
			section_name: 'settingComponent.language',
			section_btn: '',
			section_content: "",
			route: '',
			id: '1'
		},
		{
			section_name: 'settingComponent.location',
			section_btn: '',
			section_content: '',
			route: '',
			id: '2'
		},
		// {
		// 	section_name: '',
		// 	section_btn: '',
		// 	section_content: '',
		// 	route: '',
		// 	id: '3'
		// }
	];

	notificationSetting = []
	noData: boolean = false;
	isLoggedIn = false;
	constructor(private modalService: ModalService,
		public commonService: CommonService,
		private navParams: NavParams,
		private dataService: ApiService,
		private toastService: ToastService,
		private translateConfigService: TranslateConfigService,
		public translate: TranslateService,
		private alertController: AlertController,
		public getPlatformService: GetPlatformService,
		public navCtrl: NavController,
		private router: Router) {
		const token = this.commonService.localStorageGet('accessToken');
		if (token) { this.isLoggedIn = true; } else this.isLoggedIn = false;
		this.subscription = this.translateConfigService.selectedLocation.subscribe(data => {
			let selectedLanguage = this.translateConfigService.getDefaultLanguage();
			for (let i = 0; i < this.languageArray.length; i++) {
				if (selectedLanguage == this.languageArray[i].code) {
					this.selectedLanguage = this.languageArray[i];
				}
			}
		})

	}

	ngOnInit() {
		if (this.isLoggedIn) this.loadAllData(); else this.getSelectedLanguage();
	}

	ionViewWillEnter() {
		if (this.isLoggedIn) this.loadAllData(); else this.getSelectedLanguage();

	}

	getSelectedLanguage() {
		let selectedLanguage = this.translateConfigService.getDefaultLanguage();
		for (let i = 0; i < this.languageArray.length; i++) {
			if (selectedLanguage == this.languageArray[i].code) {
				this.selectedLanguage = this.languageArray[i];
			}
		}
	}
	loadAllData() {
		let promiseArray = [
			this.getCustomerDetails(),
			this.getLocation(),
			this.getNotifications(),
			this.getLocationDetails(),
			this.getSelectedLanguage(),
		];

		this.isLoading = true;
		Promise.all(promiseArray).then(data => {
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
		});
	}

	getCustomerDetails() {
		this.isLoading = true;

		this.subscription.add(
			this.dataService.get(URLS.getDefaultAddressList).subscribe(data => {
				if (data['data'] && data['code'] == 200) {
					this.isLoading = false;
					this.commonService.twoFaEnabled = data['data']['is_2fa_enabled'];
					this.commonService.localStorageSet("is_2fa_enabled", this.commonService.twoFaEnabled);

				} else {
					this.isLoading = false;
				}
			}, error => {
				this.isLoading = false;
			})
		)
	}

	getLocationDetails() {
		this.isLoading = true;
		this.subscription.add(
			this.dataService.get(URLS.getCustomerDetails).subscribe(data => {
				if (data['code'] == 200) {
					this.isLoading = false;
					if (data['data']) {
						this.commonService.localStorageSet('userInfo', JSON.stringify(data['data']));
					}
					if (data['data']['city_id']) {
						let address = data['data']['city_id'];
						this.selectedLocation = address;
					}

				} else {
					this.isLoading = false;
				}
			}, error => {
				this.isLoading = false;
			})
		)
	}
	getLocation() {
		const params = {
			dropdown: true
		}
		this.subscription.add(
			this.dataService.get(URLS.getLocation, params).subscribe(data => {
				if (data && data['code'] == 200) {
					this.locationData = data['data']['result'];
				}
			}, error => {
			})
		)
	}

	getNotifications() {
		this.isLoading = true;
		this.noData = false;
		const params = {
			dropdown: true
		}
		this.subscription.add(
			this.dataService.get(URLS.notificationSettingList, params).subscribe(data => {
				if (data && data['code'] == 200) {
					this.isLoading = false;
					let list = data['data'] && data['data']['notificationDetails'] && data['data']['notificationDetails'].length ? data['data']['notificationDetails'] : [];
					this.notificationSetting = [];
					list.forEach(element => {
						let obj = {
							id: element.notification_type_id,
							title: element.notification_type_en ? element.notification_type_en : '',
							title_ar: element.notification_type_ar ? element.notification_type_ar : '',
							is_active: element.is_enabled ? true : false,
							sequence: element.sequence
						}
						this.notificationSetting.push(obj);
					});
					if (this.notificationSetting.length < 1) {
						this.noData = true;
					}
				}
			}, error => {
				this.isLoading = false;
				this.noData = false;
			})
		)
	}

	notificationToggle(notification, notificationObj) {
		this.subscription.add(
			this.dataService.put(URLS.updateNotificationStatus, notification).subscribe(data => {
				if (data && data['code'] == 200) {
					if (notificationObj) {
						notificationObj.is_active = !notificationObj.is_active;
					}
				} else {
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}


	async presentAlert(data, input?) {
		let deactivateMsg: any;
		let activateMsg: any;
		if (input) {
			this.commonService.twoFaEnabled = !this.commonService.twoFaEnabled;
			deactivateMsg = this.commonService.domDirectionLTR ? "You want to deactivate 2FA authentication." : "تريد إلغاء تنشيط مصادقة 2FA.";
			activateMsg = this.commonService.domDirectionLTR ? "You want to activate 2FA authentication" : "تريد تنشيط مصادقة 2FA.";
		} else {
			data.is_active = !data.is_active;
			deactivateMsg = this.commonService.domDirectionLTR ? "You want to deactivate notification." : "تريد إلغاء تنشيط الإخطار.";
			activateMsg = this.commonService.domDirectionLTR ? "You want to activate notification" : "تريد تفعيل الإخطار";
		}
		const alert = await this.alertController.create({
			header: this.commonService.domDirectionLTR ? 'Are you sure?' : 'هل أنت متأكد؟',
			message: input ? this.commonService.twoFaEnabled ? deactivateMsg : activateMsg : data.is_active ? deactivateMsg : activateMsg,
			buttons: [
				{
					text: this.translate.instant("no"),
					role: 'cancel',
					handler: () => {

					},
				},
				{
					text: this.translate.instant("yes"),
					role: 'confirm',
					handler: () => {
						let isActive;
						if (input) {
							this.twoFaToggle();
						} else {

							if (data.is_active) {
								isActive = false;
							} else {
								isActive = true;
							}
							let params = {
								is_enabled: isActive,
								notification_type_id: data.id,

							};
							this.notificationToggle(params, !input ? data : false);
						}
					},
				},
			],
		});

		await alert.present();
		const { role } = await alert.onDidDismiss();
	}
	goBack() {
		this.navCtrl.back({ animated: false });
	}

	twoFaToggle() {
		const params = {
			"is_2fa_enabled": !this.commonService.twoFaEnabled
		}
		this.isLoading = true;
		this.subscription.add(
			this.dataService.put(URLS.updateTwoFaSettings, params).subscribe(data => {
				if (data['data'] && data['code'] == 200) {
					this.isTwoFaEnabled = !this.commonService.twoFaEnabled;
					this.commonService.localStorageSet("is_2fa_enabled", this.isTwoFaEnabled);
					this.commonService.twoFaEnabled = this.isTwoFaEnabled;
					if (this.isTwoFaEnabled) {
						setTimeout(() => {
							let options: any = {
								id: "twoFaModal",
								backdropDismiss: false,
								componentProps: {
									twoFaSettingsConformation: true
								}
							};
							options['component'] = TwoFactorAuthenticationComponent;
							this.modalService.openModal(options)
						}, 600)
					}
					this.twoFaSettingsConformation = this.commonService.localStorageGet("is_2fa_enabled") == "true" ? true : false;
					this.isLoading = false;
				} else {
					setTimeout(() => {
						this.isTwoFaEnabled = !this.isTwoFaEnabled;
					});
					this.isLoading = false;
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				setTimeout(() => {
					this.isTwoFaEnabled = !this.isTwoFaEnabled;
				});
				this.isLoading = false;
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}


	async languageChanged(event) {
		await this.translateConfigService.setLanguage(this.selectedLanguage.code);
		await this.translateConfigService.setDir(this.selectedLanguage.dir);
		let selectedLanguage = this.commonService.localStorageGet('locale');
		const params = {
			"language": selectedLanguage.toUpperCase(),
		}
		if (this.isLoggedIn) {
			this.subscription.add(
				this.dataService.post(URLS.languageChange, params).subscribe(data => {
					if (data['code'] == 200) {
						// this.translateConfigService.locationChange();
					} else {
						this.toastService.displayToast(data['message'], null, 'danger');
					}
				}, error => {

					this.toastService.displayToast(error['message'], null, 'danger');
				})
			)
		}
	}

	locationChanged(event) {
		this.selectedLocation = event.target.value;
		if (this.isLocationSelected) {
			this.registerLocationChange()
		}
		this.isLocationSelected = true;

	}

	registerLocationChange() {
		const params = new FormData();
		if (this.selectedLocation) {
			params.append("city_id", this.selectedLocation)
		}

		this.isLoading = true;

		this.subscription.add(this.dataService.put(URLS.updateCustomerDetails, params).subscribe(data => {
			if (data['code'] == 200) {
				this.commonService.setEvent('updateProfile', null);
			} else {
				this.toastService.displayToast(data['message'], null, 'danger');
			}
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
			this.toastService.displayToast(error['message'], null, 'danger');
		})
		)


	}

}
