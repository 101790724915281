import { Location } from "@angular/common";
import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NavController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { URLS } from "../../_config/api.config";
import { FilterComponent } from "../../components/filter/filter.component";
import { SortByComponent } from "../../components/sort-by/sort-by.component";
import { ApiService } from "../../services/api-data.service";
import { CommonService } from "../../services/common.service";
import { GetPlatformService } from "../../services/get-platform.service";
import { ModalService } from "../../services/modal.service";
import { ToastService } from "../../services/toast.service";
import { TranslateConfigService } from "../../services/translate-config.service";
import { WishlistService } from "../../services/wishlist.service";


@Component({
	selector: "app-product-listing",
	templateUrl: "./product-listing.page.html",
	styleUrls: ["./product-listing.page.scss"],
})
export class ProductListingPage implements OnInit, OnDestroy {
	@ViewChild('filterContainer', { read: ViewContainerRef }) filterContainer: ViewContainerRef;
	productName: any;
	backupProductName: any;
	private subscription: Subscription = new Subscription();
	private sortBySubscription: Subscription = new Subscription();
	public wishlistData: any;
	public isLoggedIn: boolean = false;
	public isBannerLoaded: boolean = false;
	public productData = [];
	page = 1;
	size = 9;
	subClassListData: any[] = [];
	regionListData: any[] = [];
	brandListData: any[] = [];
	supplierListData: any[] = [];
	filterparams: any = {
		page: 1,
		size: 9,
		sort_by: "RELEVANCE",
	};
	inerfaceOption = { cssClass: "sortby-dropdown" };
	isLoading: boolean = true;
	priceData: any;
	enableNext: boolean = false;
	sub_class_id: any;
	class_id: any;
	filtersubClass: any[] = [];
	filterRegion: any[] = [];
	filterBrand: any[] = [];
	filterSupplier: any[] = [];
	totalDataCount: number;
	addMoreProduct: any[] = [];
	filterbyData: any[] = [];
	filterSubClassData: any[] = [];
	banner_img: any;
	banner_title: any;
	banner_description: any;

	filterSubClass: any[] = [];
	filterRegionData: any[] = [];
	filterSupplierData: any[] = [];
	filterBrandData: any[] = [];
	public apiUrl: any;
	public filterList: any = {
		page: 1,
		size: 9,
	};
	public bannerId: any;

	public productListType: any;
	public selectedSortBy: any = "relevance";
	public filterCount: any = "1";
	bannerData: any[] = [];
	minprice: any = 0;
	maxprice: any = 0;
	public classListData = [];
	filterComponentLoaded = false;
	public display_sub_class_name_en: any;
	public display_sub_class_name_ar: any;
	currentLang: any;

	constructor(
		private modalService: ModalService,
		private translateConfigService: TranslateConfigService,
		private route: ActivatedRoute,
		private router: Router,
		public commonService: CommonService,
		private dataService: ApiService,
		public getPlatformService: GetPlatformService,
		private navCtrl: NavController,
		public wishlistService: WishlistService,
		private location: Location,
		private toastService: ToastService,
		private componentFactoryResolver: ComponentFactoryResolver,
		public translate: TranslateService,

	) {
		this.currentLang = this.commonService.currentLang;

		this.getPageName();
		this.backupProductName = this.commonService.localStorageGet("backupProductName");
		this.commonService.localStorageSet("banner_img", "/assets/images/slider.svg");
	}

	ngOnInit() {
		if (this.getPlatformService.isDesktop) {
			this.route.params.subscribe(params => {
				this.isLoading = true;
				this.initData();
			});
		} else {
			this.isLoading = true;
			this.initData();
		}

	}

	initData() {
		let promiseArray = [
			this.getPageName(),
			this.getClassList(),
			this.getSubClassList(),
			this.getRegionList(),
			this.getBrandList(),
			this.getSupplierList(),
		];

		this.backupProductName = this.commonService.localStorageGet("backupProductName")

		this.isLoggedIn = this.commonService.localStorageGet("accessToken")
			? true
			: false;
		if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state) {
			let state = this.router.getCurrentNavigation().extras.state;
			if (state.id) {
				this.commonService.localStorageSet(
					"sub_class_id",
					this.router.getCurrentNavigation().extras.state.id
				);
				this.commonService.localStorageRemove("banner_id");
			}
			if (state.product_type) {
				this.commonService.localStorageRemove("sub_class_id");
				this.commonService.localStorageRemove("banner_id");
				this.productListType =
					this.router.getCurrentNavigation().extras.state.product_type;
				this.filterCount = "1";
			}
			if (state.banner_data) {
				this.commonService.localStorageRemove("sub_class_id");
				this.commonService.localStorageSet("banner_id", state.banner_data);
				if (state.banner_title) {
					this.commonService.localStorageSet(
						"banner_title",
						state.banner_title
					);
				}
				if (state.banner_description) {
					this.commonService.localStorageSet(
						"banner_description",
						state.banner_description
					);
				}
				this.bannerId = this.router.getCurrentNavigation().extras.state.banner_data;
			}
		} else {
			this.route.paramMap.subscribe(params => {
				this.sub_class_id = +params.get('id');
				this.banner_title = params.get('sub_class-name') ? params.get('sub_class-name').replace(/-/g, ' ') : null;
				if (this.sub_class_id) {
					this.commonService.localStorageSet("sub_class_id", this.sub_class_id)
				} else {
					this.commonService.localStorageRemove("sub_class_id");
				}
			});

		}
		let bannerDescription =
			this.commonService.localStorageGet("banner_description");
		if (bannerDescription) {
			this.banner_description =
				this.commonService.localStorageGet("banner_description");
		}
		let bannerTitle = this.commonService.localStorageGet("banner_title");
		if (bannerTitle) {
			this.banner_title = this.commonService.localStorageGet("banner_title");
		}
		let bannerImg = this.commonService.localStorageGet("banner_img");
		if (bannerImg) {
			this.banner_img = this.commonService.localStorageGet("banner_img");
		}
		let bannerId = this.commonService.localStorageGet("banner_id");
		if (bannerId) {
			this.bannerId = bannerId;
		}
		let listingUrl = this.router.url;
		if (listingUrl == "/home/product-listing") {
			this.commonService.localStorageRemove("sub_class_id");
			this.isBannerLoaded = true;
		} else {
			this.sub_class_id = this.commonService.localStorageGet("sub_class_id");
		}
		if (this.sub_class_id) {
			if (this.sub_class_id.includes(",")) {
				let sub_class = this.sub_class_id.split(",").map(function (item) {
					return parseInt(item);
				});
				this.filterparams["sub_class_id"] = sub_class;
				this.commonService.localStorageRemove("banner_id");
				this.filterCount = "2";
			} else {
				this.filterparams["sub_class_id"] = [+this.sub_class_id];
				this.commonService.localStorageRemove("banner_id");
				this.filterCount = "2";
			}
		} else {
			delete this.filterparams["sub_class_id"];
			this.filterCount = "1";
		}
		const currentUrl = this.router.url.replace(/^\/[a-zA-Z]{2}/, '');

		this.commonService.localStorageSet("product_url", currentUrl);
		if (!this.getPlatformService.isDesktop) {
			this.sortBySubscription = this.commonService.callSortByData.subscribe(
				(data) => {
					this.sortByModalData(data);
				}
			);
		}

		setTimeout(() => {
			Promise.all(promiseArray).then(
				(data) => {
					if (!this.filterComponentLoaded) {
						this.lazyLoadFilterComponent();
						this.getPriceRange().then(() => {
							this.getProductList("daterange");
							this.isBannerLoaded = true;
						})
					} else {
						this.getPriceRange().then(() => {
							this.getProductList("daterange");
							this.isBannerLoaded = true;
						})
					}
				},
				(error) => {
					console.log(error);
				}

			);
		}, 2000);
	}

	lazyLoadFilterComponent(): void {
		// Lazy load the filter component
		import('../../components/filter/filter.component').then(({ FilterComponent }) => {
			const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FilterComponent);
			const componentRef = this.filterContainer.createComponent(componentFactory);

			componentRef.instance.priceRange = this.priceData;
			componentRef.instance.subClassDatas = this.subClassListData;
			componentRef.instance.classDatas = this.classListData;
			componentRef.instance.regionDatas = this.regionListData;
			componentRef.instance.brandDatas = this.brandListData;
			componentRef.instance.supplierDatas = this.supplierListData;

			componentRef.instance.callFilterData.subscribe(eventData => {
				this.callFilterData(eventData);
			});

			componentRef.instance.filterByData.subscribe(eventData => {
				this.filterByData(eventData);
			});
			this.filterComponentLoaded = true;
		});
	}

	//price range filter list
	getPriceRange() {
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.get(URLS.priceRange).subscribe((data) => {
					if (data["code"] == 200) {
						this.priceData = data["data"];
						this.minprice = data["data"]["min_product_price"]
							? data["data"]["min_product_price"]
							: 0;
						this.maxprice = data["data"]["max_product_price"]
							? data["data"]["max_product_price"]
							: 0;
						resolve(data)
					} else {
						this.priceData = [];
						resolve(data);
					}
				})
			);

		})
	}

	filterByData(event) {
		if (event.filter_by && event.filter_by.length) {
			this.filterbyData = event.filter_by;
			this.filterbyData.forEach((element, index) => {
				if (index === 0) {
					this.productName = this.commonService.domDirectionLTR ? element.label : element.label_ar;
				}
				if (element.type == "subClass") {
					this.banner_img = element.sub_class_image_url ? element.sub_class_image_url : "/assets/images/slider.svg"
					this.banner_title = this.commonService.domDirectionLTR ? element.label : element.label_ar;
				} else {
					this.banner_title = this.commonService.domDirectionLTR ? "Products" : "منتجات";
				}
				this.isBannerLoaded = true;
			});
		}
	}

	// call fun from child with all params
	callFilterData(event) {
		this.filterparams = {
			page: 1,
			size: 9,
		};
		this.filterparams["from_price"] = event.rangeLower ? event.rangeLower : 0;
		this.filterparams["to_price"] = event.rangeUpper ? event.rangeUpper : 0;

		if (event.sub_class) {
			this.filterparams["sub_class_id"] = event.sub_class;
			this.filterSubClassData = event.sub_class_data;
			this.commonService.localStorageSet("sub_class_id", event.sub_class);
		} else {
			this.productName = this.commonService.domDirectionLTR ? "Products" : "منتجات";
			this.commonService.localStorageSet("backupProductName", "المنتجات")
			this.backupProductName = "المنتجات";
		}
		if (event.region) {
			this.filterparams["region_id"] = event.region;
		}
		if (event.brand) {
			this.filterparams["brand_id"] = event.brand;
		}
		if (event.supplier) {
			this.filterparams["supplier_id"] = event.supplier;
		}
		if (event.class) {
			this.filterparams["class_id"] = event.class;
		}
		this.getProductList();
	}

	loadMoreData() {
		if (this.enableNext) {
			if (this.productListType) {
				switch (this.productListType) {
					case "FEATURED":
						this.apiUrl = URLS.getFeatureProductsData;
						break;

					case "RECOMMENDED":
						this.apiUrl = URLS.getRecommendedProducts;
						break;

					case "BESTSELLING":
						this.apiUrl = URLS.getBestSellingProducts;
						break;
				}
				this.filterList.page = this.filterList.page + 1;
				this.filterList.size = 9;
				this.subscription.add(
					this.dataService.post(this.apiUrl, this.filterList).subscribe(
						(data) => {
							if (data["code"] == 200) {
								this.totalDataCount = data["data"]["total_count"];
								if (this.productListType == "RECOMMENDED") {
									this.addMoreProduct = data["data"];
								} else {
									this.addMoreProduct = data["data"]["result"];
								}
								this.productData = this.productData.concat(this.addMoreProduct);
								this.enableNext = data["data"]["enable_next"];
								this.isLoading = false;
							}
						},
						(error) => {
							if (error.code == 500) {
								this.isLoading = false;
							}
						}
					)
				);
			} else {
				this.filterparams.page = this.filterparams.page + 1;
				this.filterparams.size = 9;
				this.subscription.add(
					this.dataService.post(URLS.productlist, this.filterparams).subscribe(
						(data) => {
							if (data["code"] == 200) {
								this.totalDataCount = data["data"]["total_count"];
								this.addMoreProduct = data["data"]["result"];
								this.productData = this.productData.concat(this.addMoreProduct);
								this.enableNext = data["data"]["enable_next"];
								this.isLoading = false;
							} else {
								this.productData = [];
								this.isLoading = false;
							}
						},
						(error) => {
							if (error.code == 500) {
								this.productData = [];
								this.isLoading = false;
							}
						}
					)
				);
			}
		}
	}

	loadData(event) {
		setTimeout(() => {
			if (this.enableNext) {
				this.loadMoreData();
				event.target.complete();
			}
		}, 1500);
	}

	selectSorting(event) {
		this.filterparams["sort_by"] = event["detail"]["value"];
		if (event["detail"]["value"] == "PRICE_ASC") {
			this.filterparams["sort_by"] = "PRICE";
			this.filterparams["sort_type"] = "ASC";
		}
		if (event["detail"]["value"] == "PRICE_DESC") {
			this.filterparams["sort_by"] = "PRICE";
			this.filterparams["sort_type"] = "DESC";
		}
		if (event["detail"]["value"] == "A-Z") {
			this.filterparams["sort_by"] = "A-Z";
			this.filterparams["sort_type"] = "ASC";
		}
		if (event["detail"]["value"] == "Z-A") {
			this.filterparams["sort_by"] = "Z-A";
			this.filterparams["sort_type"] = "DESC";
		}
		this.filterparams.page = 1;
		this.getProductList();
	}

	sortByModalData(data?) {
		if (data && data.sort_by_params) {
			data.sort_by_params.forEach((element) => {
				if (element.checked) {
					if (element.value == "PRICE1") {
						this.filterparams["sort_by"] = "PRICE";
						this.filterparams["sort_type"] = "ASC";
					} else if (element.value == "PRICE2") {
						this.filterparams["sort_by"] = "PRICE";
						this.filterparams["sort_type"] = "DESC";
					} else if (element.value == "A-Z") {
						this.filterparams["sort_by"] = "A-Z";
						this.filterparams["sort_type"] = "ASC";
					} else if (element.value == "Z-A") {
						this.filterparams["sort_by"] = "Z-A";
						this.filterparams["sort_type"] = "DESC";
					} else {
						this.filterparams["sort_by"] = element.value;
						delete this.filterparams["sort_type"];
					}
				}
			});
		}
		this.filterparams.page = 1;
		this.getProductList();
	}

	//product filter list
	getProductList(data?, isInfiniteScroll?) {
		if (data == "daterange") {
			this.filterparams["from_price"] = parseInt(this.minprice);
			this.filterparams["to_price"] = parseInt(this.maxprice);
		}
		// if (this.isLoading) {
		//     return
		// }
		if (!isInfiniteScroll) {
			this.productData = [];
			this.isLoading = true;
		}
		if (this.productListType) {
			this.commonService.localStorageRemove("banner_id");
			this.commonService.localStorageRemove("banner_title");
			this.commonService.localStorageRemove("banner_description");
			this.banner_title = null;
			this.banner_description = null;
			switch (this.productListType) {
				case "FEATURED":
					this.apiUrl = URLS.getFeatureProductsData;
					break;

				case "RECOMMENDED":
					this.apiUrl = URLS.getRecommendedProducts;
					break;

				case "BESTSELLING":
					this.apiUrl = URLS.getBestSellingProducts;
					break;
			}
			this.subscription.add(
				this.dataService.get(this.apiUrl, this.filterList).subscribe(
					(data) => {
						if (data["code"] == 200) {
							if (!isInfiniteScroll) {
								if (this.productListType == "RECOMMENDED") {
									this.productData = data["data"];
								} else {
									this.productData = data["data"]["result"];
								}
								this.productData.forEach((element) => {
									element["product_images_web"] = element["web_image_url"];
									element["product_images_mobile"] =
										element["mobile_image_url"];
								});
								this.totalDataCount = data["data"]["total_count"];
								this.enableNext = data["data"]["enable_next"];
							}
							this.isLoading = false;
						} else {
							this.productData = [];
							this.isLoading = false;
						}
					},
					(error) => {
						this.productData = [];
						this.isLoading = false;
					}
				)
			);
		} else if (this.bannerId) {
			const params = {
				banner_id: +this.bannerId,
			};
			this.subscription.add(
				this.dataService.get(URLS.getBannerList, params).subscribe(
					(data) => {
						if (data["code"] == 200) {
							if (!isInfiniteScroll) {
								this.productData = Object.keys(data["data"]).length
									? data["data"]["products"]
									: [];
								if (this.productData && this.productData.length) {
									this.productData.forEach((element) => {
										element["product_images_web"] = element[
											"product_images_web"
										]
											? element["product_images_web"]
											: null;
										element["product_images_mobile"] = element[
											"product_images_mobile"
										]
											? element["product_images_mobile"]
											: null;
									});
								}
								this.totalDataCount = data["data"]["total_count"];
								this.enableNext = data["data"]["enable_next"];
								this.isLoading = false;
							}
						} else {
							this.productData = [];
							this.isLoading = false;
						}
					},
					(error) => {
						this.productData = [];
						this.isLoading = false;
					}
				)
			);
		} else {
			this.commonService.localStorageRemove("banner_id");
			this.commonService.localStorageRemove("banner_title");
			this.commonService.localStorageRemove("banner_description");
			this.banner_title = null;
			this.banner_description = null;
			this.subscription.add(
				this.dataService.post(URLS.productlist, this.filterparams).subscribe(
					(data) => {
						if (data["code"] == 200) {
							this.isLoading = false;
							if (!isInfiniteScroll) {
								this.productData = data["data"]["result"];
								this.totalDataCount = data["data"]["total_count"];
								this.enableNext = data["data"]["enable_next"];
							}
						} else {
							this.productData = [];
							this.isLoading = false;
						}
					},
					(error) => {
						this.productData = [];
						this.isLoading = false;
					}
				)
			);
		}
	}

	//subClass filter list
	getSubClassList() {
		this.subscription.add(
			this.dataService.get(URLS.subClassListFilter).subscribe(
				(data) => {
					if (data["code"] == 200) {
						this.subClassListData = data["data"]
						this.subClassListData.forEach((element) => {
							if (element.sub_class_id === +this.sub_class_id) {
								element["isChecked"] = true;
								this.banner_img = element.sub_class_image_url;
								this.display_sub_class_name_en = element.display_sub_class_name_en;
								this.display_sub_class_name_ar = element.display_sub_class_name_en;
							} else {
								element["isChecked"] = false;
							}
						});
					} else {
						this.subClassListData = [];
					}
				},
				(error) => {
					this.subClassListData = [];
				}
			)
		);
	}

	//region filter list
	getRegionList() {
		this.subscription.add(
			this.dataService.get(URLS.regionListFilter).subscribe(
				(data) => {
					if (data["code"] == 200) {
						this.regionListData = data["data"]["result"];
						this.regionListData.forEach((element) => {
							element["isChecked"] = false;
						});
					} else {
						this.regionListData = [];
					}
				},
				(error) => {
					this.regionListData = [];
				}
			)
		);
	}

	//brand filter list
	getBrandList() {
		this.subscription.add(
			this.dataService.get(URLS.brandListFilter).subscribe(
				(data) => {
					if (data["code"] == 200) {
						this.brandListData = data["data"]["result"];
						this.brandListData.forEach((element) => {
							element["isChecked"] = false;
						});
					} else {
						this.brandListData = [];
					}
				},
				(error) => {
					this.brandListData = [];
				}
			)
		);
	}

	//supplier filter list
	getSupplierList() {
		this.subscription.add(
			this.dataService.get(URLS.supplierListFilter).subscribe(
				(data) => {
					if (data["code"] == 200) {
						this.supplierListData = data["data"]["result"];
						this.supplierListData.forEach((element) => {
							element["isChecked"] = false;
						});
					} else {
						this.supplierListData = [];
					}
				},
				(error) => {
					this.supplierListData = [];
				}
			)
		);
	}

	getClassList() {
		this.subscription.add(
			this.dataService.get(URLS.classListFilter).subscribe(
				(data) => {
					if (data["code"] == 200) {
						this.classListData = data["data"];
					} else {
						this.classListData = [];
					}
				},
				(error) => {
					this.classListData = [];
				}
			)
		);
	}

	openModal() {
		this.navCtrl.navigateRoot('/' + this.currentLang + '/login');
	}

	async openFilterModal() {
		if (!this.productListType) {
			let options: any = {
				id: "filterModal",
				componentProps: {
					showAccordian: false,
					title: "filterBy",
					priceRange: this.priceData,
					maximumPrice: +this.maxprice,
					subClassDatas: this.subClassListData,
					regionDatas: this.regionListData,
					brandDatas: this.brandListData,
					supplierDatas: this.supplierListData,
					classDatas: this.classListData,
					filterSubClassData: this.filterSubClass,
					filterRegionData: this.filterRegionData,
					filterBrandData: this.filterBrandData,
					filterSupplierData: this.filterSupplierData,
				},
			};
			options["component"] = FilterComponent;
			let data = await this.modalService.openModal(options);
			if (data && data["data"]) {

				let priceLower;
				let priceUpper;
				if (data["data"].rangeLower == 0) {
					priceLower = data["data"].rangeLower.toString();
				} else {
					priceLower = data["data"].rangeLower;
				}
				if (data["data"].rangeUpper == 0) {
					priceUpper = data["data"].rangeUpper.toString();
				} else {
					priceUpper = data["data"].rangeUpper;
				}
				if (data["data"].brandData) {
					let brandParams = [];
					data["data"].brandData.forEach((element) => {
						let obj = this.brandListData.find(
							(el) => el.brand_id == element.value
						);
						if (obj) {
							obj.isChecked = element["isChecked"];
							if (obj.isChecked) {
								brandParams.push(obj.brand_id);
							}
						}
						if (brandParams && brandParams.length) {
							this.filterparams["brand_id"] = brandParams;
						} else {
							delete this.filterparams["brand_id"];
						}
					});
					this.filterBrandData = data["data"].filterBrandData;
				}
				if (data["data"].sub_classData) {
					let sub_classParams = [];
					data["data"].sub_classData.forEach((element) => {
						let obj = this.subClassListData.find(
							(el) => el.sub_class_id == element.value
						);
						if (obj) {
							obj.isChecked = element["isChecked"];
							if (obj.isChecked) {
								sub_classParams.push(obj.sub_class_id);
							}
						}
						if (sub_classParams && sub_classParams.length > 0) {
							this.filterparams["sub_class_id"] = sub_classParams;
							this.commonService.localStorageSet("sub_class_id", sub_classParams);
						} else {
							delete this.filterparams["sub_class_id"];
						}
					});
				}
				this.filterparams["from_price"] = priceLower ? +priceLower : 0;
				this.filterparams["to_price"] = priceUpper ? +priceUpper : 0;
				this.priceData = data["data"].priceData;

				if (data["data"].regionData) {
					let regionParams = [];
					data["data"].regionData.forEach((element) => {
						let obj = this.regionListData.find(
							(el) => el.region_id == element.value
						);
						if (obj) {
							obj.isChecked = element["isChecked"];
							if (obj.isChecked) {
								regionParams.push(obj.region_id);
							}
						}
						if (regionParams && regionParams.length) {
							this.filterparams["region_id"] = regionParams;
						} else {
							delete this.filterparams["region_id"];
						}
					});
					this.filterRegionData = data["data"].filterRegionData;
				}
				if (data["data"].supplierData) {
					let supplierParams = [];
					data["data"].supplierData.forEach((element) => {
						let obj = this.supplierListData.find(
							(el) => el.supplier_id == element.value
						);
						if (obj) {
							obj.isChecked = element["isChecked"];
							if (obj.isChecked) {
								supplierParams.push(obj.supplier_id);
							}
						}
						if (supplierParams && supplierParams.length) {
							this.filterparams["supplier_id"] = supplierParams;
						} else {
							delete this.filterparams["supplier_id"];
						}
					});
					this.filterSupplierData = data["data"].filterSupplierData;
				}
				if (data["data"].filterSubClassData) {
					this.filterSubClass = data["data"].filterSubClassData;
					if (this.filterSubClass && this.filterSubClass.length) {
						this.filterSubClass.forEach((element, index) => {
							if (index == 0) {
								this.productName = element;
							}
						});
					} else {
						this.productName = this.commonService.domDirectionLTR ? "Products" : "منتجات";
					}
				} else {
					this.filterSubClass = [];
				}
				let filterParamsArray = [
					"sub_class_id",
					"brand_id",
					"region_id",
					"supplier_id",
				];
				this.filterCount = 1;
				filterParamsArray.forEach((element) => {
					if (this.filterparams[element] && this.filterparams[element].length) {
						this.filterCount += 1;
					}
				});
				this.filterCount = this.filterCount.toString();
				if (
					data["data"].filterSubClassData.length ||
					data["data"].filterSupplierData.length ||
					data["data"].filterRegionData.length ||
					data["data"].filterBrandData.length ||
					data["data"].rangeLower ||
					data["data"].rangeUpper
				) {
					this.getProductList();
				}
			}

			this.modalService.dismissAllModal();
		} else {
			return;
		}
	}

	async openSortByModal() {
		if (!this.productListType) {
			let options: any = {
				id: "sortByModal",
				componentProps: { selectedSort: this.selectedSortBy },
			};
			options["component"] = SortByComponent;
			let data = await this.modalService.openModal(options);
			if (data && data["data"]) {
				data["data"].forEach((element) => {
					if (element["checked"]) {
						this.selectedSortBy = element["translateLabel"];
					}
				});
			}
		} else {
			return;
		}
	}

	goBack() {
		this.commonService.setEvent("emptySearch", null);
		this.location.back();
	}

	getPageName() {
		if (
			this.router.getCurrentNavigation() &&
			this.router.getCurrentNavigation().extras &&
			this.router.getCurrentNavigation().extras.state
		) {
			let data = this.router.getCurrentNavigation().extras.state;
			this.productName = this.commonService.domDirectionLTR ? data["breadcrumb"] : data["banner_title"]
			this.commonService.localStorageSet("backupProductName", data["ar_label"]);
		} else {
			if (this.commonService.localStorageGet("breadcrumb")) {
				let breadcrumbData = JSON.parse(
					this.commonService.localStorageGet("breadcrumb")
				);
				let url: any = this.router.url;
				if (this.router.url.includes("%")) {
					url = url.replaceAll("%20", " ");
				}
				if (breadcrumbData.url == url) {
					this.productName = this.commonService.domDirectionLTR ? breadcrumbData["breadcrumb"] : breadcrumbData['ar_label']
					this.commonService.localStorageSet("backupProductName", this.commonService.domDirectionLTR ? breadcrumbData["breadcrumb"] : breadcrumbData['ar_label']);
				} else {
					this.route.data.subscribe((data) => {
						if (data && data["breadcrumb"]) {
							if (data["breadcrumb"] == "products") {
								this.productName = this.commonService.domDirectionLTR ? data["breadcrumb"] : "منتجات"
							} else {
								this.productName = data["breadcrumb"]
							}
							this.commonService.localStorageSet("backupProductName", data["breadcrumb"]);

						}
					});
				}

			} else {
				this.route.data.subscribe((data) => {
					if (data && data["breadcrumb"]) {
						if (data["breadcrumb"] == "products") {
							this.productName = this.commonService.domDirectionLTR ? data["breadcrumb"] : "منتجات"
						} else {
							this.productName = data["breadcrumb"]
						}
					}
				});
			}
		}

		this.backupProductName = this.commonService.localStorageGet("backupProductName");

	}

	handleRefresh(event) {
		setTimeout(() => {
			// code maybe required
			// this.isLoading = false;
			this.filterparams = {
				page: 1,
				size: 9,
				sort_by: "RELEVANCE",
			};
			this.page = 1;
			this.size = 1;
			this.selectedSortBy = "Relevance";
			// this.filterCount = "1";

			this.sub_class_id = this.commonService.localStorageGet("sub_class_id");
			if (this.sub_class_id) {
				if (this.sub_class_id.includes(",")) {
					let sub_class = this.sub_class_id.split(",").map(function (item) {
						return parseInt(item);
					});
					this.filterparams["sub_class_id"] = sub_class;
					this.commonService.localStorageRemove("banner_id");
					this.filterCount = "2";
				} else {
					this.filterparams["sub_class_id"] = [+this.sub_class_id];
					this.commonService.localStorageRemove("banner_id");
					this.filterCount = "2";
				}
			} else {
				delete this.filterparams["sub_class_id"];
				this.filterCount = "1";
			}
			this.getProductList("daterange");
			if (!this.getPlatformService.isDesktop) {
				this.sortBySubscription = this.commonService.callSortByData.subscribe(
					(data) => {
						this.sortByModalData(data);
					}
				);
			}
			event.target.complete();
		}, 1000);
	}

	ionViewWillLeave() {
		this.commonService.localStorageRemove("banner_title");
		this.commonService.localStorageRemove("banner_description");
		this.filterComponentLoaded = true;
		this.filterparams = {
			page: 1,
			size: 9,
			sort_by: "RELEVANCE",
		};
		this.enableNext = false;
	}

	ngOnDestroy() {
		this.filterComponentLoaded = true;
		this.filterparams = {
			page: 1,
			size: 9,
			sort_by: "RELEVANCE",
		};
		this.enableNext = false;
		this.subscription.unsubscribe();
	}
}
