<ion-header class="mobile-visibility app-header">
	<ion-toolbar mode="md">
		<img src="assets/icon/leftArrow.svg" class="back-icon back-img" alt="Jazeera" slot="start" (click)="goBack()">
		<ion-title mode="ios">
			<div class="order-header">
				{{'statement' | translate}}
			</div>
		</ion-title>
		<div class="section-block header-filter-block" slot="end" (click)="openFilterByModal()" *ngIf="isLoggedIn">
			<div class="filter-icon">
				<img src="./assets/icon/filterIcon.svg" alt="Jazeera">
			</div>
		</div>
	</ion-toolbar>

	<div class="searchbar-container mh-20 mv-12" *ngIf="isLoggedIn">
		<div class="searchbar-container mh-16 mv-12" *ngIf="isLoggedIn">
			<div class="search-div width-auto mr-0">
				<ion-input type="text" fill="outline" CapitalizeFirst placeholder="{{'Search' | translate}}"
					[(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" (input)="search()"
					(ngModelChange)="search()" (keyup.enter)="search()">
					<img src="./assets/icon/search.svg" slot="start" alt="Jazeera">
					<img src="./assets/icon/close.svg" class="input-clear-icon" slot="end" *ngIf="searchText"
						alt="Jazeera" (click)="clearSearch()">
				</ion-input>
			</div>
		</div>
	</div>
	<div *ngIf="isLoggedIn">
		<form [formGroup]="defaultDateRange">
			<div class="filter-section">
				<div class="filter-header">
					<div class="status-label pl-10">
						<ion-label [ngClass]=" commonService.domDirectionLTR ? 'LTR' : 'RTL'">{{'customField.selectDate'
							|
							translate}}*</ion-label>
					</div>

					<div>
						<ion-row>

							<div class=" flex center-select-date pv-10 ph-10">
								<div class="input-div-container ml-5">
									<div class="from-date-div">
										<ion-item class="from-date-class"
											[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'"
											id="defaultFromDateId">
											<ion-input value="{{ defaultFromDate | date: 'MMM dd, YYYY'}}" placeholder="{{'from'
									| translate}}" class="ion-text-start"></ion-input>
											<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end"
												alt="Jazeera"></ion-icon>
											<ion-popover trigger="defaultFromDateId" size="cover" id="datePopover">
												<ng-template>
													<ion-datetime presentation="date" [min]="'2000-01-01'"
														[max]="defaultToDate ? defaultToDate : maxFromDate"
														[(ngModel)]="defaultFromDate" formControlName="defaultfrom"
														#defaultfromDate
														(ionChange)="onDateChange(defaultfromDate)"><span
															slot="title">{{"from"
															| translate}}</span></ion-datetime>
												</ng-template>
											</ion-popover>
										</ion-item>
									</div>
								</div>

								<div class="input-div-container">
									<div class="to-date-div">
										<ion-item class="to-date-class"
											[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'"
											id="defaultToDateId">
											<ion-input value="{{defaultToDate | date: 'MMM dd, YYYY' }}" placeholder="{{'to'
													| translate}}" class="ion-text-start"></ion-input>
											<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end"
												alt="Jazeera"></ion-icon>
											<ion-popover trigger="defaultToDateId" size="cover" id="datePopover">
												<ng-template>
													<ion-datetime presentation="date"
														[min]="defaultFromDate ? defaultFromDate : '2000-01-01'"
														[max]="maxDate" [(ngModel)]="defaultToDate"
														formControlName="defaultto" #defaulttoDate
														(ionChange)="onDateChange(defaulttoDate)"><span
															slot="title">{{"to"
															| translate}}</span></ion-datetime>
												</ng-template>
											</ion-popover>
										</ion-item>
									</div>
								</div>
							</div>

							<div class="filter-buttons center-select-date">

								<button class="filter-apply-button" (click)="applyDefaultBtn()">{{"apply" |
									translate}}</button>
								<button class="filter-apply-button clear-button"
									(click)="clearDefaultFilter()">{{"clear" |
									translate}}</button>
							</div>

						</ion-row>

						<ion-row>

						</ion-row>
					</div>
				</div>
			</div>
		</form>
	</div>

	<div (click)="showBalance($event)" *ngIf="gridViewData && gridViewData.balance && !isLoading"
		class="checkBalancetext">check balance</div>
</ion-header>
<app-loader *ngIf="isLoading"></app-loader>

<ion-popover #popover [isOpen]="isOpen" (didDismiss)="isOpen = false">
	<ng-template>
		<ion-content class="ion-padding">
			<div class="close-statement">
				<img src="./assets/icon/close.svg" class="input-clear-icon" slot="end" alt="Jazeera"
					(click)="hideStatementPopOver()">
			</div>
			<div class="myStatementHeader" *ngIf="gridViewData && gridViewData.balance && !isLoading">
				<ion-row class="statement-row mv-5 box2 common-box">
					<div>
						<p class="m-0 pv-5 color-black text-bold"> {{"statementComponent.currentBalance"|translate}}
						</p>
						<p class="text-bold m-0 pv-5">{{commonService.currencySymbol}} {{gridViewData.balance}}</p>
					</div>
					<div>
						<img src="/assets/icon/statement2.svg" alt="Jazeera" class="statement-icon" />
					</div>
				</ion-row>


				<ion-row class="statement-row mv-5 box1 common-box">
					<div>
						<p class="m-0 pv-5 color-black text-bold"> {{"statementComponent.remainingBalance"|translate}}:
						</p>
						<p class="text-bold m-0 pv-5">{{commonService.currencySymbol}}
							{{gridViewData.remaining_balance}}</p>
					</div>
					<div>
						<img src="/assets/icon/statement1.svg" alt="Jazeera" class="statement-icon" />
					</div>
				</ion-row>

				<ion-row class="statement-row mv-5 box3 common-box">
					<div>
						<p class="m-0 pv-5 color-black text-bold"> {{"statementComponent.creditLimit"|translate}} :</p>
						<p class="text-bold m-0 pv-5">{{commonService.currencySymbol}} {{gridViewData.credit_limit}}</p>
					</div>
					<div>
						<img src="/assets/icon/statement3.svg" alt="Jazeera" class="statement-icon" />
					</div>
				</ion-row>
			</div>
		</ion-content>
	</ng-template>
</ion-popover>

<ion-content [fullscreen]="true">
	<ion-refresher *ngIf="!getPlatformService.isDesktop" slot="fixed" refreshingSpinner="circular"
		(ionRefresh)="handleRefresh($event)">
		<ion-refresher-content></ion-refresher-content>
	</ion-refresher>
	<div class="orders-listing mh-20 mt-10" *ngIf="isLoggedIn && !isLoading">
		<div class="mobile-view-listing" *ngIf="statementList.length">
			<div class="order-container">
				<ion-grid class="p-0 m-0">
					<ion-row class="order-card" *ngFor="let statement of statementList;index as i">
						<ion-col size="11">
							<ion-grid>
								<ion-row>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"statementComponent.post_date" | translate}}</div>
										<div class="grid-values">{{statement.post_date||'-'}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"statementComponent.doc_date" | translate}}</div>
										<div class="grid-values">{{statement.doc_date||'-'}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"statementComponent.doc_no" | translate}}</div>
										<div class="grid-values">{{statement.doc_no||'-'}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"statementComponent.doc_text" | translate}}</div>
										<div class="grid-values">{{statement.doc_text||'-'}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"statementComponent.doc_type" | translate}}</div>
										<div class="grid-values">{{statement.doc_type ||'-'}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"statementComponent.credit" | translate}}</div>
										<div class="grid-values">
											{{commonService.currencySymbol}}{{(statement.credit| number)|| 0}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"statementComponent.debit" | translate}}</div>
										<div class="grid-values">{{commonService.currencySymbol}} {{(statement.debit
											| number)|| 0}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"statementComponent.balance" | translate}}</div>
										<div class="grid-values">{{commonService.currencySymbol}} {{(statement.balance|
											number)|| 0}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"statementComponent.invoice_no" | translate}}</div>
										<div class="grid-values">{{statement.invoice_no|| '-'}}
											<img *ngIf="statement.invoice_no" src="./assets/icon/fi_download.svg"
												class="pl-10" (click)="downloadInvoice($event,statement)" alt="Jazeera"
												[title]="('downloadInvoice'|translate)">
										</div>
									</ion-col>


								</ion-row>
							</ion-grid>
						</ion-col>
					</ion-row>
					<ion-infinite-scroll threshold="400px" (ionInfinite)="loadData($event)">
						<ion-infinite-scroll-content *ngIf="enableNext" loadingSpinner="circular"
							loadingText="{{'loadMoreOrders' | translate}}">
						</ion-infinite-scroll-content>
					</ion-infinite-scroll>
				</ion-grid>
			</div>
		</div>

		<div *ngIf="!statementList.length" class="no-orders">
			<img src="./assets/icon/no-order.svg" alt="Jazeera" class="mb-30" />
			<div class="mb-20">
				<p class="label-3 color-app-black ">{{'noStatements' | translate}}</p>
			</div>
		</div>


	</div>
	<app-login-prompt *ngIf="!isLoggedIn && !isLoading"></app-login-prompt>
</ion-content>