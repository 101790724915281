<ion-header class="header" mode="md">
	<ion-toolbar class="toolbar" mode="md" id="searchBlock" class="mob-ml-15 bg-gray-90"
		[ngClass]="commonService.domDirectionLTR ? 'desktop-searchbar-ltr' : 'desktop-searchbar-rtl'">
		<img *ngIf="showBackIcon == true" src="./assets/icon/leftArrow.svg" alt="back-arrow" slot="start"
			(click)="clearClick()" [ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">

		<form class="bg-gray-90">
			<ion-searchbar #searchbar id="searchbar" autocomplete='off' autocorrect='off' autocapitalize="none"
				inputmode="search" debounce="500" type="search" name="search" enterkeyhint="search"
				[(ngModel)]="searchValue" [limit-to]='50' (ionClear)="clearClick()" (ionInput)="getSearchList($event)"
				(ionFocus)="focusOnSearch()" (ionBlur)="focusOutSearch($event)"
				placeholder="{{ fromOrdersList ? ('Search' | translate):('searchBarComponent.Search product...' | translate )}}"
				(keydown.enter)="search($event)" (ionFocus)="ionFocus($event)"
				search-icon="assets/icon/search-icon.svg"></ion-searchbar>
		</form>
	</ion-toolbar>
</ion-header>

<div class="list-div" *ngIf="visibleList != false && !fromOrdersList" #listDiv
	[ngClass]="getPlatformService.isDesktop ? 'ml-7p middle-block-search' : 'middle-block-search'" id="listDiv">
	<ng-container *ngFor="let data of searchTypeData">
		<ng-container *ngIf="data['search_type_en'] == 'CATEGORIES'">
			<ion-item *ngFor="let item of data['result'];index as i" class="search-list">
				<ion-label class="label1 pointer" (click)="goToProductListing(item)">
					{{commonService.domDirectionLTR ? item['item_name_en'] :item['item_name_ar'] }}
					<span class="label-span"> {{"in" | translate}} {{commonService.domDirectionLTR ?
						data['search_type_en'] :data['search_type_ar']}} </span>
				</ion-label>
			</ion-item>
		</ng-container>
		<ng-container *ngIf="data['search_type_en'] == 'PRODUCTS'">
			<ion-item *ngFor="let item of data['result'];index as i" class="search-list">
				<ion-label class="label1 pointer" (click)="goToProductDetail(item)">
					{{commonService.domDirectionLTR ? item['item_name_en'] :item['item_name_ar'] }}
					<span class="label-span"> {{"in" | translate}} {{commonService.domDirectionLTR ?
						data['search_type_en'] :data['search_type_ar']}} </span>
				</ion-label>
			</ion-item>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="searchValue && noSearchData">
		<ion-item class="search-list">
			<ion-label class="label1 pointer">
				{{"noRecordFound" | translate}}
			</ion-label>
		</ion-item>
	</ng-container>
</div>