import { Location } from "@angular/common";
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { CommonService } from "../services/common.service";
@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
	constructor(private router: Router,
		private location: Location,
		private commonService: CommonService,
		private cookieService: CookieService,
		public translate: TranslateService) { }

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		const isLoggedIn = this.commonService.localStorageGet('accessToken')
		let routeUrl: any;

		if (next.url && next.url.length > 0) {
			routeUrl = next.url[0].path;
		}

		if (isLoggedIn) {
			if (routeUrl && (routeUrl.includes('login'))) {
				if (this.location.getState()) {
					this.location.back();
				} else {
					this.router.navigate(['/' + this.commonService.currentLang + '/home']);
				}
				return false;
			}
			// authorised so return true
			return true;
		} else {
			if (routeUrl && (
				routeUrl.includes('login') ||
				routeUrl.includes('register') ||
				routeUrl.includes('reset-password') ||
				routeUrl.includes('2fa'))) {
				return true;
			}
		}

		this.router.navigate(['/en/login']);
		return false;
	}

	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		return this.canActivate(childRoute, state)
	}


}
