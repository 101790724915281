import { isPlatformServer } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from "@ngx-translate/core";
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { CartService } from './../services/cart.service';
import { GetPlatformService } from './../services/get-platform.service';
@Component({
	selector: 'app-tabs',
	templateUrl: 'tabs.page.html',
	styleUrls: ['tabs.page.scss']
})
export class TabsPage {
	closed$ = new Subject<any>();
	showTabs = false; // <-- show tabs by default
	currentLang;
	is_not_sample_product = true;
	is_sample_item_and_shipment_free = false;
	public cartData: any;
	@Inject(PLATFORM_ID) private platformId: Object;
	constructor(
		private _router: Router,
		public getPlatformService: GetPlatformService,
		public cartService: CartService,
		public commonService: CommonService,
		public router: Router,
		public navCtrl: NavController,
		public translate: TranslateService
	) {
		this.currentLang = this.commonService.currentLang;
		this.commonService.eventObservable.subscribe(data => {
			if (data.event == 'handleRefresh' || data.event == 'profileData') {
				setTimeout(() => {
					const token = this.commonService.localStorageGet('accessToken');
					if (token) {
						this.cartService.getCart().then(data => {
							this.cartData = data;
							for (let i = 0; i < this.cartData.result.length; i++) {
								const element = this.cartData.result[i];
								if (element.products && element.products.length) {
									this.is_not_sample_product = element.products.some(obj => !obj.is_sample_product);
									this.is_sample_item_and_shipment_free = element.products.some(obj => obj.is_sample_item_and_shipment_free);
									if (this.is_not_sample_product || (!this.is_not_sample_product && !this.is_sample_item_and_shipment_free)) {
										break;
									}

								}
							}
						}).catch(err => console.log(err, "getCart"));
					}
				}, 1000);
			}
			if (data.event == "addToCart" || data.event == "removeFromCart") {
				setTimeout(() => {
					const token = this.commonService.localStorageGet('accessToken');
					if (token) {
						this.cartService.getCart().then(data => {
							this.cartData = data;
							for (let i = 0; i < this.cartData.result.length; i++) {
								const element = this.cartData.result[i];
								if (element.products && element.products.length) {
									this.is_not_sample_product = element.products.some(obj => !obj.is_sample_product);
									this.is_sample_item_and_shipment_free = element.products.some(obj => obj.is_sample_item_and_shipment_free);
									if (this.is_not_sample_product || (!this.is_not_sample_product && !this.is_sample_item_and_shipment_free)) {
										break;
									}

								}
							}
						}).catch(err => console.log(err, "getCart"));
					}
				}, 1000);
			}
		});
	}

	ngOnInit() {
		this._router.events.pipe(
			filter(e => e instanceof NavigationEnd),
			takeUntil(this.closed$)
		).subscribe(event => {
			if (!isPlatformServer(this.platformId)) {
				const tabs = document.querySelectorAll('ion-tab-bar');

				if (tabs) {
					if (this.getPlatformService.isDesktop) {
						tabs[0].style.display = 'none';
					}
					else if ((event['url'] === '/categories' || event['url'] === '/cart' || event['url'] === '/home' ||
						event['url'] === '/my-wishlist' || event['url'] === '/orders' || event['url'] === '/tabs/categories' ||
						event['url'] === '/tabs/cart' || event['url'] === '/tabs/home' || event['url'] === '/' ||
						event['url'] === '/tabs/my-wishlist' || event['url'] === '/tabs/orders') && !this.getPlatformService.isDesktop) {
						tabs[0].style.display = 'flex';// <-- show tabs on specific pages
					}
					else if ((event['url'].includes('/home/order-payment') || event['url'].includes('/home/payment-loading'))
						&& !this.getPlatformService.isDesktop) {
						tabs[0].style.display = 'none';// <-- hide tabs on specific pages
					}

					else {
						// tabs[0].style.display = 'none';// <-- hide tabs on specific pages
						tabs[0].style.display = 'flex';
					}
				}
			}

		});

		setTimeout(() => {
			const token = this.commonService.localStorageGet('accessToken');
			if (token) {
				this.cartService.isloggedIn = true;
				this.cartService.getCart().then(data => {
					this.cartData = data;
					for (let i = 0; i < this.cartData.result.length; i++) {
						const element = this.cartData.result[i];
						if (element.products && element.products.length) {
							this.is_not_sample_product = element.products.some(obj => !obj.is_sample_product);
							this.is_sample_item_and_shipment_free = element.products.some(obj => obj.is_sample_item_and_shipment_free);
							if (this.is_not_sample_product || (!this.is_not_sample_product && !this.is_sample_item_and_shipment_free)) {
								break;
							}

						}
					}
				}).catch(err => console.log(err, "getCart"));
			} else {
				this.cartService.isloggedIn = false;
			}
		}, 1000);
	}


	categoryTabClick() {
		this.navCtrl.navigateForward('/' + this.currentLang + '/tabs/categories', { animated: false });
	}

	cartTabClick() {
		this.commonService.localStorageRemove('tempQuoteId');
		this.navCtrl.navigateForward(['/' + this.commonService.currentLang + '/cart'], { animated: false });
	}

	checkoutTabClick() {
		this.navCtrl.navigateForward(['' + this.commonService.currentLang + '/checkout-step-1'], { animated: false });
	}
}
