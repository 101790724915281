<ion-header class="mobile-visibility">
	<ion-toolbar mode="md">
		<div class="title-block">
			<img src="assets/icon/leftArrow.svg" class="back-icon" alt="Jazeera" (click)="gomobileBack()">
			<ion-title mode="ios">{{"newsDetails" | translate}}</ion-title>
		</div>
	</ion-toolbar>
</ion-header>

<div class="home-page app-header" *ngIf="getPlatformService.isDesktop">
	<app-header></app-header>
</div>

<app-loader *ngIf="isLoading"></app-loader>

<ion-content *ngIf="getPlatformService.isDesktop">
	<div class="detail-page">
		<div class="cms-wrapper">
			<div class="page-heading">{{"cmsComponent.pressDetails" | translate}}</div>
			<div class="ion-content">
				<ion-grid>
					<ion-row *ngIf="!isLoading && pressData">
						<div class="slide-container" [ngClass]="['productDetailLabel' ? 'detail-slide-container' : '']">
							<div class="product-image-container"
								[ngClass]="categories ? 'categories-image-container' : 'product-image-container'">
								<div class="product-list-image-div">
									<img *ngIf="pressData.media_url && pressData.media_type != 'mp4'"
										[src]="pressData.media_url" alt="Jazeera" width="100%" height="100%">
									<img *ngIf="!pressData.media_url  && pressData.media_type != 'mp4'"
										src="assets/images/no-img1.png" alt="Jazeera" width="100%" height="100%">

									<video width="100%" height="100%" controls #playerDetails id="playerDetails"
										*ngIf="pressData.media_url && pressData.media_type == 'mp4'">
										<source [src]="pressData.media_url" type="video/mp4">
									</video>

								</div>
							</div>
							<div class="press-title text-left" [ngClass]="[categories ? 'categories-press-title' : '']">
								<span class="text-ellipse">{{commonService.domDirectionLTR ?
									pressData.content_title_en :
									pressData.content_title_ar
									}}</span>
							</div>
							<div class="items-available pt-5 text-left" *ngIf="pressData.created_at">
								{{ pressData.created_at ? (pressData.created_at | date:'MMM dd,yyyy'):null }}
							</div>
							<div class="press-description text-left">
								<div class="from">
									{{commonService.domDirectionLTR ? pressData.content_en : pressData.content_ar}}
								</div>
							</div>
						</div>
					</ion-row>
				</ion-grid>
			</div>
		</div>

		<div *ngIf="!pressData && !isLoading">
			<div class="no-record-found ion-text-center">
				{{'noRecordFound' | translate}}
			</div>
		</div>
	</div>
	<div class="wrapper-block home-page">
		<div class="app-footer">
			<app-footer></app-footer>
		</div>
	</div>
</ion-content>

<ion-content *ngIf="!getPlatformService.isDesktop">
	<ion-refresher *ngIf="!getPlatformService.isDesktop" slot="fixed" refreshingSpinner="circular"
		(ionRefresh)="handleRefresh($event)">
		<ion-refresher-content></ion-refresher-content>
	</ion-refresher>

	<div class="detail-page">
		<div class="ion-content" *ngIf="!isLoading && pressData">
			<div class="mobile-row pv-15 mh-15">
				<div class="slide-container" [ngClass]="['productDetailLabel' ? 'detail-slide-container' : '']">
					<div class="product-image-container"
						[ngClass]="categories ? 'categories-image-container' : 'product-image-container'">
						<div class="product-list-image-div mr-20">
							<img [src]="commonService.domDirectionLTR ?  pressData.press_banner_image_en : pressData.press_banner_image_ar"
								alt="Jazeera" width="100%" height="100%">


						</div>
					</div>
				</div>
				<div class="press-title pb-5 pt-10 text-left" [ngClass]="[categories ? 'categories-press-title' : '']">
					<span class="text-ellipse">{{commonService.domDirectionLTR ?
						pressData.content_title_en :
						pressData.content_title_ar
						}}</span>
				</div>
				<div class="items-available pb-5 text-left" *ngIf="pressData.created_at">
					{{ pressData.created_at ? (pressData.created_at | date:'MMM dd,yyyy'):null }}
				</div>
				<div class="press-description text-left"
					[innerHTML]="commonService.domDirectionLTR ? pressData.content_en : pressData.content_ar">
				</div>
			</div>
		</div>


		<div *ngIf="!pressData && !isLoading">
			<div class="no-record-found ion-text-center">
				{{'noRecordFound' | translate}}
			</div>
		</div>
	</div>
</ion-content>