<ion-header class="mobile-visibility" mode="md" *ngIf="!getPlatformService.isDesktop">
	<ion-toolbar mode="md">
		<div class="title-block">
			<img src="assets/icon/leftArrow.svg" class="back-icon" alt="Jazeera" (click)="goBack()">
			<ion-title>{{"newsComponent.title" | translate}}</ion-title>
		</div>
	</ion-toolbar>
</ion-header>
<div class="home-page app-header" *ngIf="getPlatformService.isDesktop">
	<app-header></app-header>
</div>

<app-loader *ngIf="isLoading"></app-loader>

<ion-content>
	<div class="page-container" *ngIf="newsData">
		<div>

			<div class="banner-container">
				<img [src]="'./assets/images/shadow.png'" alt="Jazeera" class="shadow-image" />
				<img [src]="commonService.domDirectionLTR ?  newsData.press_banner_image_en :newsData.press_banner_image_ar"
					class="page-banner" />
			</div>


			<div class="section-block date-div">
				<h2 class="color-white p-0 m-0 ">{{commonService.domDirectionLTR ? newsData.content_title_en :
					newsData.content_title_ar}}</h2>

				<p class="pb-0 ph-10 card-desc2 mb-0 color-white">{{newsData.created_at | date : 'dd MMM yyyy'}}</p>
			</div>
		</div>


		<ion-row class="mt-50 pb-50">
			<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
				<div class="d-flex">
					<div class="ml-20 width-100">


						<div
							[innerHTML]="commonService.domDirectionLTR ? newsData.content_en : newsData.content_ar | safe :'html'">
						</div>

						<div class="pv-15 iframDiv" *ngIf="media_type && sanitizedUrl">
							<iframe class="iframeVideo" [src]="sanitizedUrl" title="YouTube video player"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
							</iframe>
						</div>
					</div>
				</div>
			</ion-col>
		</ion-row>

	</div>

	<div class="wrapper-block home-page" *ngIf="getPlatformService.isDesktop">
		<div class="app-footer">
			<app-footer></app-footer>
		</div>
	</div>
</ion-content>